// by jhan ortegon

import { useEffect, useState } from 'react';

// icons - images
import lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import lupaIcon from '../../assets/img/icons/searchIconApp.svg';

// css
import 'reactjs-popup/dist/index.css';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import paginationStyles from '../Layouts/pagination.module.scss';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// helpers
import { getPermission } from '../../helpers/helpers';

// services
import { accounts_get, accounts_edit_active, accounts_insert } from '../../actions/cuentasActions';

// Components
import Swal from 'sweetalert2';
import Select from 'react-select';
import { Box } from '@mui/material';
import Loader from 'react-loader-spinner';
import ModalNew from '../Layouts/ModalNew';
import Pagination from 'react-js-pagination';
import CustomPopup from '../Popup/customPopup';
import NumberFormat from 'react-number-format';
import AccountInfo from '../AccountInfo/AccountInfo';
import GenericTableNew from '../Layouts/GenericTableNew';
import withReactContent from 'sweetalert2-react-content';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import { getLocaleSeparators } from '../../helpers/numberFormatting';

const localeSeparators = getLocaleSeparators();

const TableCuentas = () => {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const listPermission = counter.loginReducer.currentAccount?.profile?.permission;
  const myPermission = getPermission({ prefix: 'account', listPermission });

  const [unconfirmedFilters, setUnconfirmedFilters] = useState({
    search: '',
    active: '',
  });

  const [filters, setFilter] = useState({
    search: '',
    active: '',
    page: 1,
    perpage: 10,
  });

  const [postState, setPostState] = useState({
    nit: '',
    name: '',
    bussines_name: '',
    phone: '',
    address: '',
    modalShow: false,
  });

  const [putState, setPutState] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState(false);
  const [createAcc, setCreateAcc] = useState(false);
  const [loading, setLoading] = useState(false);
  const rowTotal = counter?.cuentasReducer?.total;

  useEffect(() => {
    if (filters) {
      dispatch(accounts_get(filters));
    }
  }, [dispatch, trigger, filters]);

  const handleSearchButton = e => {
    e.preventDefault();
    setFilter({
      ...filters,
      ...unconfirmedFilters,
      page: 1,
    });
    setTrigger(trigger + 1);
  };

  const handleChange = event => {
    setUnconfirmedFilters({
      ...unconfirmedFilters,
      search: event.target.value,
    });
  };

  const filterChangeActive = event => {
    setUnconfirmedFilters({
      ...unconfirmedFilters,
      active: event.value,
    });
    setFilter({
      ...filters,
      ...unconfirmedFilters,
      active: event.value,
    });
    setTrigger(trigger + 1);
  };

  const handlePageChange = val => {
    setFilter({
      ...filters,
      page: val,
    });
  };

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-md-2 text-start px-2'>
          {elem.nit}-{CalcularDv(elem.nit)}
        </td>
        <td className='col-md-3 text-start'>{elem.name}</td>
        <td className='col-md-5 text-start'>{elem.bussines_name}</td>
        <td className='col-md-1'>
          <div className={`${tableStyles.groupElems}`}>
            <div className={`${elem.active ? tableStyles.greenState : tableStyles.redState} mx-2`}>
              {elem.active ? 'Habilitado' : 'Inhabilitado'}
            </div>
            <CustomPopup
              triggerSrc={threeDots}
              showEdit={myPermission?.edit}
              editClickEvent={() => {
                setDetail(false);
                setPutState(elem);
                setEdit(true);
              }}
              showEnable={myPermission?.edit}
              isEnabled={elem.active}
              enableClickEvent={() =>
                dispatch(
                  accounts_edit_active(elem.id, { active: !elem.active }, elem.bussines_name, () =>
                    setTrigger(trigger + 1),
                  ),
                )
              }
              newButton={
                myPermission?.edit
                  ? [
                      {
                        btnAction: () => {
                          setEdit(false);
                          setPutState(elem);
                          setDetail(true);
                        },
                        btnText: 'Ver detalle',
                        btnIcon: { lupaIcon }, //not implemented
                      },
                    ]
                  : []
              }
            />
          </div>
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (counter.cuentasReducer.cuentas !== undefined) {
      const elem2 = counter.cuentasReducer.cuentas;
      elemMap = elem2?.map(elem => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={0} className='text-start px-2'>
      Nit
    </th>,
    <th key={1}>Nombre</th>,
    <th key={2}>Razón Social</th>,
    <th key={3}>
      <div className={'w100 text-center'}>Estado</div>
    </th>,
  ];

  const CalcularDv = nit1 => {
    //
    let arreglo, x, y, z, i, dv1;
    if (isNaN(nit1) || nit1.toString().length !== 9) {
      return 'X';
    } else {
      x = 0;
      y = 0;
      z = nit1.toString().length;
      //arreglo = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
      arreglo = [41, 37, 29, 23, 19, 17, 13, 7, 3];
      for (i = 0; i < z; i++) {
        y = parseInt(nit1.toString().substr(i, 1));
        x += y * arreglo[i]; //x+=(y*arreglo[z-i]);
      }
      y = x % 11;
      if (y > 1) {
        dv1 = 11 - y;
      } else {
        dv1 = y;
      }
      return dv1;
    }
  };

  const validateDv = nit1 => {
    return !isNaN(nit1) && nit1.toString().length === 9;
  };

  const invalidDv = () => {
    MySwal.fire({
      icon: 'error',
      title: 'Nit incorrecto',
      text: 'El código nit escrito no es valido',
    });
  };

  return (
    <div className={tableStyles.container}>
      {counter?.cuentasReducer?.getAccLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      {loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      {!createAcc && !edit && !detail ? (
        <>
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}>Cuentas</h1>
            <div className='d-flex align-items-center gap-4'>
              <div className='d-flex align-items-end' style={{ maxHeight: '1.75rem' }}>
                {myPermission?.read && (
                  <Box className='w-100'>
                    <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
                    <Select
                      className={`${tableStyles.widthSelect} text-secondary w-100`}
                      onChange={filterChangeActive}
                      options={[
                        { value: '', label: 'Seleccionar...' },
                        { value: '0', label: 'Inhabilitado' },
                        { value: '1', label: 'Habilitado' },
                      ]}
                      placeholder='Seleccionar...'
                      styles={customSelectNewDark}
                      maxHeight={26}
                    />
                  </Box>
                )}
              </div>

              <div>
                {myPermission?.read && (
                  <form onSubmit={e => handleSearchButton(e)}>
                    <Box display={'flex'}>
                      <input
                        className={tableStyles.searchUsersAlt}
                        type='text'
                        placeholder='Buscar...'
                        onChange={handleChange}
                      />
                      <button onClick={e => handleSearchButton(e)}>
                        <img
                          src={lupa}
                          alt='User icon'
                          className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                        />
                      </button>
                    </Box>
                  </form>
                )}
              </div>

              <div
                className='d-flex align-items-end w-100 m-auto'
                style={{ justifyContent: 'end' }}
              >
                {myPermission?.create && (
                  <div
                    onClick={() => {
                      setCreateAcc(true);
                    }}
                    className='d-flex justify-content-end groupAddButton align-items-center'
                  >
                    <label htmlFor='newAccident' className='darkGray fw-bold'>
                      Crear cuenta
                    </label>
                    <button className='addCheckButton mx-2' />
                  </div>
                )}
              </div>
            </div>

            {postState.modalShow ? (
              <ModalNew
                title='Creación de Cuenta'
                show={postState.modalShow}
                onHide={() => setPostState({ ...postState, modalShow: false })}
                btnYesEvent={
                  validateDv(postState.nit)
                    ? () => {
                        setLoading(true);
                        dispatch(
                          accounts_insert(postState, () => {
                            setPostState({
                              ...postState,
                              nit: '',
                              name: '',
                              bussines_name: '',
                              address: '',
                              phone: '',
                              modalShow: false,
                            });
                            setLoading(false);
                            setTrigger(trigger + 1);
                          }),
                        );
                      }
                    : () => invalidDv()
                }
              >
                <div>
                  <div className='row'>
                    <div className='col-md-5'>
                      <p className={`${tableStyles.crudModalLabel} `}>NIT</p>
                      <input
                        className='register-inputs'
                        name='nit'
                        type='number'
                        max='999999999'
                        defaultValue={postState.nit}
                        onChange={event =>
                          setPostState({
                            ...postState,
                            nit: parseInt(event.target.value),
                          })
                        }
                      />
                    </div>

                    <div className='col-md-1'>
                      <p className='label-inputs'>‎‎&nbsp;</p>
                      <input
                        style={{ width: '1.2rem' }}
                        className='register-inputs text-center p-0'
                        name='verification'
                        type='text'
                        value={CalcularDv(postState.nit)}
                        readOnly={true}
                      />
                    </div>

                    <div className='col-md-6'>
                      <p className={`${tableStyles.crudModalLabel} `}>Nombre</p>
                      <input
                        className='register-inputs'
                        name='name'
                        type='text'
                        defaultValue={postState.name}
                        onChange={event => setPostState({ ...postState, name: event.target.value })}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <p className={`${tableStyles.crudModalLabel} `}>Razón social</p>
                      <input
                        className='register-inputs'
                        name='bussines_name'
                        type='text'
                        defaultValue={postState.bussines_name}
                        onChange={event =>
                          setPostState({
                            ...postState,
                            bussines_name: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <p className={`${tableStyles.crudModalLabel} `}>Teléfono</p>
                      <NumberFormat
                        allowNegative={false}
                        style={{ padding: 0, color: '#58595B' }}
                        className='register-inputs'
                        name='cellphone'
                        thousandSeparator={localeSeparators.groupSeparator}
                        decimalSeparator={localeSeparators.decimalSeparator}
                        value={postState.phone}
                        onValueChange={values => {
                          const { value } = values;
                          setPostState({ ...postState, phone: value });
                        }}
                      />
                    </div>
                    <div className='col-md-6'>
                      <p className={`${tableStyles.crudModalLabel} `}>Dirección</p>
                      <input
                        type='text'
                        className='register-inputs'
                        name='address'
                        value={postState.address}
                        onChange={e => setPostState({ ...postState, address: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <p className={`${tableStyles.crudModalLabel} `}>Dirección</p>
                    <input
                      type='text'
                      className='register-inputs'
                      name='address'
                      value={postState.address}
                      onChange={e => setPostState({ ...postState, address: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <p className={`${tableStyles.crudModalLabel} `}>Dirección</p>
                  <input
                    type='text'
                    className='register-inputs'
                    name='address'
                    value={postState.address}
                    onChange={e => setPostState({ ...postState, address: e.target.value })}
                  />
                </div>
              </ModalNew>
            ) : null}
            {myPermission?.read && (
              <GenericTableNew headers={renderHeaders} dark={true}>
                {listElem()}
              </GenericTableNew>
            )}

            {myPermission?.read && (
              <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}>
                  Pag. {filters.page}
                  {' de '}
                  {Math.ceil(rowTotal / filters.perpage)
                    ? Math.ceil(rowTotal / filters.perpage)
                    : '1'}{' '}
                  ({rowTotal} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={10}
                  totalItemsCount={rowTotal}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <AccountInfo
          createAcc={createAcc}
          editAcc={edit}
          detailAcc={detail}
          accInfo={putState}
          setPutState={setPutState}
          setFilter={setFilter}
          setUnconfirmedFilters={setUnconfirmedFilters}
          setEdit={setEdit}
          setDetail={setDetail}
          setCreateAcc={setCreateAcc}
          setTrigger={setTrigger}
          trigger={trigger}
        ></AccountInfo>
      )}
    </div>
  );
};

export default TableCuentas;
