// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ModalNew from '../Layouts/ModalNew';
// --------------- icons 💥  ----------------//
import Agregar from '../../assets/img/icons/add-check.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

//-------------- scss styles 😄   --------------
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import CustomPopupExtend from '../Popup/customPopUpExtends';

// ------------------ Functions ----------------------
import {
  changeStatusPettyCash,
  createPettyCash,
  getEmployees,
  getListJournalType,
  getListPettyCash,
  getOnePettyCash,
  updatePettyCash,
} from '../../actions/accountingActions';

import { getAllConCenter } from '../../actions/consumptionCenterActions';

import { getNiifAccounts } from '../../actions/pucActions';
import { generateId, formatteDocument, getEnvs } from '../../helpers';
import { Title } from '../../shared';
import { getLocaleSeparators } from '../../helpers/numberFormatting';

const localeSeparators = getLocaleSeparators();
const { CURRENCY } = getEnvs();

function TablePettyCash() {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const [triggerModal, setTriggerModal] = useState(1);
  const [showModal, setShowModal] = useState({
    show: false,
  });
  const [creatPettyCash, setcreatPettyCash] = useState({
    eaccount: storage.loginReducer.currentAccount.id,
    name: '',
    niifAccount: '',
    employee: '',
    cc: '',
    max_Value: '',
    totalAmount: '',
    jrnalType: '',
  });
  //console.log(creatPettyCash.max_Value)
  const [info, setInfo] = useState({
    search: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'PettyCash',
    );
  //--------------first functions 🍕   --------------

  useEffect(() => {
    if (!myPermission()?.create || !myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getListPettyCash(info));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, dispatch]);

  useEffect(() => {
    dispatch(
      getAllConCenter({ active: 1, eaccount: storage.loginReducer.currentAccount.id, lite: true }),
    );
    dispatch(getNiifAccounts({ active: 1, eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getEmployees({ eaccount: storage.loginReducer.currentAccount.id, status: 'current' }));
    dispatch(getListJournalType({ eaccount: storage.loginReducer.currentAccount.id }));
  }, [storage.loginReducer.currentAccount.id, dispatch]);

  useEffect(() => {
    if (showModal.selectedPetyCash) {
      dispatch(
        getOnePettyCash(
          {
            eaccount: storage.loginReducer.currentAccount.id,
            id: showModal.selectedPetyCash,
          },
          setcreatPettyCash,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerModal, dispatch]);
  //-------------- Table 🍕   --------------

  const header = [
    <div key={generateId()} className={`col-4 text-start px-2`}>
      Cuenta
    </div>,
    <div key={generateId()} className={`col-6 text-start`}>
      Nombre
    </div>,
    <div key={generateId()} className={`col-1`}>
      Estado
    </div>,
    <div key={generateId()} className={`col-1`}>
      &nbsp;
    </div>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingReducer.listPettyCash)) {
      storage.accountingReducer.listPettyCash.forEach(item => {
        tempList.push(
          <section2 className={`d-flex`}>
            <div className='col-4 text-start px-2'>{item.niifAccountNbr}</div>
            <div className='col-6 text-start'>{item.name}</div>
            <div className='col-1 text-center'>
              <Tooltip title={item.statusName} arrow>
                <div
                  className='rounded-pill p-1'
                  style={{
                    backgroundColor: item.statusName !== 'Deshabilitado' ? '#FAFDF6' : '#FEF7F5',
                  }}
                >
                  <b style={{ color: item.statusName !== 'Deshabilitado' ? '#83C036' : '#F39682' }}>
                    {item.statusName !== 'Deshabilitado' ? 'Habilitado' : 'Inhabilitado'}
                  </b>
                </div>
              </Tooltip>
            </div>
            <div className='col-1'>
              <CustomPopupExtend
                triggerSrc={threeDots}
                closeOnDocumentClick
                showEnable={myPermission()?.delete}
                isEnabled={item.statusName === 'Deshabilitado' ? false : true}
                enableClickEvent={() => changeStatus(item.id, item.name, item.statusName)}
                enableText={item.statusName === 'Deshabilitado' ? 'Habilitar' : 'Deshabilitar'}
                showEdit={myPermission()?.edit}
                editClickEvent={() => {
                  setShowModal({ show: true, edit: true, selectedPetyCash: item.id });
                  setTriggerModal(triggerModal + 1);
                }}
                showDetails={true}
                showDetailsEvent={() => {
                  setShowModal({ show: true, creat: false, selectedPetyCash: item.id });
                  setTriggerModal(triggerModal + 1);
                }}
                textDetails={'Ver detalle'}
                noHover
              />
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  //-------------- select options 🍕   --------------

  let optionsNiif = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.pucReducer.niifAccounts)) {
    storage.pucReducer.niifAccounts.forEach((item, index) => {
      optionsNiif.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.description,
        key: index + 'niif',
      });
    });
  }

  let optionsCostCenter = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.consumptionCenterReducer.consumption_center)) {
    storage.consumptionCenterReducer.consumption_center.forEach((item, index) => {
      optionsCostCenter.push({
        value: item.id,
        label: item.description,
        key: index + 'cc',
      });
    });
  }

  let optionsJournalType = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.accountingReducer.listJournalType)) {
    storage.accountingReducer.listJournalType.forEach((item, index) => {
      optionsJournalType.push({
        value: item.id,
        label: item.name,
        key: index + 'jt',
      });
    });
  }

  let optionsEmployees = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.accountingReducer.listEmployees)) {
    storage.accountingReducer.listEmployees.forEach((item, index) => {
      optionsEmployees.push({
        value: item.id_candidate,
        label: formatteDocument(item.doc_number).format + ' - ' + item.full_name,
        key: index + 'jt',
      });
    });
  }

  //--------------  Actions  🍕   --------------

  const creatNewPetty = () => {
    if (showModal.creat) {
      dispatch(
        createPettyCash(
          {
            eaccount: storage.loginReducer.currentAccount.id,
            name: creatPettyCash.name,
            niifAccount: creatPettyCash.niifAccount,
            employee: creatPettyCash.employee,
            cc: creatPettyCash.cc,
            max_Value: creatPettyCash.max_Value,
            totalAmount: creatPettyCash.totalAmount,
            jrnalType: 1,
          },
          () => {
            setShowModal({ show: false });
            setTrigger(trigger + 1);
            dispatch(getListPettyCash(info));
            setcreatPettyCash({
              eaccount: storage.loginReducer.currentAccount.id,
              name: '',
              niifAccount: '',
              employee: '',
              cc: '',
              max_Value: '',
              totalAmount: '',
              jrnalType: 1,
            });
          },
        ),
      );
    } else {
      dispatch(
        updatePettyCash(
          {
            eaccount: storage.loginReducer.currentAccount.id,
            name: creatPettyCash.name,
            niifAccount: creatPettyCash.niifAccount,
            employee: creatPettyCash.employee,
            cc: creatPettyCash.cc,
            max_Value: creatPettyCash.max_Value,
            totalAmount: creatPettyCash.totalAmount,
            jrnalType: 1,
            id: showModal.selectedPetyCash,
          },
          () => {
            setShowModal({ show: false });
            setTrigger(trigger + 1);
            dispatch(getListPettyCash(info));
            setcreatPettyCash({
              eaccount: storage.loginReducer.currentAccount.id,
              name: '',
              niifAccount: '',
              employee: '',
              cc: '',
              max_Value: '',
              totalAmount: '',
              jrnalType: 1,
            });
          },
        ),
      );
    }
  };

  const changeStatus = (id_activity, name_petty, status) => {
    dispatch(
      changeStatusPettyCash(
        {
          eaccount: storage.loginReducer.currentAccount.id,
          id: id_activity,
          name: name_petty,
          status: status,
        },
        () => {
          dispatch(getListPettyCash({ eaccount: storage.loginReducer.currentAccount.id }));
        },
      ),
    );
  };

  const handleSumit = e => {
    e.preventDefault();
    setTrigger(trigger + 1);
  };
  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {storage.accountingReducer.pettyC_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={tableStyles.title}>
          <Title
            title='Tabla de caja menor'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>
        <Row className='d-flex'>
          <form onSubmit={handleSumit} style={{ display: 'contents' }}>
            <Col xs={3} className='gap-1'>
              <label
                className={`${tableStyles.crudModalLabel}`}
                style={{ marginTop: '22px' }}
              ></label>
              <input
                className={` mr-2 ${tableStyles.SearchNew}`}
                placeholder='Buscar...'
                height='24px'
                type='text'
                onChange={e =>
                  setInfo({
                    ...info,
                    search: e.target.value,
                  })
                }
                style={{ marginLeft: '10px' }}
              ></input>
            </Col>
            <Col className='px-2' xs={1}>
              <i>
                <img
                  style={{ marginTop: '30px', marginLeft: '10px' }}
                  src={Lupa}
                  alt='icon'
                  className='cursorPointer'
                  onClick={() => setTrigger(trigger + 1)}
                />
              </i>
            </Col>
          </form>
          {myPermission()?.create ? (
            <Col
              xs={8}
              className='text-end d-flex align-self-end justify-content-end'
              onClick={() => setShowModal({ show: true, creat: true })}
            >
              <div
                onClick={() => setShowModal({ show: true, creat: true })}
                className={tableStyles.createNomModule}
              >
                <b className={`mr-2`}>Crear caja menor</b>
                <img src={Agregar} alt='User' />
                <div></div>
              </div>
            </Col>
          ) : (
            ''
          )}
        </Row>
        <div>
          <ModalNew
            show={showModal.show}
            onHide={() => {
              setShowModal({ show: false });
              setcreatPettyCash({
                eaccount: storage.loginReducer.currentAccount.id,
                name: '',
                niifAccount: '',
                employee: '',
                cc: '',
                max_Value: '',
                totalAmount: '',
                jrnalType: 1,
              });
            }}
            title={
              showModal.creat
                ? 'Crear caja menor'
                : showModal.edit
                  ? 'Editar caja menor'
                  : 'Detalle de caja menor'
            }
            btnYesEvent={showModal.creat || showModal.edit ? () => creatNewPetty() : null}
            btnYesName={showModal.creat ? 'Guardar' : 'Editar'}
            btnYesDisabled={
              !!(creatPettyCash.name === '') ||
              !!(creatPettyCash.niifAccount === '') ||
              !!(creatPettyCash.employee === '') ||
              !!(creatPettyCash.cc === '') ||
              !!(creatPettyCash.max_Value === '') ||
              !!(creatPettyCash.max_Value === 0) ||
              !!(creatPettyCash.totalAmount === '') ||
              !!(creatPettyCash.totalAmount === 0) ||
              !!(creatPettyCash.jrnalType === '') ||
              !!(Number(creatPettyCash.max_Value) > Number(creatPettyCash.totalAmount))
            }
            size={'500'}
          >
            <Row className='d-flex'>
              <Col xs={12}>
                <label className={tableStyles.crudModalLabel}>Nombre</label>
                <input
                  type='text'
                  name='activity name'
                  value={creatPettyCash.name}
                  style={{ color: '#58595B' }}
                  onChange={e =>
                    setcreatPettyCash({
                      ...creatPettyCash,
                      name: e.target.value,
                    })
                  }
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={!(showModal.edit || showModal.creat)}
                  placeholder='Escribir...'
                />
              </Col>
            </Row>
            <Row className='d-flex'>
              <Col xs={12}>
                <label className={tableStyles.crudModalLabel}>Cuenta</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'account' + trigger}
                  options={optionsNiif}
                  styles={customSelectNewDark}
                  value={optionsNiif.find(
                    item => item.value === Number(creatPettyCash.niifAccount),
                  )}
                  onChange={e =>
                    setcreatPettyCash({
                      ...creatPettyCash,
                      niifAccount: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>
            <Row className='d-flex'>
              <Col xs={12}>
                <label className={tableStyles.crudModalLabel}>Empleado</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'employee' + trigger}
                  options={optionsEmployees}
                  styles={customSelectNewDark}
                  value={optionsEmployees.find(
                    item => item.value === Number(creatPettyCash.employee),
                  )}
                  onChange={e =>
                    setcreatPettyCash({
                      ...creatPettyCash,
                      employee: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>
            <Row className='d-flex'>
              <Col xs={12}>
                <label className={tableStyles.crudModalLabel}>C. Consumo</label>

                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'price' + trigger}
                  options={optionsCostCenter}
                  value={optionsCostCenter.find(item => item.value === Number(creatPettyCash.cc))}
                  styles={customSelectNewDark}
                  onChange={e =>
                    setcreatPettyCash({
                      ...creatPettyCash,
                      cc: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={12}>
                <label className={tableStyles.crudModalLabel}>Tipo de cpte.</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'type' + trigger}
                  options={optionsJournalType}
                  value={optionsJournalType.find(item => item.value === creatPettyCash.jrnalType)}
                  styles={customSelectNewDark}
                  onChange={e =>
                    setcreatPettyCash({
                      ...creatPettyCash,
                      jrnalType: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={6}>
                <label className={tableStyles.crudModalLabel}>Fondo de caja menor</label>
                <NumberFormat
                  key={'amount' + trigger}
                  allowNegative={false}
                  style={
                    !(showModal.edit || showModal.creat)
                      ? { paddingLeft: '5px', color: '#58595B', backgroundColor: '#F5F7FA' }
                      : { paddingLeft: '5px', color: '#58595B' }
                  }
                  className={IndividualStyles.registerInputsClearBlue}
                  name='valuepetty'
                  suffix={` ${CURRENCY}`}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  thousandSeparator={localeSeparators.groupSeparator}
                  decimalScale={2}
                  fixedDecimalScale
                  value={creatPettyCash.totalAmount}
                  onValueChange={values => {
                    const { value } = values;
                    setcreatPettyCash({
                      ...creatPettyCash,
                      totalAmount: value,
                    });
                  }}
                  disabled={showModal.edit || showModal.creat ? false : true}
                  placeholder='Escribir...'
                ></NumberFormat>
              </Col>

              <Col xs={6}>
                <label className={tableStyles.crudModalLabel}>Valor máx. egreso individual</label>
                <NumberFormat
                  placeholder='Escribir...'
                  key={'valueMax' + trigger}
                  allowNegative={false}
                  style={
                    !(showModal.edit || showModal.creat)
                      ? { paddingLeft: '5px', color: '#58595B', backgroundColor: '#F5F7FA' }
                      : { paddingLeft: '5px', color: '#58595B' }
                  }
                  className={IndividualStyles.registerInputsClearBlue}
                  name='valuemax'
                  suffix={` ${CURRENCY}`}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  thousandSeparator={localeSeparators.groupSeparator}
                  decimalScale={2}
                  fixedDecimalScale
                  value={creatPettyCash.max_Value}
                  onValueChange={values => {
                    const { value } = values;
                    setcreatPettyCash({
                      ...creatPettyCash,
                      max_Value: value,
                    });
                  }}
                  isAllowed={values => {
                    const { floatValue } = values;
                    //console.log(floatValue <= creatPettyCash.totalAmount)
                    if (creatPettyCash.totalAmount >= 0) {
                      if (floatValue <= creatPettyCash.totalAmount) {
                        return true;
                      } else if (floatValue > creatPettyCash.totalAmount) {
                        return false;
                      } else {
                        if (floatValue !== undefined) {
                          if (floatValue <= 0) {
                            return false;
                          } else {
                            return true;
                          }
                        } else {
                          return true;
                        }
                      }
                    }
                    // return floatValue <= creatPettyCash.totalAmount;
                  }}
                  disabled={showModal.edit || showModal.creat ? false : true}
                ></NumberFormat>
              </Col>
            </Row>
          </ModalNew>
        </div>
        <GenericTableScroll
          headers={header}
          dark={true}
          body={renderList()}
          typeHead={'2'}
        ></GenericTableScroll>
      </div>
    </>
  );
}
export default TablePettyCash;
