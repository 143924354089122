// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

// --------------- icons 💥  ----------------//
import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

//-------------- scss styles 😄   --------------
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';

// ------------------ Functions ----------------------
import {
  changeStatusPopulation,
  createPopulation,
  getPopulation,
} from '../../actions/populationActions';
import { getClient } from '../../actions/receiptOfInvoicesActions';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { formatToRcSelect, getPermission, loader } from '../../helpers/helpers';

import { useGetMethod } from '../../Hooks';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import CustomPopup from '../Popup/customPopup';

const TablePopulation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const token = storage.loginReducer.Authorization;
  const eaccount = storage.loginReducer.currentAccount.id;

  const listPermission = storage.loginReducer.currentAccount?.profile?.permission;

  const [trigger, setTrigger] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState({
    show: false,
  });

  const [creatActivity, setCreatActivity] = useState({
    clientId: undefined,
    description: '',
    eaccount: storage.loginReducer.currentAccount.id,
    id: '',
    status: '',
  });

  const [info, setInfo] = useState({
    search: '',
    id_account: storage.loginReducer.currentAccount.id,
    page: 1,
    perpage: 10,
  });

  const myPermission = getPermission({ prefix: 'Population', listPermission });

  const { results: listCompany, load: loadListCompany, trigger: getListCompany } = useGetMethod();

  useEffect(() => {
    getListCompany({
      url: '/coporateClients/',
      objFilters: { id_account: eaccount },
      token: token,
    });
  }, [eaccount, getListCompany, token]);

  //-------------- Table 🍕   --------------
  const renderHeaders = [
    <th key={2} className={`px-3`}>
      Descripción
    </th>,
    <th key={3}>Cliente-Empresa</th>,
    <th key={4} className={`text-center`}>
      Estado
    </th>,
    <th key={5}>&nbsp;</th>,
  ];

  const renderElement = (item, index) => {
    return (
      <tr key={index} className={``}>
        <td className='col-6 text-start px-3'>{item.description}</td>
        <td className='col-4 text-start'>{item.clientName}</td>
        <td className='col-1'>
          <Tooltip title={item.status === 1 ? 'Habilitado' : 'Inhabilitado'} arrow>
            <div
              className={`
                            ${IndividualStyles.popupReqActive} ${
                              item.status === 0 ? tableStyles.redState : tableStyles.greenState
                            }
                        `}
            >
              {item.status ? 'Habilitado' : 'Inhabilitado'}
            </div>
          </Tooltip>
        </td>
        <td className='col-1'>
          {myPermission?.edit && (
            <CustomPopup
              triggerSrc={threeDots}
              showEnable={myPermission?.delete}
              isEnabled={item.status === 0 ? false : true}
              enableClickEvent={() => changeStatus(item.code, item.status, item.description)}
              enableText={item.status === 0 ? 'Habilitar' : 'Deshabilitar'}
              showEdit={myPermission?.edit}
              editClickEvent={() => {
                setShowModal({ show: true, edit: true, selectedPopulation: item.code });
                setCreatActivity({
                  description: item.description,
                  clientId: item.clientId,
                  status: item.status,
                  id: item.code,
                });
              }}
            />
          )}
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (storage.populationReducer.listPopulation !== undefined) {
      const elem2 = storage.populationReducer.listPopulation;
      elemMap = elem2.map(elem => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  //-------------- select options 🍕   --------------

  const optionsClient = formatToRcSelect(listCompany?.results, 'id', 'copyName', '');

  //--------------  Actions  🍕   --------------

  const creatNewActivity = () => {
    if (showModal.creat) {
      setLoading(true);
      dispatch(
        createPopulation(
          {
            eaccount: creatActivity.eaccount,
            clientId: creatActivity.clientId,
            description: creatActivity.description,
          },
          () => {
            setShowModal({ show: false });
            setCreatActivity({
              description: '',
              clientId: '',
              eaccount: storage.loginReducer.currentAccount.id,
            });
            setInfo({
              search: '',
              id_account: storage.loginReducer.currentAccount.id,
              page: 1,
              perpage: 10,
            });
            setLoading(false);
            setTrigger(trigger + 1);
          },
        ),
      );
    }
  };
  const handleSumit = e => {
    e.preventDefault();
    setTrigger(trigger + 1);
  };

  const changeStatus = (id_activity, statusItem, nameItem) => {
    const statusString = statusItem ? 'deshabilitará' : 'habilitará';

    customSwaltAlert({
      title: `¿Está seguro?`,
      text: `Se ${statusString} la población ${nameItem}`,
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(
          changeStatusPopulation(
            {
              eaccount: storage.loginReducer.currentAccount.id,
              id: id_activity,
              status: statusItem === 1 ? 0 : 1,
              name: nameItem,
            },
            () => {
              setTrigger(trigger + 1);
              setLoading(false);
            },
          ),
        );
      }
    });
  };

  const editPopulation = () => {
    setLoading(true);
    dispatch(
      changeStatusPopulation(
        {
          eaccount: storage.loginReducer.currentAccount.id,
          id: creatActivity.id,
          status: creatActivity.status,
          name: creatActivity.description,
          clientId: creatActivity.clientId,
          edit: true,
        },
        () => {
          setShowModal({ show: false });
          setCreatActivity({
            description: '',
            clientId: '',
            eaccount: storage.loginReducer.currentAccount.id,
            id: '',
            status: '',
          });
          setInfo({
            search: '',
            id_account: storage.loginReducer.currentAccount.id,
            page: 1,
            perpage: 10,
          });
          setLoading(false);
          setTrigger(trigger + 1);
        },
      ),
    );
  };

  const handlePageChange = pageNumber => {
    setInfo({ ...info, page: pageNumber });
    setTrigger(trigger + 1);
  };

  useEffect(() => {
    if (!myPermission?.create || !myPermission?.read) {
      history?.push('/administracion/inicio');
    }
  }, [history, myPermission?.create, myPermission?.read]);

  useEffect(() => {
    setLoading(true);
    dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getPopulation(info, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, storage.loginReducer.currentAccount.id, trigger]);

  return (
    <>
      {(loading || loadListCompany) && loader}
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className='d-flex'>
          <h1 className={tableStyles.title}>Población</h1>
        </div>
        <Row className='d-flex align-items-end'>
          <form className='d-flex w-80 mb-2' onSubmit={handleSumit}>
            {myPermission?.read && (
              <Col>
                <div className='d-flex gap-2'>
                  <input
                    className={tableStyles.searchUsersAlt}
                    placeholder='Buscar...'
                    type='text'
                    onChange={e =>
                      setInfo({
                        ...info,
                        search: e.target.value,
                        page: 1,
                      })
                    }
                  ></input>

                  <img
                    style={{ cursor: 'pointer' }}
                    src={Lupa}
                    onClick={() => setTrigger(trigger + 1)}
                    alt='lupa'
                  />
                </div>
              </Col>
            )}
          </form>
          {myPermission?.create && (
            <div style={{ width: '20%' }} className='d-flex align-items-end justify-content-end'>
              <div
                className='d-flex align-items-center justify-content-end groupAddButton'
                style={{ marginTop: '15px', width: '200px' }}
                onClick={() => setShowModal({ show: true, creat: true })}
              >
                <label htmlFor='newAccident' className='darkGray fw-bold'>
                  Crear Población
                </label>
                <button
                  className='addCheckButton mx-2'
                  style={{ marginBottom: '1.5px', marginTop: '0.1rem' }}
                />
              </div>
            </div>
          )}
        </Row>
        <div>
          <ModalNew
            hideFooter={showModal.edit ? !myPermission?.edit : !myPermission?.create}
            show={showModal.show}
            onHide={() => {
              setShowModal({ show: false });
              setCreatActivity({
                description: '',
                clientId: '',
                eaccount: storage.loginReducer.currentAccount.id,
              });
            }}
            title={showModal.creat ? 'Crear Población' : showModal.edit && 'Editar Población'}
            btnYesEvent={showModal.edit ? () => editPopulation() : () => creatNewActivity()}
            btnYesName={showModal.creat ? 'Guardar' : 'Editar'}
            btnYesDisabled={
              !!(creatActivity.clientId === undefined || creatActivity.clientId === '') ||
              !!(creatActivity.description === '')
            }
            size={'500'}
            btnNoEvent={() => {
              setShowModal({ show: false });
              setCreatActivity({
                description: '',
                clientId: '',
                eaccount: storage.loginReducer.currentAccount.id,
              });
            }}
          >
            <Row className='d-flex'>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>
                  Cliente-Empresa<span className={'text-danger'}>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'code' + trigger}
                  options={optionsClient}
                  value={optionsClient.find(item => item.value === `${creatActivity.clientId}`)}
                  styles={customSelectNewDark}
                  onChange={e =>
                    setCreatActivity({
                      ...creatActivity,
                      clientId: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>
                  Descripción<span className={'text-danger'}>*</span>
                </p>
                <input
                  key={'detail' + trigger}
                  className={IndividualStyles.registerInputsClearBlue}
                  type='text'
                  style={{ color: '#58595B' }}
                  value={creatActivity.description}
                  onChange={e =>
                    setCreatActivity({
                      ...creatActivity,
                      description: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </ModalNew>
        </div>

        {myPermission?.read && (
          <GenericTableNew dark={true} headers={renderHeaders}>
            {listElem()}
          </GenericTableNew>
        )}

        {myPermission?.read && (
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {storage.populationReducer.totalPopulation ? info.page : ''}
              {' de '}
              {Math.ceil(storage.populationReducer.totalPopulation / info.page)
                ? Math.ceil(storage.populationReducer.totalPopulation / info.page)
                : ''}{' '}
              ({storage.populationReducer.totalPopulation} encontrados)
            </p>
            <Pagination
              activePage={info.page}
              itemsCountPerPage={10}
              totalItemsCount={storage.populationReducer.totalPopulation}
              pageRangeDisplayed={5}
              onChange={e => handlePageChange(e)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default TablePopulation;
