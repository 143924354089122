import { useEffect, useState } from 'react';
import { useGetMethod } from '../../Hooks';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import GenericTableNew from '../Layouts/GenericTableNew';
import {
  customSwaltAlert,
  isEmptyOrUndefined,
  nitCalculate,
} from '../../helpers';
import { getLocaleSeparators } from '../../helpers/numberFormatting';
import { useDispatch, useSelector } from 'react-redux';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import ThreeDots from '../../assets/img/icons/threeDots.svg';
import { loader } from '../../helpers';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import Pagination from 'react-js-pagination';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import SelectComponent from '../SelectComponent/SelectComponent';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import Agregar from '../../assets/img/icons/add-check.svg';
import SearchIcon from '../../assets/img/icons/lupa.svg';
import ModalNew from '../Layouts/ModalNew';
import IndividualStyles from './TableDeductionsAccruals.module.scss';
import CreatableSelect from 'react-select/creatable';
import {
  // getDeductionAccruals,
  get_third_party,
  get_doc_types,
  get_recipient_types,
  create_Deductions_Accruals,
  update_DeductionAccruals,
  activationDesactivation,
} from '../../actions/deductionsAccrualsAction';
import { getNiifAccounts } from '../../actions/AccountsActions';
import NumberFormat from 'react-number-format';
import * as deepcopy from 'deepcopy';

const localeSeparators = getLocaleSeparators();

export default function TableDeductionsAccrualsNew() {
  const storage = useSelector(state => state);
  const eaccount = storage?.loginReducer?.currentAccount?.id;
  const token = storage?.loginReducer?.Authorization;
  const dispatch = useDispatch();

  let asterisk = <b style={{ color: '#FF8B00' }}>*</b>;

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    description: '',
    entity_account: eaccount,
    status: '',
    type: '',
  });

  useEffect(() => {
    dispatch(get_third_party({}));
    dispatch(getNiifAccounts({ eaccount }));
    dispatch(get_doc_types({}));
    dispatch(get_recipient_types({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters]);

  const thirdParties = useSelector(state => state.deductionsAccrualsReducer.allThirdParties);
  const docTypes = useSelector(state => state.deductionsAccrualsReducer.allDocTypes);
  const RecipientTypes = useSelector(state => state.deductionsAccrualsReducer.allRecipientTypes);

  let optionsType = [
    {
      value: '',
      label: 'Seleccionar...',
      name: 'type',
    },
    {
      value: 2,
      label: 'Devengado',
      name: 'type',
    },
    {
      value: 1,
      label: 'Deducción',
      name: 'type',
    },
  ];

  let stateOptions = [
    {
      value: '',
      label: 'Seleccionar...',
      name: 'status',
    },
    {
      value: 'permanent',
      label: 'Permanente',
      name: 'status',
    },
    {
      value: 'enabled',
      label: 'Habilitado',
      name: 'status',
    },
    {
      value: 'disabled',
      label: 'Deshabilitado',
      name: 'status',
    },
  ];

  const [optionsDocuments, setOptionsDocuments] = useState([
    { label: 'Seleccionar...', value: '' },
  ]);

  useEffect(() => {
    if (docTypes) {
      const newOptions = [{ label: 'Seleccionar...', value: '' }];
      docTypes.forEach(item => {
        newOptions.push({
          value: item.id,
          label: `${item.description}`,
          name: 'doc_type',
        });
      });
      setOptionsDocuments(newOptions);
    }
  }, [docTypes]);

  const initialState = {
    show: false,
    isEditing: false,
    isCreating: false,
    detail: false,
    adjustments: {
      type: undefined,
      description: '',
      recipient_type: undefined,
      recipient_id: '',
      niifAccount_id: '',
      niifAccount_name: '',
      affects_socialSecurity: '',
      affects_socialBenefit: '',
      nit_third_party: '',
      nit_description: '',
      entity_account: eaccount,
      status: '',
      btnyes: true,
    },
    statemodal2: {
      nit_verification_code: '',
      nit_number: '',
      doc_type: '',
      modal2BtnYes: true,
      descrption_nit: '',
    },
    optionsAccounting: [{ label: 'Escriba una cuenta', value: '' }],
    trigger: false,
    modal2: false,
    reactdisabled: false,
  };

  const [state, setState] = useState(initialState);

  const [optionsRecipientTypes, setOptionsRecipientTypes] = useState([
    { label: 'Seleccionar...', value: '', name: 'recipient_type' },
  ]);

  useEffect(() => {
    if (RecipientTypes) {
      const newOptions = [{ label: 'Seleccionar...', value: '', name: 'recipient_type' }];

      RecipientTypes.forEach(item => {
        if (state.adjustments.type === 2) {
          if (item.description === 'Empleado') {
            newOptions.push({
              value: item.id,
              label: `${item.description}`,
              name: 'recipient_type',
            });
          }
        } else if (state.adjustments.type === 1) {
          if (item.description !== 'Empleado') {
            newOptions.push({
              value: item.id,
              label: `${item.description}`,
              name: 'recipient_type',
            });
          }
        }
      });

      setOptionsRecipientTypes(newOptions);
    }
  }, [RecipientTypes, state.adjustments.type]);

  const [optionThirdParties, setOptionThirdParties] = useState([]);

  useEffect(() => {
    const newOptions = [];

    if (state.statemodal2.nit_number) {
      newOptions.push({
        value: '',
        label: ` ${state.statemodal2.descrption_nit}`,
        nit: `${state.statemodal2.nit_number}${state.statemodal2.nit_verification_code}`,
      });
    }

    if (thirdParties) {
      thirdParties.forEach(item => {
        newOptions.push({
          value: item.id,
          label: `${item.nit} - ${item.description}`,
          nit: item.nit,
        });
      });
    }

    setOptionThirdParties(newOptions);
  }, [state.statemodal2, thirdParties]);

  const {
    trigger: getDeductionAccruals,
    load: deductionAccrualsLoader,
    results: deductionsAccruals,
  } = useGetMethod();

  const [trigger, setTrigger] = useState(1);

  const fetchData = async () => {
    try {
      const result = await getDeductionAccruals({
        url: '/payroll/adjustments/',
        objFilters: {
          ...filters,
        },
        token: token,
      });
      if (!result?.success) {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          message: result.message,
          confirmButtonText: 'Aceptar',
        });
      }
    } catch (error) {
      console.error('error fetching data', error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, trigger]);

  const onSubmitSearch = e => {
    e.preventDefault();
    const { search } = e.target;
    setFilters({ ...filters, description: search.value });
  };

  const handleIconClick = e => {
    const form = e.target.closest('form');
    if (form) {
      form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  const changeBtnYes = () => {
    setState(prevState => {
      let { adjustments } = prevState;
      if (
        !!adjustments.type &&
        !!adjustments.description &&
        !!adjustments.recipient_type
      ) {
        if (adjustments.type === 1) {
          if (adjustments.nit_third_party === '') {
            adjustments.btnyes = true;
          } else {
            adjustments.btnyes = false;
          }
        } else {
          adjustments.btnyes = false;
        }

        if (adjustments.recipient_type === 3 && isEmptyOrUndefined(adjustments.nit_third_party)) {
          adjustments.btnyes = true;
        } else {
          adjustments.btnyes = false;
        }
      } else {
        adjustments.btnyes = true;
      }
      return { ...prevState, adjustments };
    });
  };

  const changeBtnYes2 = event => {
    let name, value;
    if (event.target) {
      ({ name, value } = event.target);
    } else {
      ({ name, value } = event);
      name = event.name;
      value = event.value;
    }

    if (name === 'nit_description' || name === 'nit_third_party') {
      setState(prevState => ({
        ...prevState,
        adjustments: {
          ...prevState.adjustments,
          [name]: value,
        },
      }));
    }
    setState(prevState => ({
      ...prevState,
      statemodal2: {
        ...prevState.statemodal2,
        [name]: value,
        modal2BtnYes: !(
          !!prevState.statemodal2.nit_number &&
          !!prevState.statemodal2.nit_verification_code &&
          !!prevState.statemodal2.doc_type &&
          !!prevState.statemodal2.descrption_nit &&
          prevState.statemodal2.nit_number.length > 4
        ),
      },
    }));
  };
  const onChangeCheckBox = e => {
    const name = e.target.name;
    setState(prevState => {
      const adjustments = { ...prevState.adjustments };
      adjustments[name] = !adjustments[name];
      return { ...prevState, adjustments };
    });
  };

  const onChangeForm = event => {
    let name, value

    if (event.target) {
      ({ name, value } = event.target);
    } else {
      ({ name, value } = event);
      name = event.name;
      value = event.value;
    }

    setState(prevState => {
      let adjustments = { ...prevState.adjustments, [name]: value };

      if (name === 'type') {
        adjustments.nit_description = '';
        adjustments.nit_third_party = '';
        adjustments.recipient_id = '';
        adjustments.recipient_type = null;

        if (value === 1) {
          adjustments.affects_socialBenefit = null;
          adjustments.affects_socialSecurity = null;
        } else {
          adjustments.affects_socialBenefit = false;
          adjustments.affects_socialSecurity = false;
          adjustments.recipient_id = null;
          adjustments.nit_third_party = null;
          adjustments.nit_description = '';
        }
      }

      if (name === 'recipient_type') {
        if (value === 2) {
          adjustments.recipient_id = null;
          adjustments.nit_third_party = null;
        }
      }

      return { ...prevState, adjustments };
    });

    changeBtnYes();
  };

  const toggleModal = () => {
    const newShowState = !state.show;
    if (!newShowState) {
      setState(initialState);
    } else {
      setState(prevState => ({ ...prevState, show: newShowState }));
    }
  };

  const toggleModal2 = () => {
    setState(prevState => ({
      ...prevState,
      modal2: !prevState.modal2,
      reactdisabled: false,
      statemodal2: {
        ...prevState.statemodal2,
        nit_number: '',
        nit_verification_code: '',
        descrption_nit: '',
        modal2BtnYes: true,
      },
    }));
  };
  const activateDesactivate = (obj, status, filters, description) => {
    dispatch(activationDesactivation(obj, status, filters, description)).then(() => {
      setTrigger(prev => prev + 1);
    });
  };

  const handleDetailRecord = client => {
    const newAdjustments = deepcopy(client);
    setState({
      ...state,
      show: !state.show,
      adjustments: newAdjustments,
      detail: true,
      modalbtnYes: false,
      isEditing: false,
    });
  };

  const handleEditRecord = x => {
    const newAdjustments = deepcopy(x);
    setState(prevState => ({
      ...prevState,
      show: !prevState.show,
      adjustments: {
        ...newAdjustments,
        btnyes: !(
          !!newAdjustments.type &&
          !!newAdjustments.description &&
          !!newAdjustments.recipient_type
        ),
      },
      detail: false,
      isEditing: true,
    }));
  };

  const handleSubmit = () => {
    if (!state.detail) {
      if (state.isEditing) {
        dispatch(update_DeductionAccruals(state.adjustments, state.filters));
        dispatch(get_third_party({}));
        setState(initialState);
        setTrigger(trigger + 1);
      } else {
        dispatch(create_Deductions_Accruals(state.adjustments, state.filters));
        dispatch(get_third_party({}));
        setState(initialState);
        setTrigger(trigger + 1);
      }
    } else {
      setState(initialState);
    }
  };

  const handleSubmit2 = () => {
    setState(prevState => ({
      ...prevState,
      modal2: !prevState.modal2,
      adjustments: {
        ...prevState.adjustments,
        btnyes: true,
      },
    }));

    changeBtnYes();
  };

  const renderFilters = () => {
    return (
      <>
        <Row className='d-flex justify-content-end'>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Tipo de novedad</p>
            <SelectComponent
              styles={customSelectNewDark}
              placeholder={'Seleccionar...'}
              key={'Novedad' + trigger}
              value={optionsType?.filter(x => x.value === filters?.type)}
              onChange={e => {
                setFilters({
                  ...filters,
                  type: e.value,
                  page: 1,
                });
                setTrigger(trigger + 1);
              }}
              options={optionsType}
            ></SelectComponent>
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Estado</p>
            <SelectComponent
              styles={customSelectNewDark}
              placeholder={'Seleccionar...'}
              key={'Año' + trigger}
              value={stateOptions.filter(x => x.value === filters?.status)}
              onChange={e => {
                setFilters({
                  ...filters,
                  status: e.value,
                  page: 1,
                });
                setTrigger(trigger + 1);
              }}
              options={stateOptions}
            ></SelectComponent>
          </Col>
          <Col xs={4} className='d-flex align-self-end gap-1'>
            <form onSubmit={onSubmitSearch} className='d-flex flex-grow-1 me-3'>
              <input
                type='text'
                placeholder='Buscar...'
                name='search'
                className={`${tableStyles.SearchNew} w-600 me-2`}
              />
              <img
                style={{ width: '1.5rem' }}
                src={SearchIcon}
                alt='User icon'
                className={`${tableStyles.iconSvgMargin2} pointer`}
                onClick={handleIconClick}
              />
            </form>
          </Col>
          <Col xs={2} className='align-self-end'>
            <div className='d-flex justify-content-end'>
              <div
                className={tableStyles.createNomModule}
                onClick={() =>
                  setState({
                    ...state,
                    show: true,
                    isCreating: true,
                    isEditing: false,
                    detail: false,
                  })
                }
              >
                <b className={`mr-2`}>Agregar Item</b>
                <img src={Agregar} alt='new' />
                <div></div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const renderTable = () => {
    const headers = [
      <th key={1} style={{ whiteSpace: 'nowrap' }} className='text-start px-2 col-6'>
        Nombre
      </th>,
      <th key={2} style={{ whiteSpace: 'nowrap' }} className='text-start'>
        Tipo de novedad
      </th>,
      <th key={3} className='text-start'>
        Beneficiario
      </th>,
      <th key={4} style={{ whiteSpace: 'nowrap' }} className='text-center w-20 col-1 ps-3'>
        Estado
      </th>,
      <th key={5} style={{ whiteSpace: 'nowrap' }} className='text-center px-2'></th>,
    ];

    const renderList = () => {
      let table = [];

      if (Array.isArray(deductionsAccruals?.results)) {
        table = deductionsAccruals?.results?.map((x, index) => {
          return (
            <tr key={'renderList' + index} className='hover-table-row'>
              <td style={{ whiteSpace: 'nowrap' }} className='text-start px-2 col-6'>
                {x?.description || '-'}
              </td>
              <td style={{ whiteSpace: 'nowrap' }} className='text-start'>
                {x?.type_name || '-'}
              </td>
              <td style={{ whiteSpace: 'nowrap' }} className='text-start'>
                {x?.recipient_name || '-'}
              </td>
              <td className='text-center w-20 col-1 ps-3'>
                <div
                  className='rounded-pill p-1'
                  style={{
                    backgroundColor: `${x?.background}`,
                  }}
                >
                  <b style={{ color: `${x?.fontcolor}` }}>{x?.value_status}</b>
                </div>
              </td>
              <td className='text-center col-1 w-15'>
                <CustomPopupExtend
                  showEdit
                  showEnable
                  showDetails
                  textDetails='Ver Detalle'
                  isEnabled={x?.status === 'enabled'}
                  enableText={x?.status === 'enabled' ? 'Deshabilitar' : 'Habilitar'}
                  editText='Editar'
                  showDetailsEvent={() => handleDetailRecord(x)}
                  editClickEvent={() => handleEditRecord(x)}
                  enableClickEvent={() => {
                    activateDesactivate(x?.id, x?.status, filters, x?.description);
                  }}
                  triggerSrc={ThreeDots}
                  alt='iconSearch'
                />
              </td>
            </tr>
          );
        });
      }
      return table;
    };

    return (
      <GenericTableNew headers={headers} dark={true} shadow>
        {renderList()}
      </GenericTableNew>
    );
  };
  return (
    <>
      {deductionAccrualsLoader && loader}
      <div className={`${tableStyles.tlnContainer}`}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Deducciones y devengados</h1>

          {renderFilters()}
          {renderTable()}

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              pag {filters.page} {'de'}{' '}
              {Math.ceil(deductionsAccruals?.row_total / filters.perpage)
                ? Math.ceil(deductionsAccruals?.row_total / filters.perpage)
                : '1'}{' '}
              {''} ({deductionsAccruals?.row_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={deductionsAccruals?.row_total}
              pageRangeDisplayed={5}
              onChange={page => setFilters({ ...filters, page: page })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
          <ModalNew
            title={state.isEditing ? 'Item' : 'Nuevo Item'}
            subtitle={state.isEditing ? 'Editar' : state.detail ? 'Detalle' : ''}
            show={state.show}
            size='400'
            onHide={() => toggleModal()}
            btnYesName='Aceptar'
            hideCancelButton={state.detail ? () => handleSubmit() : null}
            btnYesEvent={() => handleSubmit()}
            btnYesDisabled={state.adjustments.btnyes}
          >
            <div className={`${IndividualStyles.ItemInicio}`}>
              <p className={`${tableStyles.crudModalLabel}`}>Tipo de novedad{asterisk}</p>
              <SelectComponent
                name='type'
                noOptionsMessage={() => 'No hay datos'}
                options={optionsType}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                isDisabled={state.isEditing || state.detail}
                maxHeight={22}
                value={optionsType.filter(x => x.value === state.adjustments.type)}
                onChange={e => onChangeForm(e)}
              />
            </div>
            <div className={`${IndividualStyles.ItemInicio}`}>
              <p className={`${tableStyles.crudModalLabel}`}>Nombre{asterisk}</p>
              <input
                placeholder='Escribir...'
                name='description'
                className={`${IndividualStyles.register_inputs_new3} px-2`}
                disabled={state.detail}
                value={state.adjustments.description || ''}
                onChange={e => onChangeForm(e)}
              />
            </div>
            <div className={`${IndividualStyles.ItemInicio}`}>
              <p className={`${tableStyles.crudModalLabel}`}>Beneficiario{asterisk}</p>
              <SelectComponent
                noOptionsMessage={() => 'No hay datos'}
                options={optionsRecipientTypes}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                isDisabled={state.detail}
                onChange={e => onChangeForm(e)}
                value={optionsRecipientTypes?.filter(
                  x => x.value === state.adjustments.recipient_type,
                )}
              />
            </div>
            <div
              className={`${IndividualStyles.ItemInicio} ${IndividualStyles.register_input_with_background}`}
              hidden={state.adjustments.type === 2 || state.adjustments.type === ''}
            >
              <p className={`${tableStyles.crudModalLabel}`}>Razón social{asterisk}</p>
              {state.adjustments.recipient_type === 2 ? (
                <input
                  name=''
                  className={`register-inputs`}
                  disabled={true}
                  onChange={e =>
                    setState(prevState => ({
                      ...prevState,
                      adjustments: {
                        ...prevState.adjustments,
                        nit_description: e.label,
                        nit_third_party: e.nit,
                        recipient_id: e.value,
                      },
                    }))
                  }
                  value={`${storage.loginReducer.currentAccount.nit} - ${storage.loginReducer.currentAccount.name}`}
                />
              ) : (
                <CreatableSelect
                  options={optionThirdParties}
                  placeholder='Seleccionar..'
                  isDisabled={state.adjustments.recipient_type === 2 || state.detail}
                  value={
                    state.adjustments.recipient_type !== 2
                      ? optionThirdParties.filter(x => x.value === state.adjustments.recipient_id)
                      : ''
                  }
                  styles={customSelectNewDark}
                  maxHeight={22}
                  onChange={e => {
                    if (e?.__isNew__) {
                      setState(prevState => ({
                        ...prevState,
                        reactdisabled: true,
                        modal2: true,
                      }));
                      e.value = '';
                      e.label = '';
                    }
                    setState(prevState => ({
                      ...prevState,
                      adjustments: {
                        ...prevState.adjustments,
                        nit_description: e.label,
                        nit_third_party: e.nit,
                        recipient_id: e.value,
                      },
                    }));
                    changeBtnYes();
                  }}
                />
              )}
            </div>
            <div
              className={`${IndividualStyles.inputMargin} form-check mx-2`}
              hidden={state.adjustments.type === 1 || state.adjustments.type === ''}
            >
              <input
                className='form-check-input'
                type='checkbox'
                name='affects_socialSecurity'
                id='affects_socialSecurity'
                onChange={onChangeCheckBox}
                checked={state.adjustments.affects_socialSecurity}
                disabled={state.detail}
              />
              <p className={`${tableStyles.crudModalLabel}`} htmlFor='affects_socialSecurity'>
                Base para seguridad social
              </p>
            </div>
            <div
              className='form-check mx-2'
              hidden={state.adjustments.type === 1 || state.adjustments.type === ''}
            >
              <input
                className='form-check-input'
                type='checkbox'
                name='affects_socialBenefit'
                id='affects_socialBenefit'
                onChange={onChangeCheckBox}
                checked={state.adjustments.affects_socialBenefit}
                disabled={state.detail}
              />
              <p className={`${tableStyles.crudModalLabel}`} htmlFor='affects_socialBenefit'>
                Base para prestaciones sociales
              </p>
            </div>
          </ModalNew>
          <ModalNew
            title={'Crear tercero'}
            show={state?.modal2}
            size='220'
            onHide={() => toggleModal2()}
            btnYesName={'Aceptar'}
            hideCancelButton={state?.detail ? () => handleSubmit() : null}
            btnYesEvent={() => handleSubmit2()}
            btnYesDisabled={state?.statemodal2?.modal2BtnYes}
          >
            <div className={` ${IndividualStyles.inputMargin} ${IndividualStyles.ItemNormal}`}>
              <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemInicio}`}>
                <p className={`${tableStyles.crudModalLabel}`}>Tipo de documento{asterisk}</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={optionsDocuments}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  maxHeight={22}
                  onChange={e => changeBtnYes2(e)}
                ></Select>
              </div>
              <div className={`${IndividualStyles.ItemFin}`}>
                <p className={`${tableStyles.crudModalLabel}`}>Número de documento{asterisk}</p>
                <div className='d-flex'>
                  <NumberFormat
                    className={`register-inputs ${IndividualStyles.nitBig}`}
                    name='Nit'
                    type='tel'
                    thousandSeparator={localeSeparators.groupSeparator}
                    decimalSeparator={localeSeparators.decimalSeparator}
                    placeholder='000.000.000.000'
                    isAllowed={values => values.value.length <= 10} // max 10 digitos
                    onValueChange={values => {
                      const { value } = values;
                      let a = {
                        name: 'nit_number',
                        value: value,
                      };
                      let b = {
                        name: 'nit_verification_code',
                        value: nitCalculate(value),
                      };
                      let c = {
                        name: 'nit_third_party',
                        value: value,
                      };
                      changeBtnYes2(a);
                      changeBtnYes2(b);
                      changeBtnYes2(c);
                    }}
                  />
                  -
                  <input
                    className={`register-inputs ${IndividualStyles.nitMini}`}
                    name='verificationCode'
                    value={state?.statemodal2.nit_verification_code}
                    disabled={true}
                  ></input>
                </div>
              </div>
            </div>
            <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemInicio}`}>
              <p className={`${tableStyles.crudModalLabel}`}>Razón social</p>
              <input
                name='descrption_nit'
                className={`${IndividualStyles.register_inputs_new2}`}
                onChange={e => {
                  let d = {
                    name: 'nit_description',
                    value: e.target.value,
                  };
                  let c = {
                    name: e.target.name,
                    value: e.target.value,
                  };
                  changeBtnYes2(c);
                  changeBtnYes2(d);
                }}
              ></input>
            </div>
          </ModalNew>
        </div>
      </div>
    </>
  );
}
