import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { Col, Row, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import { isEmptyOrUndefined } from '../../helpers/helpers';
import Loader from 'react-loader-spinner';
import { createNewPayrollParam, updatePayrrolParam } from '../../actions/accountingActions';
import moment from 'moment';
import { customSwaltAlert } from '../../helpers';
import { getLocaleSeparators } from '../../helpers/numberFormatting';
import { Title } from '../../shared/Title';
import NumberFormat from 'react-number-format';

const localeSeparators = getLocaleSeparators();

export const NewProvision = () => {
  const location = useLocation();
  const store = useSelector(state => state);
  const history = useHistory();
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  let isEditing = location?.state?.isEditing;
  let paramInfo = location?.state?.paramInfo;
  const [inputs, setInputs] = useState({
    description: '',
    param: '',
    value: '',
    validFrom: '',
    validUntil: '',
    type: undefined,
    createdBy: idAccount,
    eaccount: idEnterprise,
  });

  const [isLoading, setIsLoading] = useState(false);
  const token = store.loginReducer.Authorization;
  const types = [
    { value: '', label: 'Seleccionar...' },
    { value: 'percentage', label: 'Porcentaje' },
    { value: 'value', label: 'Valor' },
  ];

  useEffect(() => {
    if (isEditing) {
      setInputs({
        id: paramInfo.id,
        description: paramInfo.description,
        param: paramInfo.param,
        value: paramInfo.value,
        validFrom: moment(paramInfo.validFrom).format('YYYY-MM-DD'),
        validUntil: moment(paramInfo.validUntil).format('YYYY-MM-DD'),
        type: paramInfo.type,
        createdBy: paramInfo.createdBy,
        eaccount: paramInfo.eaccount,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleBack = () => {
    history.push('/administracion/configuracionContable');
  };

  const handleSubmit = async () => {
    if (
      isEmptyOrUndefined(inputs.description) ||
      isEmptyOrUndefined(inputs.param) ||
      isEmptyOrUndefined(inputs.type) ||
      isEmptyOrUndefined(inputs.validFrom) ||
      isEmptyOrUndefined(inputs.validUntil) ||
      isEmptyOrUndefined(inputs.value)
    ) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Por favor llene los campos obligatorios`,
        showCancelButton: false,
      });
    }

    if (inputs.validFrom > inputs.validUntil) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `La fecha de validez fin no puede ser menor a la de inicio`,
        showCancelButton: false,
      });
    }
    try {
      setIsLoading(true);
      const result = isEditing
        ? await updatePayrrolParam(inputs, token)
        : await createNewPayrollParam(inputs, token);
      if (result.success) {
        setIsLoading(false);
        if (!isEditing) {
          setInputs({
            description: '',
            param: '',
            value: '',
            validFrom: '',
            validUntil: '',
            type: '',
            createdBy: idAccount,
            eaccount: idEnterprise,
          });
        }
        return customSwaltAlert({
          icon: 'success',
          title: isEditing ? 'Actualizado exitosamente' : 'Creado exitosamente',
          text: `Se ha ${isEditing ? 'actualizado' : 'creado'} el parámetro de nómina: ${inputs?.description}`,
          showCancelButton: false,
        });
      } else {
        setIsLoading(false);
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: result.message,
          showCancelButton: false,
        });
      }
    } catch (error) {
      setIsLoading(false);
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error.message,
        showCancelButton: false,
      });
    }
  };
  return (
    <>
      {isLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={tableStyles.container} style={{ paddingTop: '30px' }}>
        <div className={tableStyles.tableArea}>
          <Title
            title={isEditing ? 'Editar Parámetro de nómina' : 'Nuevo Parámetro de nómina'}
            onClickIcon={handleBack}
            className={'mb-2'}
          />

          <Row>
            <Col xs={12}>
              {' '}
              <p className={`${tableStyles.crudModalLabel} `}>Descripción</p>
              <input
                onChange={e => setInputs({ ...inputs, description: e.target.value })}
                value={inputs.description}
                type='text'
                placeholder='Escribe...'
                className='register-inputs text-primary '
                name='position'
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Parámetro
                <span className={'text-danger'}>*</span>
              </p>
              <input
                onChange={e => {
                  setInputs({ ...inputs, param: e.target.value });
                }}
                value={inputs.param}
                placeholder='Escribe...'
                className='register-inputs text-primary '
                name='position'
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Valor
                <span className={'text-danger'}>*</span>
              </p>

              <NumberFormat
                className='register-inputs text-primary'
                name='position'
                placeholder='Escribe...'
                thousandSeparator={localeSeparators.groupSeparator}
                decimalSeparator={localeSeparators.decimalSeparator}
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                value={String(inputs.value ?? '')}
                onValueChange={({ value }) => {
                  setInputs({ ...inputs, value });
                }}
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Válido desde
                <span className={'text-danger'}>*</span>
              </p>
              <input
                onChange={e => setInputs({ ...inputs, validFrom: e.target.value })}
                type='date'
                className='register-inputs'
                value={inputs.validFrom}
                name='position'
                style={{ fontWeight: 'lighter' }}
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Válido hasta
                <span className={'text-danger'}>*</span>
              </p>
              <input
                onChange={e => setInputs({ ...inputs, validUntil: e.target.value })}
                type='date'
                min={inputs.validFrom}
                value={inputs.validUntil}
                className='register-inputs'
                name='position'
                style={{ fontWeight: 'lighter' }}
              />
            </Col>
            <Col xs={12}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Tipo
                <span className={'text-danger'}>*</span>
              </p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary'
                onChange={e => setInputs({ ...inputs, type: e.value })}
                options={types}
                value={types.find(x => x.value === inputs.type)}
                placeholder={'Seleccionar...'}
                styles={customSelectNewDark}
              ></Select>
            </Col>

            <Col xs={12} className={`pt-3 customTabs__Footer mt-2`}>
              <Button onClick={() => handleSubmit()} className={tableStyles.btnPrimary}>
                {isEditing ? 'Actualizar' : 'Guardar'}
              </Button>

              <Button
                variant='outline-primary'
                onClick={() => handleBack()}
                className={tableStyles.btnSecondary}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
