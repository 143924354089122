//-------------- components 🍕   --------------------------------------------
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod, usePostMethod } from '../../../Hooks/useFetch';
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import Lupa from '../../../assets/img/icons/lupa.svg';
import threeDots from '../../../assets/img/icons/threeDots.svg';
import { customSelectNew4 } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { getEnvs } from '../../../helpers';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';
import { getPermission, loader } from '../../../helpers/helpers';
import { formatNumberCurrency, getLocaleSeparators } from '../../../helpers/numberFormatting';

const localeSeparators = getLocaleSeparators();
const { CURRENCY } = getEnvs();

export const EntityRelationship = () => {
  //-------------- states 🍕   --------------------------

  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const listPermission = store.loginReducer.currentAccount?.profile?.permission;
  const myPermission = getPermission({ prefix: 'copayRate', listPermission });
  const [data, setData] = useState({
    modalActive: false,
    modalCreate: false,
    data: {
      copaymentRateName: '',
      copaymentRateValue: undefined,
      copyName: undefined,
      corpclientId: undefined,
      crtId: undefined,
      equivalent: '',
      vatId: undefined,
    },
    trigger: 0,
    listEntityRelationship: [],
    listClients: [],
    filters: {
      search: '',
      enabled: '',
      ...{ eaccount },
    },
  });


  /* -------------------------------------------------------------------------- */
  /*                                  Petitions                                 */
  /* -------------------------------------------------------------------------- */

  const {
    results: listEntityRelationship,
    trigger: getEntityRelationship,
    load: loadEntityRelationship,
  } = useGetMethod();

  const { results: listClients, trigger: getClients, load: loadClients } = useGetMethod();

  const { results: listGroups, trigger: getGroups, load: loadGroups } = useGetMethod();

  const { load: loadUpdateEntityRelationship, trigger: updateEntityRelationship } = usePostMethod();

  /* /* ---------------------------      ACTIONS  -------------------------- */

  const actionRemoveEntityRelationship = relation => {
    setData(state => ({ ...state, modalActive: false }));
    const entityRelationshipStatus = relation?.enabled ? 'deshabilitar' : 'habilitar';
    const entityRelationshipStatus3 = relation?.enabled ? 'deshabilitado' : ' habilitado';

    customSwaltAlert({
      title: `¿Está seguro?`,
      text: `Se va ${entityRelationshipStatus} la relación entidad: ${relation?.copyName}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      setData(state => ({ ...state, modalActive: false }));
      if (isConfirmed) {
        updateEntityRelationship({
          url: '/medical/client/copaymentRate/',
          token: token,
          method: 'PUT',
          body: {
            crtId: Number(relation.crtId),
            corpclientId: Number(relation.corpclientId),
            enabled: !relation?.enabled,
          },

          succesAction: () => {
            incrementTrigger();

            onGetEntityRelationship();

            setData({
              ...data,
              modalActive: false,
              data: {
                copaymentRateName: '',
                copaymentRateValue: undefined,
                copyName: undefined,
                corpclientId: undefined,
                crtId: undefined,
                equivalent: '',
                vatId: undefined,
              },
            });
            customSwaltAlert({
              icon: 'success',
              title: `Actualizado exitosamente`,
              text: `Se ha ${entityRelationshipStatus3} la relación entidad: ${relation?.copyName}`,
              confirmButtonText: `Aceptar`,
              showCancelButton: false,
            });
          },
          doAfterException: res => {
            customSwaltAlert({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `${res.message}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const actionUpdateEntityRelationship = () => {
    setData(state => ({ ...state, modalActive: false }));

    customSwaltAlert({
      title: `¿Está seguro?`,
      confirmButtonText: `Si, continuar`,
      showCancelButton: true,
      text: `Desea actualizar la relación entidad ${data.data?.copyName}`,
    }).then(({ isConfirmed }) => {
      setData(state => ({ ...state, modalActive: false }));

      if (isConfirmed) {
        updateEntityRelationship({
          url: '/medical/client/copaymentRate/',
          token: token,
          method: 'PUT',
          body: { ...data.data },

          succesAction: res => {
            incrementTrigger();
            onGetEntityRelationship();
            setData({
              ...data,
              modalActive: false,
              data: {
                copaymentRateName: '',
                copaymentRateValue: undefined,
                copyName: undefined,
                corpclientId: undefined,
                crtId: undefined,
                equivalent: '',
                vatId: undefined,
              },
            });
            customSwaltAlert({
              icon: 'success',
              title: `Actualizado exitosamente`,
              text: res.message,
              confirmButtonText: `Aceptar`,
              showCancelButton: false,
            });
          },
          doAfterException: res => {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: `${res.message}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const actionCreateEntityRelationship = () => {
    setData(state => ({ ...state, modalActive: false }));

    updateEntityRelationship({
      url: '/medical/client/copaymentRate/',
      token: token,
      method: 'POST',
      body: { ...data?.data, enabled: 1 },

      succesAction: () => {
        incrementTrigger();
        onGetEntityRelationship();
        customSwaltAlert({
          icon: 'success',
          title: `Creado exitosamente`,
          text: `Se ha creado la relación entidad a la empresa: ${data?.data?.copyName}`,
          confirmButtonText: `Aceptar`,
          showCancelButton: false,
        });
      },
      doAfterException: res => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const validateCreateOrUpdateEntityRelationship = () => {
    const { corpclientId, crtId, equivalent, vatId } = data.data;

    if (corpclientId?.length < 0 || corpclientId < 0 || !corpclientId) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Seleccione una empresa correcta`,
        confirmButtonText: `Aceptar`,
        showCancelButton: false,
      });
    }

    if (crtId?.length < 0 || crtId < 0 || !crtId) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Seleccione un grupo correcto`,
        confirmButtonText: `Aceptar`,
        showCancelButton: false,
      });
    }

    if (vatId?.length < 0 || vatId <= 0 || !vatId) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Seleccione un iva correcto`,
        confirmButtonText: `Aceptar`,
        showCancelButton: false,
      });
    }

    if (equivalent?.length <= 0) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Digite un equivalente correcto`,
        confirmButtonText: `Aceptar`,
        showCancelButton: false,
      });
    }

    data.modalCreate ? actionCreateEntityRelationship() : actionUpdateEntityRelationship();
  };

  const incrementTrigger = () => setData(state => ({ ...state, trigger: state.trigger + 1 }));

  const renderListEntityRelationship = () => {
    return data?.listEntityRelationship?.map((e, key) => (
      <Box
        key={key}
        display={'grid'}
        gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr 200px'}
        className={tableStyles.cardStyles2}
      >
        <Box display={'grid'} gridTemplateColumns={'1fr'}>
          <strong>Empresa</strong>
          <span>{e?.copyName || `-`}</span>
        </Box>

        <Box display={'grid'} gridTemplateColumns={'1fr'}>
          <strong>Grupo</strong>
          <span>{e?.copaymentRateName || `-`}</span>
        </Box>

        <Box display={'grid'} gridTemplateColumns={'1fr'}>
          <strong>Equivalente</strong>
          <span>{e.equivalent || `-`}</span>
        </Box>

        <Box display={'grid'} gridTemplateColumns={'1fr'}>
          <strong>Valor</strong>
          <span>
            {e?.copaymentRateValue
              ? `${formatNumberCurrency(e?.copaymentRateValue)}`
              : '-'}
          </span>
        </Box>

        <Box display={'grid'} gridTemplateColumns={'1fr'}>
          <strong>IVA</strong>
          <span>{String(e?.ivaValue >= 0 ? e?.ivaValue : '')?.length ? e?.ivaShortName : '-'}</span>
        </Box>

        <Box display={'grid'} gap={2} gridTemplateColumns={'150px 60px'} alignItems='center'>
          <Box className={e.enabled ? tableStyles.greenState2 : tableStyles.redState2}>
            {e?.enabled ? 'Habilitado' : 'Inhabilitado'}
          </Box>
          {myPermission?.edit && (
            <CustomPopupExtend
              noHover
              position='right center'
              triggerSrc={threeDots}
              showEdit={myPermission?.edit}
              showEnable={myPermission?.edit}
              isEnabled={e?.enabled}
              editClickEvent={() => {
                let tempObj = { ...e };
                delete tempObj['enabled'];
                setData(state => ({
                  ...state,
                  data: {
                    ...state.data,
                    ...tempObj,
                    vatId: tempObj.vatShortName ?? '-',
                  },
                  modalActive: true,
                  modalCreate: false,
                }));
              }}
              enableClickEvent={() => {
                setData(state => ({
                  ...state,
                  data: { ...e },
                  modalActive: true,
                  modalCreate: false,
                }));

                actionRemoveEntityRelationship(e);
              }}
            />
          )}
        </Box>
      </Box>
    ));
  };

  const renderNotData = () => (
    <div
      className='alert mt-5 text-center w-75 m-auto'
      style={{ backgroundColor: '#005dbf0d', color: '#005dbf' }}
      role='alert'
    >
      No hay datos para mostrar
    </div>
  );

  const handleKeyDown = (e, searchClick) => {
    if (e.key === 'Enter' || searchClick) {
      getEntityRelationship({
        url: `/medical/client/copaymentRate/`,
        objFilters: {
          eaccount,
          tm: true,
          search: data.filters.search,
          enabled: data.filters.enabled,
        },
        token: token,
      });
    }
  };

  const onGetEntityRelationship = () => {
    let filter = { eaccount, tm: true };

    if (data.filters.search.length) {
      filter = { eaccount, tm: true, search: data.filters.search };
    }

    getEntityRelationship({
      url: `/medical/client/copaymentRate/`,
      objFilters: { ...filter, enabled: data.filters.enabled },
      token: token,
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Effects                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    //The endpoint always asks for the tm parameter to be true.
    getEntityRelationship({
      url: `/medical/client/copaymentRate/`,
      objFilters: { eaccount, tm: true },
      token: token,
    });

    getClients({
      url: `/medical/client/`,
      objFilters: { eaccount, status: 'enabled', slim: 1 },
      token: token,
    });

    getGroups({
      url: `/medical/copaymentRate/`,
      objFilters: { eaccount, tm: true, enabled: 1 },
      token: token,
    });
  }, [eaccount, getClients, getEntityRelationship, getGroups, token]);

  useEffect(() => {
    if (listEntityRelationship?.results) {
      setData(state => ({
        ...state,
        listEntityRelationship: listEntityRelationship?.results,
      }));
    }
  }, [listEntityRelationship]);

  return (
    <>
      {(loadEntityRelationship || loadGroups || loadClients || loadUpdateEntityRelationship) &&
        loader}

      <div className='w-100'>
        {/* /* ---------------------------  MODAL -------------------------- */}

        <OrdModal
          hideBtnYes={data.modalCreate ? !myPermission?.create : !myPermission?.edit}
          title={data.modalCreate ? 'Crear relación entidad' : 'Actualizar relación entidad'}
          titleClassName={tableStyles.darkBlueText}
          show={data.modalActive}
          btnYesName='Aceptar'
          btnYesStyle={tableStyles.btnYesStyle}
          btnCancelStyle={tableStyles.btnCancelStyle}
          btnNoName='Cancelar'
          size='500'
          btnYesEvent={() => validateCreateOrUpdateEntityRelationship()}
          onHide={() => {
            setData(state => ({ ...state, modalActive: false }));
          }}
          btnNoEvent={() => {
            setData(state => ({ ...state, modalActive: false }));
          }}
        >
          <Box my={2}>
            <Box>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                <span>
                  {' '}
                  &nbsp;Empresa
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
                </span>
              </Form.Label>

              <Select
                value={
                  data.data.corpclientId && {
                    label: listClients?.results?.find(
                      e => Number(e.id) === Number(data.data.corpclientId),
                    )?.name,
                    value: listClients?.results?.find(
                      e => Number(e.id) === Number(data.data.corpclientId),
                    )?.id,
                  }
                }
                options={
                  listClients?.results?.length
                    ? [
                        { label: 'Seleccionar...', value: '' },
                        ...(listClients?.results?.map(e => ({
                          label: e.name,
                          value: e.id,
                        })) ?? []),
                      ]
                    : [{ label: 'Seleccionar...', value: '' }]
                }
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={customSelectNew4}
                onChange={e => {
                  setData(state => ({
                    ...state,
                    data: {
                      ...state.data,
                      corpclientId: Number(e.value),
                      copyName: e.label,
                    },
                  }));
                }}
              />
            </Box>

            <Box mt={2}>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                <span>
                  {' '}
                  &nbsp;Grupo
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
                </span>
              </Form.Label>

              <Select
                value={
                  data.data.crtId && {
                    label: listGroups?.results?.find(e => Number(e.id) === Number(data.data.crtId))
                      ?.name,
                    value: listGroups?.results?.find(e => Number(e.id) === Number(data.data.crtId))
                      ?.id,
                  }
                }
                options={
                  listGroups?.results?.length
                    ? [
                        { label: 'Seleccionar...', value: '' },
                        ...(listGroups?.results?.map(e => ({
                          label: e.name,
                          value: e.id,
                          extra: e.ivaShortName ?? '-',
                        })) ?? []),
                      ]
                    : [{ label: 'Seleccionar...', value: '' }]
                }
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={customSelectNew4}
                onChange={e => {
                  let copaymentValue = listGroups?.results?.find(
                    group => group?.id === e?.value,
                  )?.value;
                  setData(state => ({
                    ...state,
                    data: {
                      ...state.data,
                      crtId: Number(e.value),
                      vatId: e.extra,
                      copaymentRateValue: copaymentValue >= 0 ? copaymentValue : '',
                    },
                  }));
                }}
              />
            </Box>

            <Box my={2}>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                <span>
                  {' '}
                  &nbsp;Iva
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
                </span>
              </Form.Label>

              <input
                className={`register-inputs`}
                style={{ maxHeight: '32px', height: '32px' }}
                type='text'
                disabled
                value={data.data.vatId}
                maxLength={'20'}
                placeholder={'Escribir...'}
              />
            </Box>

            <Box mt={2}>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                <span>
                  {' '}
                  &nbsp;Equivalente
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
                </span>
              </Form.Label>

              <input
                className={`register-inputs`}
                style={{ maxHeight: '32px', height: '32px' }}
                type='text'
                value={data.data.equivalent}
                maxLength={'20'}
                placeholder={'Escribir...'}
                onChange={({ target }) => {
                  setData(state => ({
                    ...state,
                    data: { ...state.data, equivalent: target.value },
                  }));
                }}
              />
            </Box>

            <Box mt={2}>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                <span>
                  {' '}
                  &nbsp;Valor
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
                </span>
              </Form.Label>

              <NumberFormat
                disabled
                allowNegative={false}
                className={`register-inputs ${tableStyles.inputPlaceholder}`}
                placeholder={formatNumberCurrency(0)}
                style={{ maxHeight: '32px', height: '32px' }}
                suffix={` ${CURRENCY}`}
                thousandSeparator={localeSeparators.groupSeparator}
                decimalSeparator={localeSeparators.decimalSeparator}
                decimalScale={2}
                isNumericString
                value={String(data.data.copaymentRateValue ?? '')}
              />
            </Box>
          </Box>
        </OrdModal>

        <div className='w-100 mx-1'>
          <Row className='mt-2'>
            {myPermission?.read && (
              <div className='d-flex align-items-end gap-2'>
                <div style={{ width: '160px' }}>
                  <p className={tableStyles.crudModalLabel}>Estado</p>
                  <Select
                    options={[
                      { label: 'Seleccionar...', value: '' },
                      { label: 'Habilitado', value: 1 },
                      { label: 'Inhabilitado', value: 0 },
                    ]}
                    className='text-secondary'
                    placeholder={'Seleccionar...'}
                    styles={customSelectNew4}
                    onChange={({ value }) => {
                      setData(state => ({
                        ...state,
                        filters: { ...data.filters, enabled: value },
                      }));

                      getEntityRelationship({
                        url: `/medical/client/copaymentRate/`,
                        objFilters: { ...data.filters, enabled: value },
                        token: token,
                      });
                    }}
                  />
                </div>

                <div className={`d-flex`}>
                  <input
                    className={tableStyles.searchUsersAlt}
                    style={{ height: '32px' }}
                    type='text'
                    onKeyDown={e => handleKeyDown(e)}
                    onChange={e => {
                      setData(state => ({
                        ...state,
                        filters: { ...state.filters, search: e.target.value },
                      }));
                    }}
                    placeholder='Buscar...'
                  />

                  <img
                    style={{ width: '1.7rem' }}
                    src={Lupa}
                    alt='User icon'
                    className={`${tableStyles.iconSvgMargin2} pointer`}
                    onClick={() => {
                      incrementTrigger();
                      onGetEntityRelationship();
                    }}
                  />
                </div>

                {myPermission?.create && (
                  <div className={`${tableStyles.colorNewOrder} ms-auto`}>
                    <div
                      className='d-flex justify-content-end groupAddButton align-items-center '
                      onClick={() => {
                        setData(state => ({
                          ...state,
                          data: {
                            copaymentRateName: '',
                            copaymentRateValue: '',
                            copyName: '',
                            corpclientId: '',
                            crtId: '',
                            equivalent: '',
                            vatId: '',
                          },
                          modalActive: true,
                          modalCreate: true,
                        }));
                      }}
                    >
                      <label htmlFor='newAccident' className='darkGray fw-bold'>
                        Crear relación entidad
                      </label>
                      <button className='addCheckButton mx-2' />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Row>
          {myPermission?.read && (
            <>
              {data?.listEntityRelationship.length
                ? renderListEntityRelationship()
                : renderNotData()}
            </>
          )}
        </div>
      </div>
    </>
  );
};
