// by jhan ortegon

import { useEffect, useState } from 'react';

// icons - images
import x from '../../assets/img/icons/Cerrar.svg';
import Atras from '../../assets/img/icons/atras-icon.svg';
import iconCamera from '../../assets/img/icons/blueCamera.svg';

// css
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import bankMoveStyle from '../../TreasuryModule/MovimientosBancarios/bankMoveStyles.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Styles from '../PayRollModule/Hiring/Contract.module.scss';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useGetMethod } from '../../Hooks/useFetch';

// helpers
import NumberFormat from 'react-number-format';
import { customSwaltAlert } from '../../helpers';
import { convertBase64, isEmptyOrUndefined, validateEmail, formatteDocument } from '../../helpers';
import { nitCalculate } from '../../helpers/nitCalculate';
import { getLocaleSeparators } from '../../helpers/numberFormatting';

// services
import {
  creationAccounts,
  getBusinessType,
  getSocietyType,
  updateAccounts,
} from '../../actions/AccountsActions';
import { get_doc_types } from '../../actions/deductionsAccrualsAction';

// Components
import { Card, Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { Title } from '../../shared/Title';
import { OnwerModule } from './OnwerModule';

const localeSeparators = getLocaleSeparators();

const AccountInfo = props => {
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const token = storage.loginReducer.Authorization;
  const [trigger, setTrigger] = useState(0);
  const [info, setInfo] = useState({
    eaccount: storage.loginReducer.currentAccount.id,
    user: storage.loginReducer.user_data.id,
    bussines_name: '',
    name: '',
    nit: '',
    btype_id: undefined,
    society_id: undefined,
    cuenta_email: '',
    sds_code: '',
    phone: '',
    address: '',
    idType: undefined,
    file_name: '',
    base64: '',
    lastD: '',
    lr_first_name: '',
    lr_second_name: '',
    lr_first_surname: '',
    lr_second_surname: '',
    legal_rep_id: '',
    legal_rep_issued: '',
    legal_rep_idtype: undefined,
    owner_modules: [],
  });

  const { trigger: getModules, results: modulesResults } = useGetMethod();
  const { trigger: getUsers, results: usersResults } = useGetMethod();

  const [formFields, setFormFields] = useState([{ module: '', owner: '' }]);

  const [ownerOptions, setOwnerOptions] = useState({
    modulesOptions: [{ value: '', label: 'Seleccionar...' }],
    usersOptions: [{ value: '', label: 'Seleccionar...' }],
  });

  const [isLoading, setIsLoading] = useState(false);

  let businessType = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.pucReducer.businessType)) {
    storage.pucReducer.businessType.forEach(item => {
      businessType.push({
        value: item.id,
        label: item.description,
        key: item.id + 'businessType',
      });
    });
  }
  let societyType = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.pucReducer.societyType)) {
    storage.pucReducer.societyType.forEach(item => {
      societyType.push({
        value: item.id,
        label: item.description,
        key: item.id + 'societyType',
      });
    });
  }
  let businessDocType = [
    { key: 'default', value: '', label: 'Seleccionar...' },
    { key: 'businessDocType1', value: 'NI', label: 'NIT' },
    { key: 'businessDocType2', value: 'CC', label: 'Cédula de ciudadanía' },
    { key: 'businessDocType3', value: 'CE', label: 'Cédula de extranjería' },
    { key: 'businessDocType4', value: 'PA', label: 'Pasaporte' },
    { key: 'businessDocType5', value: 'CD', label: 'CD' },
    { key: 'businessDocType6', value: 'PE', label: 'Permiso Especial' },
  ];

  let optionsDocType = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.deductionsAccrualsReducer.allDocTypes)) {
    storage.deductionsAccrualsReducer.allDocTypes.forEach(item => {
      optionsDocType.push({
        value: item.id,
        label: item.description,
        key: item.id + 'docType',
      });
    });
  }

  let optionsInvoices = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.objected_by_client)) {
    storage.billingReducer.objected_by_client.forEach(item => {
      optionsInvoices.push({
        value: item.id,
        label: item.sequence,
        key: item.id + 'objectedByClient',
      });
    });
  }

  let optionsNotes = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.note_types)) {
    storage.billingReducer.note_types.forEach(item => {
      optionsNotes.push({
        value: item.id,
        label: item.name,
        key: item.id + 'notes',
      });
    });
  }
  const handleSubmit = (data, owner_modules) => {
    if (isEmptyOrUndefined(data.bussines_name)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo razón social es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.name)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo abreviación es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.cuenta_email)) {
      if (!validateEmail(data.cuenta_email)) {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El campo correo es obligatorio',
          showCancelButton: false,
        });
      }
    }

    if (isEmptyOrUndefined(info?.file_name || info?.entity_logo)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El archivo logo de la entidad es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.address)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo dirección de la empresa es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.idType)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo tipo de documento de la empresa es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.nit)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo nit es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.btype_id)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo tipo de empresa es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.society_id)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo tipo de sociedad es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.phone)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo telefono de la empresa es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.lr_first_name)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo primer nombre es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.lr_first_surname)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo primer apellido es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.legal_rep_idtype)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo tipo de documento del representante legal es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.legal_rep_id)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo número del docuento del representante legal es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.legal_rep_issued)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo lugar de expedición del documento del representante legal es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.legal_phone_number)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo celular es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(data.sign)) {
      if (!data.base64_sign) {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El archivo firma del represetante legal es obligatorio',
          showCancelButton: false,
        });
      }
    }

    if (
      !owner_modules.every(({ module, owner }) => {
        if (isEmptyOrUndefined(module) && isEmptyOrUndefined(owner) && owner_modules.length > 1) {
          return false;
        }
        if (
          (!isEmptyOrUndefined(module) && isEmptyOrUndefined(owner)) ||
          (isEmptyOrUndefined(module) && !isEmptyOrUndefined(owner))
        ) {
          return false;
        }
        return true;
      })
    ) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Para cada módulo debe haber escogido un usuario responsable',
        showCancelButton: false,
      });
    }

    if (props.editAcc) {
      setIsLoading(true);
      const formatData = { ...data, owner_modules };
      delete formatData.active;

      dispatch(
        updateAccounts(
          formatData,
          () => {
            setInfo({});
            setTrigger(trigger + 1);
            props.setEdit(false);
            props.setUnconfirmedFilters({
              search: '',
              active: '',
            });
            props.setFilter({
              search: '',
              active: '',
              page: 1,
              perpage: 10,
            });
            props.setPutState({});
            props.setTrigger(trigger + 1);
            setIsLoading(false);
          },
          () => {
            setIsLoading(false);
          },
        ),
      );
    } else {
      const payload = { ...data, owner_modules };
      setIsLoading(true);
      dispatch(
        creationAccounts(
          payload,
          () => {
            setInfo({});
            setTrigger(trigger + 1);
            props.setCreateAcc(false);
            props.setUnconfirmedFilters({
              search: '',
              active: '',
            });
            props.setFilter({
              search: '',
              active: '',
              page: 1,
              perpage: 10,
            });
            props.setPutState({});
            props.setTrigger(trigger + 1);
            setIsLoading(false);
          },
          () => {
            setIsLoading(false);
          },
        ),
      );
    }
  };
  const handleImagePick = async e => {
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    setInfo({
      ...info,
      file_name: e.target.files[0].name,
      base64: file64,
      entity_logo: '',
      fileUrl: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handleModuleOwner = ({ value }, name, index) => {
    let data = [...formFields];
    data[index][name] = value;
    setFormFields(data);
  };

  const addModuleOwnerFields = () => {
    if (formFields.length < ownerOptions.modulesOptions.length) {
      const newField = {
        module: '',
        owner: '',
      };
      const newFormFields = [...formFields];
      newFormFields.splice(newFormFields.length, 0, newField);
      setFormFields(newFormFields);
    }
    return;
  };

  const removeModuleOwnerFields = index => {
    const fields = [...formFields];
    fields.splice(index, 1);
    setFormFields(fields);
  };

  const handleImagePick_sign = async e => {
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    setInfo({
      ...info,
      sign: '',
      file_name_sign: e.target.files[0].name,
      base64_sign: file64,
      fileUrl_sign: URL.createObjectURL(e.target.files[0]),
    });
  };
  let asterisk = <b style={{ color: '#FF8B00' }}>*</b>;

  useEffect(() => {
    const results = modulesResults?.results;
    if (results) {
      const moduleOptions = [
        { value: '', label: 'Seleccionar...' },
        ...results.flatMap(obj =>
          obj.categories.map(({ id, name }) => ({ value: id, label: name })),
        ),
      ];
      setOwnerOptions(state => ({
        ...state,
        modulesOptions: moduleOptions,
      }));
    }
  }, [modulesResults]);

  useEffect(() => {
    const results = usersResults?.results;
    if (results) {
      let usersOptions = [
        { value: '', label: 'Seleccionar...' },
        ...results.map(({ id, first_name, first_surname }) => ({
          value: id,
          label: `${first_name} ${first_surname}`,
        })),
      ];

      if (props?.editAcc || props?.detailAcc) {
        const ownerModulesToAdd = props?.accInfo?.owner_modules?.filter(
          field => field.owner !== null,
        );

        const filteredOwnerModules = ownerModulesToAdd.filter(
          field => !results.some(result => result.id === field.owner),
        );

        const ownerOptionsToAdd = filteredOwnerModules.map(({ owner, owner_name }) => ({
          value: owner,
          label: owner_name,
        }));

        usersOptions = [...usersOptions, ...ownerOptionsToAdd];
      }

      setOwnerOptions(state => ({
        ...state,
        usersOptions: usersOptions,
      }));
    }
  }, [props?.accInfo?.owner_modules, props?.detailAcc, props?.editAcc, usersResults]);

  useEffect(() => {
    getModules({
      url: '/security/modules/',
      token: token,
    });
    getUsers({
      url: '/users/params/',
      objFilters: {
        page: 1,
        perpage: 10,
        active: 1,
      },
      token: token,
    });
    dispatch(getBusinessType({ eaccount: storage?.loginReducer?.currentAccount?.id }));
    dispatch(getSocietyType({ eaccount: storage?.loginReducer?.currentAccount?.id }));
    dispatch(get_doc_types());

    if (props?.editAcc || props?.detailAcc) {
      setInfo(props?.accInfo);
      if (!isEmptyOrUndefined(props?.accInfo?.owner_modules)) {
        setFormFields(props?.accInfo?.owner_modules);
      }
    }
  }, [
    dispatch,
    props?.accInfo,
    props?.detailAcc,
    props?.editAcc,
    storage?.loginReducer?.currentAccount?.id,
    getModules,
    token,
    getUsers,
  ]);

  return (
    <>
      <div style={{ paddingTop: '30px' }} className={bankMoveStyle.centredContainer}>
        <div className={bankMoveStyle.container2}>
          <div className={tableStyles.container1}>
            <div className={tableStyles.ItemSmall}>
              <div className='d-flex'>
                {(storage.billingReducer.loading_create_invoice || isLoading) && (
                  <div className='loading'>
                    <Loader type='Oval' color='#003f80' height={100} width={100} />
                  </div>
                )}
                {props.createAcc ? (
                  <>
                    <Title
                      title='Creación de cuenta'
                      onClickIcon={() => {
                        props.setCreateAcc(false);
                        props.setUnconfirmedFilters({
                          search: '',
                          active: '',
                        });
                        props.setFilter({
                          search: '',
                          active: '',
                          page: 1,
                          perpage: 10,
                        });
                        setInfo({});
                        setTrigger(trigger + 1);
                        props.setPutState({});
                        props.setTrigger(trigger + 1);
                      }}
                    />
                  </>
                ) : !!props.editAcc || !!props.detailAcc ? (
                  <>
                    <img
                      className={`${tableStyles.title} mr-3 hoverPointer`}
                      src={Atras}
                      alt='Atras'
                      onClick={() => {
                        props.setEdit(false);
                        props.setDetail(false);
                        props.setUnconfirmedFilters({
                          search: '',
                          active: '',
                        });
                        props.setFilter({
                          search: '',
                          active: '',
                          page: 1,
                          perpage: 10,
                        });
                        setTrigger(trigger + 1);
                        props.setPutState({});
                        props.setTrigger(trigger + 1);
                      }}
                    />
                    <h1 className={tableStyles.title}>
                      {props.detailAcc ? 'Detalle de cuenta' : 'Editar cuenta'}
                    </h1>
                  </>
                ) : (
                  <h1 className={tableStyles.title}>Información de cuenta</h1>
                )}
              </div>
            </div>
          </div>
          {/* ---------------------------- ROW 1 ---------------------------------- */}
          <h6 className={`${tableStyles.darkBlueText} mt-4`}>
            <b>Información General</b>
          </h6>
          <div className='d-flex'>
            <div className='mr-3' style={{ width: '70%' }}>
              <Row className='d-flex' style={{ width: '100%' }}>
                <Col xs={6}>
                  <p className={tableStyles.crudModalLabel}>Razón social{asterisk}</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    key={'bsname' + trigger}
                    style={{ color: '#58595B' }}
                    defaultValue={props?.accInfo?.bussines_name}
                    onChange={e => setInfo({ ...info, bussines_name: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                    type='text'
                  />
                </Col>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Abreviación{asterisk}</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    key={'abrv' + trigger}
                    style={{ color: '#58595B' }}
                    type='text'
                    defaultValue={props?.accInfo?.name}
                    onChange={e => setInfo({ ...info, name: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Correo electrónico{asterisk}</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    key={'email' + trigger}
                    type='text'
                    defaultValue={props?.accInfo?.cuenta_email}
                    onChange={e => setInfo({ ...info, cuenta_email: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
              </Row>
              <Row className='d-flex' style={{ width: '100%' }}>
                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Dirección{asterisk}</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    type='text'
                    key={'address' + trigger}
                    defaultValue={props?.accInfo?.address}
                    onChange={e => setInfo({ ...info, address: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Tipo de doc.{asterisk}</p>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    key={'idType' + trigger + props.trigger}
                    options={businessDocType}
                    value={businessDocType.find(x => x.value === info.idType)}
                    styles={customSelectNewDark}
                    onChange={e =>
                      setInfo({
                        ...info,
                        idType: e.value,
                      })
                    }
                    placeholder='Seleccionar...'
                    isDisabled={props.detailAcc ? true : false}
                  />
                </Col>

                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Nit{asterisk}</p>
                  <NumberFormat
                    key={'nit' + trigger}
                    style={props?.accInfo?.nit ? { cursor: 'default' } : null}
                    className={`register-inputs ${IndividualStyles.registerInputs}`}
                    name='Nit'
                    type='tel'
                    thousandSeparator={localeSeparators.groupSeparator}
                    decimalSeparator={localeSeparators.decimalSeparator}
                    decimalScale={0}
                    placeholder='000.000.000.000'
                    defaultValue={props?.accInfo?.nit}
                    readOnly={!!props?.accInfo?.nit}
                    isAllowed={values => values.value.length <= 10}
                    onValueChange={values => {
                      const { value } = values;
                      setInfo({ ...info, nit: value, nitDigit: nitCalculate(value) });
                    }}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
                <Col xs={1}>
                  <p className={tableStyles.crudModalLabel}>ㅤ</p>
                  <label
                    readOnly
                    className={IndividualStyles.registerInputs}
                    style={{
                      color: '#58595B',
                      backgroundColor: `${props.detailAcc ? '#f5f7fa' : ''}`,
                    }}
                  >
                    {props?.accInfo?.nit
                      ? nitCalculate(props?.accInfo?.nit?.toString())
                      : info?.nitDigit}
                  </label>
                </Col>
              </Row>
              <Row className='d-flex' style={{ width: '100%' }}>
                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Tipo de empresa{asterisk}</p>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    key={'tipoemp' + trigger + props.trigger}
                    options={businessType}
                    placeholder='Seleccionar...'
                    value={businessType.find(x => x.value === info.btype_id)}
                    styles={customSelectNewDark}
                    onChange={e =>
                      setInfo({
                        ...info,
                        btype_id: e.value,
                      })
                    }
                    isDisabled={props.detailAcc ? true : false}
                  />
                </Col>
                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Tipo de sociedad{asterisk}</p>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    key={'tiposociedad' + trigger + props.trigger}
                    options={societyType}
                    placeholder='Seleccionar...'
                    value={societyType.find(x => x.value === info.society_id)}
                    styles={customSelectNewDark}
                    onChange={e =>
                      setInfo({
                        ...info,
                        society_id: e.value,
                      })
                    }
                    isDisabled={props.detailAcc ? true : false}
                  />
                </Col>

                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Prestador de servicios de salud</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    type='number'
                    key={'sdscode' + trigger}
                    defaultValue={props?.accInfo?.sds_code}
                    onChange={e => setInfo({ ...info, sds_code: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
              </Row>

              <Row className='d-flex' style={{ width: '100%' }}>
                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Teléfono{asterisk}</p>
                  <NumberFormat
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    key={'phone' + trigger}
                    onChange={e => setInfo({ ...info, phone: e.target.value })}
                    decimalScale={0}
                    defaultValue={props?.accInfo?.phone}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
              </Row>
            </div>
            <div style={{ width: '20%' }}>
              <Col>
                <p className={tableStyles.crudModalLabel}>Logo de la entidad{asterisk}</p>
                <Card border='primary' className='w-100 ' style={{ width: '20%', height: '10rem' }}>
                  {info?.fileUrl || !!info?.entity_logo ? (
                    <>
                      {!props?.detailAcc && (
                        <img
                          style={{ position: 'absolute' }}
                          className={` hoverPointer  mt-2 mr-2`}
                          src={x}
                          onClick={() =>
                            setInfo({
                              ...info,
                              file_name: '',
                              base64: '',
                              fileUrl: '',
                              entity_logo: '',
                            })
                          }
                          alt='Eliminar'
                        />
                      )}

                      <img
                        style={{
                          maxWidth: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                        src={info?.entity_logo ? info.entity_logo : info?.fileUrl}
                        alt='imagen usuario '
                      />
                    </>
                  ) : (
                    <>
                      <span className='mt-4'></span>
                      <p className={` text-center text-secondary pt-5`}>
                        Insertar imagen{asterisk}
                      </p>
                    </>
                  )}
                  {!props?.detailAcc && (
                    <label
                      htmlFor='imageUpload'
                      className={`${Styles.imageInputLabel} ${Styles.hoverBlue}`}
                    >
                      <img className={`hoverPointer`} src={iconCamera} alt='cambiar imagen' />
                    </label>
                  )}

                  <input
                    accept='image/png'
                    id='imageUpload'
                    name='imageUpload'
                    type='file'
                    onChange={handleImagePick}
                    className={Styles.imageInputBtn}
                  />
                </Card>
              </Col>
            </div>
          </div>

          <h6 className={`${tableStyles.darkBlueText} mt-5`}>
            <b>Representante legal</b>
          </h6>
          <div className='d-flex'>
            <div className='mr-3' style={{ width: '70%' }}>
              <Row className='d-flex' style={{ width: '100%' }}>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Primer Nombre{asterisk}</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    key={'lrFirstName' + trigger}
                    defaultValue={props?.accInfo?.lr_first_name}
                    type='text'
                    onChange={e => setInfo({ ...info, lr_first_name: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Segundo Nombre</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    key={'lrSecondName' + trigger}
                    defaultValue={props?.accInfo?.lr_second_name}
                    type='text'
                    onChange={e => setInfo({ ...info, lr_second_name: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Primer Apellido{asterisk}</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    key={'lrFirstSurname' + trigger}
                    defaultValue={props?.accInfo?.lr_first_surname}
                    type='text'
                    onChange={e => setInfo({ ...info, lr_first_surname: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Segundo Apellido</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    key={'lrSecondSurname' + trigger}
                    type='text'
                    defaultValue={props?.accInfo?.lr_second_surname}
                    onChange={e => setInfo({ ...info, lr_second_surname: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
              </Row>
              <Row className='d-flex' style={{ width: '100%' }}>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Tipo de doc.{asterisk}</p>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    key={'tipodoclegl' + trigger + props.trigger}
                    options={optionsDocType}
                    placeholder='Seleccionar...'
                    value={optionsDocType.find(x => x.value === info.legal_rep_idtype)}
                    styles={customSelectNewDark}
                    onChange={e =>
                      setInfo({
                        ...info,
                        legal_rep_idtype: e.value,
                      })
                    }
                    isDisabled={props.detailAcc ? true : false}
                  />
                </Col>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Número de doc.{asterisk}</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    key={'legalRepId' + trigger}
                    defaultValue={formatteDocument(props?.accInfo?.legal_rep_id ?? "").format}
                    value={formatteDocument(info.legal_rep_id).format}
                    type='text'
                    onChange={e => setInfo({ ...info, legal_rep_id: formatteDocument(e.target.value).value})}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Lugar de exp.{asterisk}</p>
                  <input
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    key={'legalRepIssued' + trigger}
                    type='text'
                    defaultValue={props?.accInfo?.legal_rep_issued}
                    onChange={e => setInfo({ ...info, legal_rep_issued: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                  />
                </Col>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Celular{asterisk}</p>
                  <NumberFormat
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#58595B' }}
                    key={'legalPhoneNumber' + trigger}
                    defaultValue={props?.accInfo?.legal_phone_number}
                    onChange={e => setInfo({ ...info, legal_phone_number: e.target.value })}
                    disabled={props.detailAcc ? true : false}
                    decimalScale={0}
                  />
                </Col>
              </Row>
            </div>
            <div style={{ width: '20%' }}>
              <Col>
                <p className={tableStyles.crudModalLabel}>Firma{asterisk}</p>
                <Card border='primary' className='w-100 ' style={{ width: '20%', height: '10rem' }}>
                  {info?.fileUrl_sign || !!info?.sign ? (
                    <>
                      {!props?.detailAcc && (
                        <img
                          style={{ position: 'absolute' }}
                          className={` hoverPointer  mt-2 mr-2`}
                          src={x}
                          onClick={() =>
                            setInfo({
                              ...info,
                              file_name_sign: '',
                              base64_sign: '',
                              fileUrl_sign: '',
                              sign: '',
                            })
                          }
                          alt='Eliminar firma'
                        />
                      )}

                      <img
                        style={{
                          maxWidth: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                        src={info?.sign ? info.sign : info?.fileUrl_sign}
                        alt='firma usuario '
                      />
                    </>
                  ) : (
                    <>
                      <span className='mt-4'></span>
                      <p className={` text-center text-secondary pt-5`}>Insertar firma{asterisk}</p>
                    </>
                  )}
                  {!props?.detailAcc && (
                    <label
                      htmlFor='imageUpload_sign'
                      className={`${Styles.imageInputLabel} ${Styles.hoverBlue}`}
                    >
                      <img className={`hoverPointer`} src={iconCamera} alt='cambiar firma' />
                    </label>
                  )}
                  <input
                    accept='image/png'
                    id='imageUpload_sign'
                    name='imageUpload_sign'
                    type='file'
                    onChange={handleImagePick_sign}
                    className={Styles.imageInputBtn}
                  />
                </Card>
              </Col>
            </div>
          </div>
          <h6 className={`${tableStyles.darkBlueText} mt-5`}>
            <b>Responsable por módulo</b>
          </h6>

          {formFields.map((form, index) => {
            return (
              <OnwerModule
                key={index + props?.trigger}
                index={index}
                form={form}
                props={props}
                defaultModuleOptions={ownerOptions?.modulesOptions}
                defaultUserOptions={ownerOptions?.usersOptions}
                handleModuleOwner={handleModuleOwner}
                addModuleOwnerFields={addModuleOwnerFields}
                removeModuleOwnerFields={removeModuleOwnerFields}
              ></OnwerModule>
            );
          })}
        </div>
        <Row className='d-flex' style={{ width: '95%' }}>
          <div className='d-flex justify-content-end'>
            {!props.detailAcc && (
              <button
                className='btn-primary rounded-2 my-3'
                onClick={() => handleSubmit(info, formFields)}
              >
                {props.editAcc ? 'Editar' : 'Guardar'}
              </button>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};

export default AccountInfo;
