// by jhan ortegon

import { Component } from 'react';

// icons - images
import addCheck from '../../assets/img/icons/add-check.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

// css
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';

// hooks
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// helpers
import * as deepcopy from 'deepcopy';
import { PAGE, PERPAGE } from '../../helpers/constants';
import { generateId, optionsSelect, valueSelect } from '../../helpers/helpers';

// services
import { accounts_get } from '../../actions/cuentasActions';
import { city_get, province_get } from '../../actions/locationActions';
import {
  changeStatus,
  createSite,
  getListDistances,
  getResolution,
  getSites,
  getSitesSelect,
  updateSite,
} from '../../actions/sitesActions';

// Components
import { Box } from '@mui/material';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import { ordCustomSelect } from '../Layouts/react-select-custom';
import CustomPopup from '../Popup/customPopup';
import SelectComponent from '../SelectComponent/SelectComponent';
import { Text } from '../UI/atoms';
import NumberFormat from "react-number-format";

class TableSites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal: 'Agregar sede',
      modal: false,
      editRecord: false,
      copySite: null,
      siteArray: [],
      addSite: {
        id: '',
        description: '',
        prefix: '',
        phone: '',
        address: '',
        id_account: this.props.currentAccount.id,
        id_province: '',
        id_city: '',
        id_resolution: [],
        resolutions: [],
        is_virtual: false,
      },
      filters: {
        search: '',
        perpage: PERPAGE,
        page: PAGE,
        id_account: this.props.currentAccount.id,
      },
      headerState: [
        {
          tittle: 'Descripción',
          className: 'text-start px-3',
        },
        {
          tittle: 'Dirección',
          className: 'text-start',
        },
        {
          tittle: 'Estado',
          className: 'w100',
        },
      ],
    };
  }

  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === 'Sites');

  componentDidMount() {
    const { history } = this.props;
    if (!this.myPermission()?.read) {
      history?.push('/compras/inicio');
    }

    this.props.getSites(this.state.filters);
    this.props.getSitesSelect({
      eaccount: this.props.currentAccount.id,
      modality: 'on_site',
      status: 'enabled',
    });
    this.props.getListDistances();
    this.props.getResolution({
      status: 1,
    });
    this.props.province_get({});
    this.props.city_get({});
    this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.createSucces !== prevProps.createSucces) {
      if (this.props.createSucces) {
        this.setState({
          modal: false,
        });
      }
    }
  }

  // AQUÍ
  getInitialState = () => {
    this.setState({
      titleModal: 'Agregar sede',
      modal: false,
      editRecord: false,
      siteArray: [],
      addSite: {
        id: '',
        description: '',
        prefix: '',
        phone: '',
        address: '',
        id_account: this.props.currentAccount.id,
        id_province: '',
        id_city: '',
        id_resolution: '',
        resolutions: [],
        is_virtual: false,
      },
      filters: {
        search: '',
        perpage: PERPAGE,
        page: PAGE,
        id_account: this.props.currentAccount.id,
      },
      headerState: [
        {
          tittle: 'Descripción',
          className: 'text-start px-3',
        },
        {
          tittle: 'Dirección',
          className: 'text-start',
        },
        {
          tittle: 'Estado',
          className: 'w100',
        },
      ],
    });
  };

  addSede = () => {
    const siteObj = {
      site: '',
      distance: '',
      unit: '',
      id: generateId(),
    };

    const arraySites = [...(this?.state?.siteArray ?? []), siteObj];

    this.setState({ siteArray: arraySites });
  };

  removeSede = id => {
    const filter = this.state.siteArray.filter(el => el.id !== id);
    this.setState({ siteArray: filter });
  };

  handleSearchChange(text) {
    let { filters } = this.state;
    filters.search = text;
    this.setState({ filters });
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getSites(this.state.filters);
  }

  handleEditRecord = site => {
    let { modal, addSite, titleModal } = this.state;
    modal = !this.state.modal;
    addSite = deepcopy(site);
    titleModal = 'Editar sede';

    let filter = [];
    if (site.siteCloses.length) {
      filter = site.siteCloses.map(el => ({
        id: generateId(),
        site: el.id,
        distance: el.distance,
        unit: el.distanceTypeId,
      }));
    }

    this.setState({ modal, addSite, editRecord: true, titleModal, siteArray: filter });
  };

  toggleStatus = site => {
    this.props.changeStatus(site, this.state.filters);
  };

  // Search sites when user was typing
  onKeyUpSearch = e => {
    const value = e.target.value;
    let { filters } = this.state;
    if (value !== '') {
      if (e.keyCode === 13) {
        filters.search = value;
        this.setState({ filters });
        this.props.getSites(this.state.filters);
      } else {
        e.preventDefault();
      }
    } else {
      filters.search = '';
      this.setState({ filters });
      this.props.getSites(this.state.filters);
    }
  };

  toggleModal = () => {
    let { modal } = this.state;
    modal = !modal;
    if (this.state.editRecord) {
      this.getInitialState();
    }
    this.getInitialState();
    this.setState({ modal });
  };

  onChangeForm = e => {
    const name = e.target.name;
    let value = e.target.value;
    if (e.target.name === 'is_virtual') {
      value = this.state.addSite.is_virtual === false ? true : false;
    }
    let { addSite } = this.state;
    addSite[name] = value;
    this.setState({ addSite });
  };

  onChangeForm2 = (e, id) => {
    const value = e?.target?.value || e.value;
    const name = e?.target?.name || e.extra;
    const filter = this.state.siteArray.map(state => {
      if (state.id === id && name === 'description') {
        return { ...state, distance: value };
      } else if (state.id === id && 'distance' === name.split('_')[0]) {
        return { ...state, unit: value };
      } else if (state.id === id && 'site' === name.split('_')[0]) {
        return { ...state, site: value };
      } else {
        return state;
      }
    });
    this.setState({ siteArray: filter });
  };

  onChangeFormSelect = (target, state) => {
    const name = state.name;
    const value = target.value;
    let { addSite } = this.state;
    addSite[name] = value;
    this.setState({ addSite });
  };

  handleProvinceChange = ({ value }) => {
    let { addSite } = this.state;
    addSite = { ...addSite, id_province: value };
    this.setState({ addSite });
    //this.props.city_get({province: e.target.value});
  };

  handleSubmit() {
    let { addSite } = this.state;
    addSite.id_resolution = addSite.resolutions[0];
    if (this.state.editRecord) {
      return this.props.updateSite(
        {...this.state.addSite,
          resolutions: this.state.addSite.resolutions.filter(x=> x !== "")
        },
        this.state.filters,
        this.state.siteArray,
        () => this.getInitialState(),
      );
    }
    this.props.createSite(
      {...this.state.addSite,
        resolutions: this.state.addSite.resolutions.filter(x=> x !== "")
      },
      this.state.filters,
      { objOnSite: this.state.siteArray, initial: this.getInitialState },
      () => {
        this.getInitialState();
      },
    );
  }

  renderSites(site) {
    return (
      <tr key={site.id}>
        <td className='col-md-4 text-start px-3'>{site.description}</td>
        <td className='col-md-5 text-start'>{site.address}</td>
        <td className='col-md-1'>
          <div className={tableStyles.groupElems}>
            <div className={`${site.active ? tableStyles.greenState : tableStyles.redState} mx-1`}>
              {site.active ? 'Habilitado' : 'Inhabilitado'}
            </div>

            {this.myPermission()?.edit && (
              <CustomPopup
                triggerSrc={threeDots}
                showEdit={true && !!this.myPermission()?.edit}
                editClickEvent={() => this.handleEditRecord(site)}
                showEnable={true && !!this.myPermission()?.delete}
                isEnabled={site.active}
                enableClickEvent={() => this.toggleStatus(site)}
              />
            )}
          </div>
        </td>
      </tr>
    );
  }

  renderAccounts() {
    const gotAccounts = this.props.cuentas
      ? this.props.cuentas.map(account => {
          return (
            <option key={account.id} value={account.id}>
              {account.name}
            </option>
          );
        })
      : '';

    return (
      <select
        name='id_account'
        className='register-inputs'
        onChange={this.onChangeForm}
        value={this.state.addSite.id_account}
      >
        <option value='' defaultValue>
          Seleccionar...
        </option>
        {gotAccounts}
      </select>
    );
  }

  renderProvinces() {
    return (
      <SelectComponent
        options={optionsSelect({ list: this.props.provinces, label: 'name', value: 'id' })}
        value={valueSelect({
          list: this.props.provinces,
          findId: 'id',
          findLabel: 'name',
          value: this.state.addSite.id_province,
        })}
        onChange={this.handleProvinceChange}
      />
    );
  }

  renderCities() {
    return (
      <SelectComponent
        options={optionsSelect({
          list: this.props.cities.filter(
            city => city.province === parseInt(this.state.addSite.id_province),
          ),
          label: 'name',
          value: 'id',
        })}
        value={valueSelect({
          list: this.props.cities.filter(
            city => city.province === parseInt(this.state.addSite.id_province),
          ),
          findId: 'id',
          findLabel: 'name',
          value: this.state.addSite.id_city,
        })}
        onChange={({ value }) => {
          let { addSite } = this.state;
          addSite['id_city'] = value;
          this.setState({ addSite });
        }}
      />
    );
  }
  renderResolutions() {
    const formattedValue = this.props.resolutions
      .filter(resolution => this.state.addSite?.resolutions.includes(resolution.id))
      .map(resolution => ({
        label: `${resolution.typeBill.name} - ${resolution.type === 'allow' ? 'Autoriza' : 'Habilita'} - N° ${resolution.nbr}
        del ${resolution.authDate} hasta ${resolution.dueDate} - ${resolution.accountName}`,
        value: resolution.id,
      }));
    return (
      <SelectComponent
        isMulti
        styles={ordCustomSelect}
        className={tableStyles.heightScrollSelectApp}
        closeMenuOnSelect={false}
        options={optionsSelect({
          list: this.props.resolutions.map(resolution => ({
            label: `${resolution.typeBill.name} - ${resolution.type === 'allow' ? 'Autoriza' : 'Habilita'} - N° ${resolution.nbr}
                    del ${resolution.authDate} hasta ${resolution.dueDate} - ${resolution.accountName}`,
            value: resolution.id,
          })),
          label: 'label',
          value: 'value',
        })}
        value={formattedValue}
        onChange={value => {
            let { addSite } = this.state;
            addSite['resolutions'] = value.map(res => res.value);
            this.setState({ addSite });
        }}
      />
    );
  }

  renderNearbyLocations() {
    //ANCHOR - ANCHOR
    return (
      <>
        {this.state.siteArray.length ? (
          <>
            {this.state.siteArray.map(element => {
              return (
                <Box
                  key={element.id}
                  display={'grid'}
                  gridTemplateColumns={'1fr 1fr'}
                  columnGap='10px'
                  alignItems={'center'}
                  margin='1rem 0'
                >
                  <SelectComponent
                    name={`site_${element.id}`}
                    options={optionsSelect({
                      list: this.props.sitesSelect,
                      label: 'site',
                      value: 'siteId',
                      extra: `site_${element.id}`,
                    })}
                    value={valueSelect({
                      list: this.props.sitesSelect,
                      findId: 'siteId',
                      findLabel: 'site',
                      value: element.site,
                    })}
                    onChange={e => this.onChangeForm2(e, element.id)}
                  />
                  <Box display='grid' gridTemplateColumns={'1fr 1fr 20px'} columnGap='5px'>
                    <input
                      className='register-inputs'
                      name='description'
                      type='number'
                      placeholder='Distancia'
                      onChange={e => this.onChangeForm2(e, element.id)}
                      value={element.distance}
                    />
                    <SelectComponent
                      name={`distance_${element.id}`}
                      options={optionsSelect({
                        list: this.props.distancesSelect,
                        label: 'disSymbol',
                        value: 'disId',
                        extra: `distance_${element.id}`,
                      })}
                      value={valueSelect({
                        list: this.props.distancesSelect,
                        findId: 'disId',
                        findLabel: 'disSymbol',
                        value: element.unit,
                      })}
                      onChange={e => this.onChangeForm2(e, element.id)}
                    />
                    <Text
                      title={'x'}
                      margin='0px 0px 5px 0px'
                      pointer
                      handleClick={() => {
                        this.removeSede(element.id);
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
          </>
        ) : (
          ''
        )}
      </>
    );
  }

  render() {
    const handleSumit = e => {
      e.preventDefault();
      this.props.getSites(this.state.filters);
    };
    let renderHeaders = this.state.headerState.map((header, index) => {
      return (
        <th key={index}>
          <div
            className={`${header === 'est.' || header === '' ? tableStyles.groupElems : ''} ${header.className}`}
          >
            {header.tittle}
          </div>
        </th>
      );
    });
    const renderSites = this.props.sites
      ? this.props.sites.map(site => {
          return this.renderSites(site);
        })
      : '';

    return (
      <div className={tableStyles.container}>
        {this.props?.changeStatusLoading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        {this.props.loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        {this.myPermission()?.read ? (
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}>Sedes</h1>

            <div className={`${tableStyles.mainRow}`}>
              <div>
                {this.myPermission()?.read && (
                  <form onSubmit={handleSumit}>
                    <input
                      className={tableStyles.searchUsersAlt}
                      type='text'
                      placeholder='Buscar...'
                      onChange={e => this.handleSearchChange(e.target.value)}
                    />

                    <img
                      src={Lupa}
                      alt='Search icon'
                      className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                      onClick={() => this.props.getSites(this.state.filters)}
                    />
                  </form>
                )}
              </div>

              <div className={tableStyles.groupElems}>
                {this.myPermission()?.create && (
                  <div
                    className='d-flex justify-content-end groupAddButton align-items-center'
                    onClick={this.toggleModal}
                  >
                    <label htmlFor='newAccident' className='darkGray fw-bold'>
                      Nueva sede
                    </label>
                    <button className='addCheckButton mx-2' />
                  </div>
                )}
              </div>
            </div>

            <ModalNew
              title={this.state.titleModal}
              show={this.state.modal}
              btnYesEvent={() => this.handleSubmit()}
              hideFooter={
                this.state.editRecord ? !this.myPermission()?.edit : !this.myPermission()?.create
              }
              onHide={() => {
                this.toggleModal(false);
                this.getInitialState();
              }}
            >
              <div className='row justify-content-center mb-4'>
                <div className='col-md-6'>
                  <p className={`label-inputs ${tableStyles.crudModalLabel}`}>
                    Nombre de la sede <span className='text-danger'>*</span>
                  </p>
                  <input
                    className='register-inputs'
                    name='description'
                    type='text'
                    placeholder='Escribe...'
                    onChange={this.onChangeForm}
                    value={this.state.addSite.description}
                  />
                </div>
              </div>
              <div className='row flex-around'>
                <div className='flex-1'>
                  <p className={`label-inputs ${tableStyles.crudModalLabel}`}>
                    Prefijo <span className='text-danger'>*</span>
                  </p>
                  <input
                    className='register-inputs'
                    name='prefix'
                    type='text'
                    placeholder='Escribe...'
                    onChange={this.onChangeForm}
                    value={this.state.addSite.prefix}
                  />
                </div>
                <div className='flex-1'>
                  <p className={`label-inputs ${tableStyles.crudModalLabel}`}>
                    Teléfono <span className='text-danger'>*</span>
                  </p>
                  <NumberFormat
                    className='register-inputs'
                    name='phone'
                    placeholder='Escribe...'
                    onChange={this.onChangeForm}
                    decimalScale={0}
                    value={this.state.addSite.phone}
                  />
                </div>
                <div className='flex-1'>
                  <p className={`label-inputs ${tableStyles.crudModalLabel}`}>
                    Dirección <span className='text-danger'>*</span>
                  </p>
                  <input
                    className='register-inputs'
                    type='text'
                    name='address'
                    placeholder='Escribe...'
                    onChange={this.onChangeForm}
                    value={this.state.addSite.address}
                  />
                </div>
              </div>

              <div className='row flex-around'>
                <div className='flex-1'>
                  <p className={`label-inputs ${tableStyles.crudModalLabel}`}>
                    Departamento <span className='text-danger'>*</span>
                  </p>
                  {this.renderProvinces()}
                </div>
                <div className='flex-1'>
                  <p className={`label-inputs ${tableStyles.crudModalLabel}`}>
                    Ciudad <span className='text-danger'>*</span>
                  </p>
                  {this.renderCities()}
                </div>

                <div className='flex-1'>
                  <p className={`label-inputs ${tableStyles.crudModalLabel}`}>
                    Resoluciones de facturación<span className='text-danger'>*</span>
                  </p>
                  {this.renderResolutions()}
                </div>
                <div className='flex-1 mt-2'>
                  <Text
                    title='Agregar sede cercana'
                    classNameText='label-inputs text-secondary'
                    iconPath={addCheck}
                    iconAlt='agregar sede'
                    handleClick={this.addSede}
                  />
                  {this.renderNearbyLocations()}
                  <div className='d-flex mt-3 align-items-center'>
                    <input
                      type='checkbox'
                      name='is_virtual'
                      className='border border-dark-blue form-check-input p1 check-dark-blue'
                      onClick={this.onChangeForm}
                      value={this.state.addSite.is_virtual}
                      checked={this.state.addSite.is_virtual}
                    />
                    <label htmlFor='handleTelemedicine' className={`${tableStyles.crudModalLabel}`}>
                      Habilitado Telemedicina
                    </label>
                  </div>
                </div>
              </div>
            </ModalNew>
            {this.myPermission()?.read && (
              <GenericTableNew
                fontFamilyTable={'fontFamilyTable'}
                dark={true}
                headers={renderHeaders}
              >
                {renderSites}{' '}
              </GenericTableNew>
            )}

            {this.myPermission()?.read && (
              <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}>
                  Pag. {this.props.totalSites ? this.state.filters.page : ''}
                  {' de '}
                  {Math.ceil(this.props.totalSites / this.state.filters.perpage)
                    ? Math.ceil(this.props.totalSites / this.state.filters.perpage)
                    : ''}{' '}
                  ({this.props.totalSites} encontrados)
                </p>
                <Pagination
                  activePage={this.state.filters.page}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.totalSites}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentAccount: state.loginReducer.currentAccount,
  isAuth: state.loginReducer.Authenticated,
  sites: state.siteReducer.sites,
  sitesSelect: state.siteReducer.sitesSelect,
  distancesSelect: state.siteReducer.distancesSelect,
  totalSites: state.siteReducer.totalSites,
  createSucces: state.siteReducer.createSucces,
  cuentas: state.cuentasReducer.cuentas,
  cities: state.locationReducer.cities,
  provinces: state.locationReducer.provinces,
  loading: state.siteReducer.site_loading,
  changeStatusLoading: state.siteReducer.loading,
  resolutions: state.siteReducer.resolutions,
});

const mapDispatchToProps = dispatch => ({
  getSites: async filters => {
    await dispatch(getSites(filters));
  },
  getSitesSelect: async filters => {
    await dispatch(getSitesSelect(filters));
  },

  getListDistances: async filters => {
    await dispatch(getListDistances(filters));
  },
  createSite: (objSite, filters, objValidate, doAfter) => {
    dispatch(createSite(objSite, filters, objValidate, doAfter));
  },
  updateSite: (objSite, filters, siteArray, doAfter) => {
    dispatch(updateSite(objSite, filters, siteArray, doAfter));
  },
  changeStatus: (objSite, filters) => {
    dispatch(changeStatus(objSite, filters));
  },
  accounts_get: async filters => {
    dispatch(accounts_get(filters));
  },
  city_get: async filters => {
    dispatch(city_get(filters));
  },
  province_get: async filters => {
    dispatch(province_get(filters));
  },
  getResolution: async filters => {
    dispatch(getResolution(filters));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableSites));
