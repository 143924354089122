import { getEnvs } from "./getEnvs";

const APP_LANGUAGE = getEnvs().APP_LANGUAGE ?? 'es-CO'
const ENV_CURRENCY = getEnvs().CURRENCY ?? 'COP'

export const formatNumberCurrency = (numberToFormat = 0, currency = "") => {
    const numberFormatter = new Intl.NumberFormat(APP_LANGUAGE, {
        style: 'currency',
        currency: currency || ENV_CURRENCY,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    return numberFormatter.format(numberToFormat)
}

export const unFormatNumber = (formattedNumber = '') => {
    const { groupSeparator, decimalSeparator } = getLocaleSeparators()

    const normalizedNumber = formattedNumber
        .replace(new RegExp('\\' + groupSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator, 'g'), '.')

    return parseFloat(normalizedNumber)
}

export const getLocaleSeparators = (locale = APP_LANGUAGE) => {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const groupSeparator = parts.find(part => part.type === 'group').value;
    const decimalSeparator = parts.find(part => part.type === 'decimal').value;

    return { groupSeparator, decimalSeparator };
}