import moment from 'moment';
import { getEnvs } from '../helpers';

export const getParamsObject = url => {
  let params = new URLSearchParams(url);

  let paramsObj = {};
  params.forEach((value, key) => {
    paramsObj[key] = value;
  });

  return paramsObj;
};

export const hasData = variable => {
  let isValid = false;

  if (typeof variable === 'boolean') {
    isValid = !!variable;
  }

  if (typeof variable === 'string') {
    isValid = variable.trim() !== '';
  }

  if (typeof variable === 'number') {
    isValid = variable > 0;
  }

  if (typeof variable === 'undefined') {
    isValid = false;
  }

  if (typeof variable === 'object') {
    isValid = Object.keys(variable || {}).length > 0;
  }

  if (variable === null) {
    isValid = false;
  }

  return isValid;
};

export const decode = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const isTokenExpired = expTimestamp => {
  const token = decode(expTimestamp).exp;
  return moment().isAfter(moment.unix(token));
};

const { URL_BASE, URL_PROTOCOL } = getEnvs();
export const urlRedirect = `${URL_PROTOCOL}${URL_BASE}`;
