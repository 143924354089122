// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';

import Check from '../../assets/img/icons/extraBtnCheck.svg';
import Reject from '../../assets/img/icons/extraBtnRedX.svg';
import Lupa from '../../assets/img/icons/lupaTransparent.svg';

import {
  createRepayment,
  getDetailExpense,
  getListPettyCash,
  getRepayment,
} from '../../actions/accountingActions';

import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { Title } from '../../shared';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import ModalNew from '../Layouts/ModalNew';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import Styles from './pettyCashStyles.module.scss';
import { generateId, getEnvs } from '../../helpers';
import { formatNumberCurrency, getLocaleSeparators } from '../../helpers/numberFormatting';

const localeSeparators = getLocaleSeparators();
const { CURRENCY } = getEnvs();

function Repayment() {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector(state => state);

  const [trigger, setTrigger] = useState(1);
  const [triggerModal, setTriggerModal] = useState(1);
  const [triggerClean, setTriggerClean] = useState(1);
  const [showModal, setShowModal] = useState({
    show: false,
    id: '',
  });
  const [showModalRejected, setShowModalRejected] = useState({
    show: false,
    justify: '',
  });
  const [creatRepayment, setcreatRepayment] = useState({
    pettyCash: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  //-------------- myPermission  😄   --------------
  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'Repayment',
    );
  //--------------first functions 🍕   --------------

  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (creatRepayment.pettyCash !== '') {
      dispatch(
        getRepayment({
          eaccount: storage.loginReducer.currentAccount.id,
          pettyCash: creatRepayment.pettyCash,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    dispatch(
      getListPettyCash({ eaccount: storage.loginReducer.currentAccount.id, status: 'enabled' }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showModal.id !== '') {
      dispatch(
        getDetailExpense({ eaccount: storage.loginReducer.currentAccount.id, id: showModal.id }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerModal]);

  const header = [
    <div key={generateId()} className={`col-3`}>
      Fecha
    </div>,
    <div key={generateId()} className={`col-5 text-start`}>
      Detalle
    </div>,
    <div key={generateId()} className={`col-3 text-end`}>
      Valor
    </div>,
    <div key={generateId()} className={`col-1`}>
      &nbsp;
    </div>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingReducer.listExpenseForRepayment?.expenses)) {
      storage.accountingReducer.listExpenseForRepayment.expenses.forEach(item => {
        tempList.push(
          <section2 className={`d-flex`}>
            <div className={`col-3`}>
              {item.date?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </div>
            <div className={`col-5 text-start`}>{item.description}</div>
            <div className={`col-3 text-end`}>
              {item.amount ? formatNumberCurrency(item.amount) : ''}
            </div>
            <div className={`col-1`}>
              <img
                className={`w-40 ${IndividualStyles.pointer}`}
                style={{ width: '16px', height: '16px' }}
                onClick={() => {
                  setShowModal({ show: true, edit: false, id: item.id });
                  setTriggerModal(triggerModal + 1);
                }}
                src={Lupa}
                alt=''
              />
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  let optionsPettyCash = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.accountingReducer.listPettyCash)) {
    storage.accountingReducer.listPettyCash.forEach((item, index) => {
      optionsPettyCash.push({
        value: item.id,
        label: item.niifAccountNbr + ' - ' + item.name,
        key: index + 'pettyC',
      });
    });
  }

  //--------------  Actions  🍕   --------------
  const approvedRepayment = () => {
    customSwaltAlert({
      icon: 'warning',
      title: `¿Está seguro?`,
      text: 'Verifique la información',
    }).then(response => {
      if (response.isConfirmed) {
        dispatch(
          createRepayment(
            {
              eaccount: storage.loginReducer.currentAccount.id,
              pettyCash: creatRepayment.pettyCash,
              status: 'approved',
              user: storage.loginReducer.user_data.id,
            },
            () => {
              setcreatRepayment({
                pettyCash: '',
                eaccount: storage.loginReducer.currentAccount.id,
              });
              getListPettyCash({ eaccount: storage.loginReducer.currentAccount.id });
              setTriggerClean(triggerClean + 1);
            },
          ),
        );
      }
    });
  };

  const rejectedRepayment = () => {
    customSwaltAlert({
      icon: 'warning',
      title: `¿Está seguro?`,
      text: 'Verifique la información',
    }).then(response => {
      if (response.isConfirmed) {
        setShowModalRejected({ show: true });
      }
    });
  };

  const sendrejectedRepayment = () => {
    dispatch(
      createRepayment(
        {
          eaccount: storage.loginReducer.currentAccount.id,
          pettyCash: creatRepayment.pettyCash,
          status: 'rejected',
          user: storage.loginReducer.user_data.id,
          rejected: showModalRejected.justify,
        },
        () => {
          setcreatRepayment({
            pettyCash: '',
            eaccount: storage.loginReducer.currentAccount.id,
          });
          setShowModalRejected({ show: false, justify: '' });
          getListPettyCash({ eaccount: storage.loginReducer.currentAccount.id });
          setTriggerClean(triggerClean + 1);
        },
      ),
    );
  };

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {!!storage.accountingReducer.listExpenseForRepayment_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        {storage.accountingReducer.repayment_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={tableStyles.title}>
          <Title
            title='Reembolsos'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>

        <Row>
          <Col xs={5}>
            <div className='display-grid mt-1'>
              <label className={tableStyles.crudModalLabel}>Caja menor</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder='Seleccionar...'
                key={'pettyCash' + triggerClean}
                options={optionsPettyCash}
                styles={customSelectNewDark}
                onChange={e => {
                  setcreatRepayment({
                    ...creatRepayment,
                    pettyCash: e.value,
                  });
                  setTrigger(trigger + 1);
                }}
              />
            </div>
          </Col>
        </Row>

        <GenericTableScroll
          headers={header}
          dark={true}
          body={renderList()}
          typeHead={'2'}
        ></GenericTableScroll>

        <Row>
          <Col xs={8}></Col>
          <Col xs={2}>
            <div className='display-grid mt-1'>
              <label className={tableStyles.crudModalLabel}>Fondo</label>
              <NumberFormat
                key={'fondo' + triggerClean}
                allowNegative={false}
                style={{
                  padding: 0,
                  color: '#58595B',
                  background: '#F5F7FA',
                  textAlign: 'end',
                  paddingRight: '4px',
                }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                suffix={` ${CURRENCY}`}
                decimalSeparator={localeSeparators.decimalSeparator}
                thousandSeparator={localeSeparators.groupSeparator}
                decimalScale={2}
                fixedDecimalScale
                value={storage.accountingReducer.listExpenseForRepayment?.totalAmout}
              />
            </div>
          </Col>

          <Col xs={2}>
            <div className='display-grid mt-1'>
              <label className={tableStyles.crudModalLabel}>Total gastos</label>
              <NumberFormat
                key={'amountTotal' + triggerClean}
                allowNegative={false}
                style={{
                  padding: 0,
                  color: '#58595B',
                  background: '#F5F7FA',
                  textAlign: 'end',
                  paddingRight: '4px',
                }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                suffix={` ${CURRENCY}`}
                decimalSeparator={localeSeparators.decimalSeparator}
                thousandSeparator={localeSeparators.groupSeparator}
                decimalScale={2}
                fixedDecimalScale
                value={storage.accountingReducer.listExpenseForRepayment?.totalRepayment}
              />
            </div>
          </Col>
        </Row>

        <div>
          <ModalNew
            show={showModal.show}
            onHide={() => {
              setShowModal({ show: false });
            }}
            title={'Detalle de reembolso'}
            btnYesEvent={null}
            btnNoEvent={() => {
              setShowModal({ show: false });
            }}
            btnNoName={'Aceptar'}
            size={'500'}
          >
            <Row className='d-flex'>
              <Col xs={6}>
                <label className={tableStyles.crudModalLabel}>Fecha reembolso</label>
                <input
                  type='date'
                  name='activity name'
                  value={storage.accountingReducer.detailExpense?.date}
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={!showModal.edit}
                />
              </Col>
            </Row>
            <Row className='d-flex'>
              <Col xs={12}>
                <label className={tableStyles.crudModalLabel}>Detalle</label>
                <input
                  type='text'
                  name='activity name'
                  value={storage.accountingReducer.detailExpense?.description}
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={!showModal.edit}
                />
              </Col>
            </Row>
            <Row className='d-flex'>
              <Col xs={12}>
                <label className={tableStyles.crudModalLabel}>Tipo de gasto</label>
                <input
                  type='text'
                  name='activity name'
                  value={storage.accountingReducer.detailExpense?.expTypeName}
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={!showModal.edit}
                />
              </Col>
            </Row>
            <Row className='d-flex'>
              <Col xs={12}>
                <label className={tableStyles.crudModalLabel}>Tercero</label>
                <input
                  type='text'
                  name='activity name'
                  value={storage.accountingReducer.detailExpense?.thirdPartyName}
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={!showModal.edit}
                />
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={12}>
                <label className={tableStyles.crudModalLabel}>Centro de costo</label>
                <input
                  type='text'
                  name='activity name'
                  value={storage.accountingReducer.detailExpense?.ccName}
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={!showModal.edit}
                />
              </Col>
            </Row>

            <Row className='d-flex' style={{ marginTop: '15px' }}>
              <Col xs={4}>
                <label className={tableStyles.crudModalLabel}>Registrado por</label>
                <input
                  type='text'
                  name='registerBy'
                  value={storage.accountingReducer.detailExpense?.creatorName}
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={true}
                />
              </Col>
              <Col xs={4}>
                <label className={tableStyles.crudModalLabel}>Fecha</label>
                <input
                  type='text'
                  name='date2'
                  value={storage.accountingReducer.detailExpense?.createdDate?.replace(
                    /^(\d{4})-(\d{2})-(\d{2})$/g,
                    '$3/$2/$1',
                  )}
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={true}
                />
              </Col>
              <Col xs={4}>
                <label className={tableStyles.crudModalLabel}>Hora</label>
                <input
                  type='text'
                  name='hour'
                  value={storage.accountingReducer.detailExpense?.createdTime}
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={true}
                />
              </Col>
            </Row>
          </ModalNew>
          {creatRepayment.pettyCash !== '' ? (
            <div className={IndividualStyles.bottomRow} style={{ paddingRight: '0px' }}>
              <Button
                className={IndividualStyles.btnExtra}
                onClick={() => approvedRepayment()}
                disabled={
                  !!(creatRepayment.pettyCash === '') ||
                  !myPermission()?.approve ||
                  !!storage.accountingReducer.listExpenseForRepayment?.expenses?.length <= 0
                    ? true
                    : false
                }
              >
                <img src={Check} alt='my images' /> <span></span> Aprobar
              </Button>

              <Button
                className={IndividualStyles.btnExtra}
                onClick={() => rejectedRepayment()}
                disabled={
                  !!(creatRepayment.pettyCash === '') ||
                  !myPermission()?.reject ||
                  !!storage.accountingReducer.listExpenseForRepayment?.expenses?.length <= 0
                    ? true
                    : false
                }
              >
                <img src={Reject} alt='' /> <span></span>Denegar
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>

        <div>
          {/* MODAL JUSTIFICACIÓN DE DENEGACIÓN*/}
          <ModalNew
            title='Reembolso rechazado'
            show={showModalRejected.show}
            btnYesName={'Guardar'}
            btnNoName={'Cancelar'}
            size='400'
            btnYesEvent={() => {
              sendrejectedRepayment();
              setShowModalRejected({ ...showModalRejected, show: true });
            }}
            btnYesDisabled={!!(showModalRejected.justify === '')}
            onHide={() => setShowModalRejected({ show: false })}
            btnNoEvent={() => setShowModalRejected({ show: false })}
          >
            <textarea
              className={`${Styles.inputsTextArea}`}
              name='justification'
              type='text'
              value={showModalRejected.justify}
              onChange={e =>
                setShowModalRejected({ ...showModalRejected, justify: e.target.value })
              }
            ></textarea>
          </ModalNew>
        </div>
      </div>
    </>
  );
}
export default Repayment;
