import Popup from 'reactjs-popup';
import Gear from '../../assets/img/icons/engraneGris.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import styles from './customPopup.module.scss';

function CustomPopupExtend({ noHover = true, ...props}) {
  const hidePopupExtend = () => {
    const ref = document?.querySelector('#popup-root');
    ref?.remove();
  };

  return (
    <Popup
      trigger={
        <span
          style={props?.center ? { cursor: 'pointer', textAlign: 'center' } : { cursor: 'pointer' }}
          className={
            props.triggerDisabled
              ? styles.disabled
              : noHover
                ? ''
                : `${tableStyles.colorNewOrderHover}`
          }
          disabled={!!props.triggerDisabled}
        >
          <img
            src={props.triggerSrc ? props.triggerSrc : Gear}
            alt='configuracion'
            className={`icons-gear ${props.triggerClass}`}
            height='20px'
          />
        </span>
      }
      position={props.position ? props.position : 'left top'}
      contentStyle={{ zIndex: 100 }}
      styles={styles.popupContent}
    >
      <ul className={`${styles.popUpUl}  hoverPointer`}>
        {props.showDetails && (
          <li
            onClick={() => {
              props.showDetailsEvent();
              hidePopupExtend();
            }}
            className={styles.popUpShowDetails}
          >
            {props.textDetails ? props.textDetails : 'Ver detalle'}
          </li>
        )}
        {props.showEnable ? (
          <li
            onClick={() => {
              props.enableClickEvent();
              hidePopupExtend();
            }}
            className={props.isEnabled ? styles.popUpLiActiveRed : styles.popUpLiActive}
          >
            {props.isEnabled
              ? props.enableText
                ? props.enableText
                : 'Deshabilitar'
              : props.disabledText
                ? props.disabledText
                : 'Habilitar'}
          </li>
        ) : null}

        {props.showEdit ? (
          <li
            onClick={() => {
              hidePopupExtend();
              props.editClickEvent();
            }}
            className={props.iconUpdate ? styles.popUpLiUpdate : styles.popUpLiEdit}
          >
            {props.editText ? props.editText : 'Editar'}
          </li>
        ) : null}

        {props.showPrint && (
          <li
            className={styles.popUpLiUndo}
            onClick={() => {
              props.printEvent();
              hidePopupExtend();
            }}
          >
            {props.printText ? props.printText : 'Imprimir'}
          </li>
        )}

        {props.showDelete && (
          <li
            className={styles?.popUpLiDelete}
            onClick={() => {
              props?.deleteEvent();
              hidePopupExtend();
            }}
          >
            {props?.deleteText ? props?.deleteText : 'Eliminar'}
          </li>
        )}

        {props.showAddDataSheet && (
          <li
            className={styles.popUpLiDataSheet}
            onClick={() => {
              props.addDataSheetEvent();
              hidePopupExtend();
            }}
          >
            {props.addDataSheetText ? props.addDataSheetText : 'Ficha técnica'}
          </li>
        )}

        {!!props.checkEvent && (
          <li
            className={styles.popUpLiCheck}
            onClick={() => {
              props.checkEvent();
              hidePopupExtend();
            }}
          >
            {props.checkText ? props.checkText : 'Elegir Todo'}
          </li>
        )}

        {!!props.purchaseEvent && (
          <li
            className={styles.popUpLiPurchase}
            onClick={() => {
              props.purchaseEvent();
              hidePopupExtend();
            }}
          >
            {props.purchaseText ? props.purchaseText : 'Ver OC generada'}
          </li>
        )}

        {!!props.dealEvent && (
          <li
            className={styles.popUpLiDeal}
            onClick={() => {
              props.dealEvent();
              hidePopupExtend();
            }}
          >
            {props.dealText ? props.dealText : 'Convenio'}
          </li>
        )}

        {props.showUndo && (
          <li
            className={styles.popUpLiUndo}
            onClick={() => {
              props.undoEvent();
              hidePopupExtend();
            }}
          >
            {props.undoText ? props.undoText : 'Deshacer'}
          </li>
        )}

        {props.showSing && (
          <li
            className={styles.popUpSign}
            onClick={() => {
              props.signEvent();
              hidePopupExtend();
            }}
          >
            {props.signText ? props.signText : 'Firmar'}
          </li>
        )}

        {props.showRadFis && (
          <li
            className={styles.showIRadDig}
            onClick={() => {
              props.radFisEvent();
              hidePopupExtend();
            }}
          >
            {props.undoText ? props.undoText : 'Rad. Fisica'}
          </li>
        )}
        {props.showRadDig && (
          <li
            className={styles.showIRadDig}
            onClick={() => {
              props.radDigEvent();
              hidePopupExtend();
            }}
          >
            {props.undoText ? props.undoText : 'Rad. Digital'}
          </li>
        )}

        {props.showClone && (
          <li
            className={styles.popUpClone}
            onClick={() => {
              props.cloneEvent();
              hidePopupExtend();
            }}
          >
            {props.cloneText ? props.cloneText : 'CLonar'}
          </li>
        )}
        {props.showRegister && (
          <li
            className={styles.popUpRegister}
            onClick={() => {
              props.registerEvent();
              hidePopupExtend();
            }}
          >
            {props.registerText ? props.registerText : 'Registrar'}
          </li>
        )}
        {props.showSign && (
          <li
            className={styles.popUpLiSign}
            onClick={() => {
              props.signEvent();
              hidePopupExtend();
            }}
          >
            {props.signText ? props.signText : 'Firmar'}
          </li>
        )}
        {props.showOtroSi && (
          <li
            className={styles.popUpLiOtroSi}
            onClick={() => {
              props.otroSiEvent();
              hidePopupExtend();
            }}
          >
            {props.otroSi ? props.otroSi : 'Agregar otrosí'}
          </li>
        )}
        {props.showEmployeeRe && (
          <li
            className={styles.popUpLiEmployeeRe}
            onClick={() => {
              props.employeeReEvent();
              hidePopupExtend();
            }}
          >
            {props.employeeRe ? props.employeeRe : 'Sustitución patronal'}
          </li>
        )}
        {props.sendToAccount && (
          <li
            className={`${styles.sendToAccountTextStyles} ${tableStyles.f13}`}
            onClick={() => {
              props.sendToAccountTextEvent();
              hidePopupExtend();
            }}
          >
            {props.sendToAccountText ?? 'Agregar otrosí'}
          </li>
        )}

        {props?.extraButtons &&
          props?.extraButtons?.map((button, index) => {
            return button?.class
              ? button?.event && (
                <li
                  key={`btn-popUp-${index}`}
                  className={button?.class}
                  onClick={() => button?.event()}
                >
                  {button?.icon && <img src={button?.icon} alt={button?.text} />}
                  {button?.text}
                </li>
              )
              : button?.event && (
                <li
                  key={`btn-popUp-${index}`}
                  className={styles.popUpGeneric}
                  onClick={async () => {
                    await button?.event();
                    hidePopupExtend();
                  }}
                >
                  <img src={button?.icon} alt={button?.text} />
                  {button?.text}
                </li>
              );
          })}
      </ul>
    </Popup>
  );
}

export default CustomPopupExtend;
