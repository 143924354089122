import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  getProvidersList,
  getProvidersNotes,
  listBIllsAndVal,
  postRegisterNote,
} from '../../actions/notesAction';
import simpleRightArrow from '../../assets/img/icons/simpleRightArrow.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import {
  convertMoneyFormat,
  formatToRcSelect,
  isEmptyOrUndefined,
  message,
} from '../../helpers/helpers';
import { getLocaleSeparators } from '../../helpers/numberFormatting';
import GenericTableNew from '../Layouts/GenericTableNew';
import { customSelectNew } from '../Layouts/react-select-custom';

import deepcopy from 'deepcopy';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';

const localeSeparators = getLocaleSeparators();

export const NoteRecords = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const idAccount = store.loginReducer.user_data.id;
  const arrNoteList = store.notesReducer?.noteTypesList;
  const arrProvidersList = store.notesReducer?.providersList;
  let arrBillsAndValues = store.notesReducer?.billsAndValues;
  const [selectedNote, setSelectedNote] = useState('');
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [dataUserInput, setDataUserInput] = useState([]);
  const [detail, setDetail] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState('');
  const filtersProvList = {
    id_account: idEnterprise,
    eaccount: store.loginReducer.currentAccount.id,
    status: 'enabled',
    category: 1,
  };
  const noteTypesFormatted = formatToRcSelect(arrNoteList, 'id', 'name', 'nature');
  const providersFormatted = formatToRcSelect(arrProvidersList, 'id', 'name');
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  today = yyyy + '-' + mm + '-' + dd;
  useEffect(() => {
    dispatch(getProvidersNotes(filtersProvList));
    dispatch(getProvidersList(filtersProvList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let i = 0;

  const listElem = () => {
    let elemMap;
    if (Array.isArray(arrBillsAndValues)) {
      const elem2 = arrBillsAndValues;
      if (elem2.length > 0) {
        elemMap = elem2.map((elem, i) => {
          return renderElement(elem, i);
        });
      } else {
        elemMap = (
          <tr>
            <td className='col-md-2 text-center'>ㅤ</td>
            <td className='col-md-8 text-center'>No hay registros</td>
            <td className='col-md-2 text-center'>ㅤ</td>
          </tr>
        );
      }
    }
    return elemMap;
  };

  const renderElement = (elem, i) => {
    return (
      <tr key={elem.code}>
        <td className='col-md-2 text-center'>
          <input id='check' onChange={e => handleClickToCheck(e, elem, i)} type='checkbox'></input>
        </td>
        <td className='col-md-8 text-center'>{elem.bill_number}</td>
        <td className='col-md-2 text-end px.2'>{convertMoneyFormat(elem?.total_bill)}</td>
      </tr>
    );
  };
  const registerNote = async () => {
    if (isEmptyOrUndefined(selectedNote)) {
      return message(
        'warning',
        'Intenta de nuevo',
        'El campo notas de proveedor es obligatorio',
        undefined,
        true,
      );
    }
    if (isEmptyOrUndefined(detail)) {
      return message(
        'warning',
        'Intenta de nuevo',
        'El campo detalles es obligatorio',
        undefined,
        true,
      );
    }
    if (isEmptyOrUndefined(detail)) {
      return message(
        'warning',
        'Intenta de nuevo',
        'El campo fecha es obligatorio',
        undefined,
        true,
      );
    }

    try {
      setLoading(true);
      const result = await postRegisterNote(
        Number(selectedNote.value),
        selectedProvider,
        detail,
        date,
        idEnterprise,
        idAccount,
        selectedNote.extra?.toLowerCase(),
        dataUserInput,
        token,
      );
      if (result.success) {
        setLoading(false);
        setSelectedNote('');
        setSelectedProvider('');
        setDetail('');
        setDate('');
        setSelectedObjects([]);
        dispatch(listBIllsAndVal());
        return message('success', 'Éxito', 'Nota registrada exitosamente', undefined, true);
      } else {
        setLoading(false);
        return message('error', 'Error', result.message, undefined, true);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error', error.message);
    }
  };

  const renderHeaders = [
    <th className='col-md-1 text-center' style={{ borderTopLeftRadius: '10px' }} key={i}>
      &nbsp;
    </th>,

    <th className='col-md-7 text-center pb-8' key={i + 1}>
      No. Factura
    </th>,
    <th className='col-md-4 text-end px-2' style={{ borderTopRightRadius: '10px' }} key={i + 1}>
      Valor
    </th>,
  ];

  /* -------------------------------------------------------------------------- */
  /*                           Handle changes section                           */
  /* -------------------------------------------------------------------------- */

  const handleProviders = e => {
    if (selectedObjects.length > 0) {
      document.getElementById('check').checked = false;
    }
    setSelectedObjects([]);
    setSelectedProvider(e.value);
    const filts = { account: idEnterprise, provider: e.value };
    dispatch(listBIllsAndVal(filts));
  };

  const handleClickToCheck = (e, elem, i) => {
    if (e.target?.checked) {
      const obj = {
        rnd_bill_number: elem.bill_number,
        rnd_total_value: elem.total_bill,
        rnd_value_note: 0,
        total: 0,
      };
      if (Array.isArray(dataUserInput)) {
        setDataUserInput([...dataUserInput, obj]);
      }
    }
    if (!e?.target?.checked) {
      if (Array.isArray(dataUserInput)) {
        dataUserInput.splice(i, 1);
      }
    }

    changeBoxSelected(elem);
  };

  const changeBoxSelected = item => {
    let tempSelected = selectedObjects;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.warehouse_id === item.warehouse_id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.warehouse_id !== item.warehouse_id);
      } else {
        let newChange = {
          ...item,
        };
        tempSelected.push(newChange);
      }
      setSelectedObjects(tempSelected);
    }
  };

  const handleChangeInput = (e, i) => {
    const { name, value } = e;
    let newArray = deepcopy(dataUserInput);
    newArray[i][name] = value;
    newArray[i].rnd_total_value = value;
    setDataUserInput(newArray);
  };

  const handleCancel = () => {
    arrBillsAndValues = [];
    dispatch(listBIllsAndVal());
    setSelectedObjects({});
    setSelectedNote('');
    setSelectedProvider({ value: '', label: '-Seleccione-' });
    setDetail('');
    setDate('');
    setDataUserInput([]);
  };
  return (
    <>
      {store.notesReducer?.loading ||
        (loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003F80' height={100} width={100} />
          </div>
        ))}

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Registro de notas</h1>

          <Row className='d-flex'>
            <Col xs={6}>
              <p className={tableStyles.crudModalLabel}>Notas de proveedores</p>
              <div className='d-flex'>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={noteTypesFormatted.find(obj => obj.value === selectedNote)}
                  onChange={e => setSelectedNote(e)}
                  options={noteTypesFormatted}
                  className={'w-100'}
                  placeholder='Seleccione...'
                  styles={customSelectNew}
                />
              </div>
            </Col>

            <Col xs={6}>
              <p className={tableStyles.crudModalLabel}>Proveedores</p>
              <div className='d-flex'>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={providersFormatted.find(obj => obj.value === selectedProvider)}
                  options={providersFormatted}
                  onChange={e => handleProviders(e)}
                  className={'w-100'}
                  placeholder='Seleccione...'
                  styles={customSelectNew}
                  // isDisabled={selectedNote === "" ? true : false}
                />
              </div>
            </Col>
          </Row>
          <Row className='d-flex'>
            <Col xs={10}>
              <p className={tableStyles.crudModalLabel}>Detalle</p>
              <div className='d-flex'>
                <textarea
                  onChange={e => setDetail(e.target.value)}
                  className={`register-inputs`}
                  name='requisition_date'
                  type='text'
                  value={detail}
                  placeholder='Escribir...'
                />
              </div>
            </Col>

            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Fecha</p>
              <div className='d-flex'>
                <input
                  onChange={e => setDate(e.target.value)}
                  className={`register-inputs`}
                  name='requisition_date'
                  max={today}
                  type='date'
                />
              </div>
            </Col>
          </Row>

          <GenericTableNew dark={true} headers={renderHeaders}>
            {listElem()}
          </GenericTableNew>

          {selectedObjects.length > 0 &&
            selectedObjects.map((e, i) => {
              let total = 0;
              if (dataUserInput.length > 0) {
                dataUserInput[i].rnd_total_value = Number(e.total_bill);

                if (selectedNote.extra === 'C') {
                  total = Number(e.total_bill) - Number(dataUserInput[i].rnd_value_note);
                } else {
                  total = Number(dataUserInput[i].rnd_value_note) + Number(e.total_bill);
                }
              }
              return (
                <Row className='d-flex' key={i}>
                  <Col xs={2}>
                    <p className={tableStyles.crudModalLabel}>No. factura</p>
                    <div className='d-flex'>
                      <input
                        className={`register-inputs`}
                        name='requisition_date'
                        type='text'
                        value={e.bill_number}
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col xs={3}>
                    <p className={tableStyles.crudModalLabel}>Valor inicial facturas</p>
                    <div className='d-flex'>
                      <input
                        disabled={true}
                        value={convertMoneyFormat(e.total_bill)}
                        className={`register-inputs`}
                        type='text'
                      />
                    </div>
                  </Col>
                  <Col className={`pt-4`} style={{ width: '1%' }} xs={1}>
                    <img src={simpleRightArrow} alt='arrow'></img>
                  </Col>
                  <Col xs={3}>
                    <p className={tableStyles.crudModalLabel}>Valor nota</p>
                    <div className='d-flex'>
                      <NumberFormat
                        id={`valueToFocus${i}`}
                        onValueChange={e => handleChangeInput({ ...e, name: 'rnd_value_note' }, i)}
                        className={`register-inputs`}
                        name='rnd_value_note'
                        allowNegative={false}
                        prefix='$'
                        thousandSeparator={localeSeparators.groupSeparator}
                        decimalSeparator={localeSeparators.decimalSeparator}
                        decimalScale={2}
                      />
                    </div>
                  </Col>
                  <Col xs={3}>
                    <p className={tableStyles.crudModalLabel}>Saldo</p>

                    <div className='d-flex'>
                      <input
                        value={convertMoneyFormat(
                          total,
                          // dataUserInput[i].rnd_total_value
                        )}
                        className={`register-inputs`}
                        name='requisition_date'
                        type='text'
                        disabled={true}
                      />
                    </div>
                  </Col>
                </Row>
              );
            })}
          <div className={`customTabs__Footer mt-5`}>
            <button
              onClick={() => registerNote()}
              className={`${tableStyles.btnPrimary} btn`}
              disabled={selectedObjects.length > 0 ? false : true}
            >
              Guardar
            </button>
            <button onClick={() => handleCancel()} className={`${tableStyles.btnSecondary} btn`}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
