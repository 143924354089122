import { Checkbox, TextField } from '@material-ui/core';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { RenderMedicalFormulasPdf } from '../../AsistanceModule/RenderMedicalFormulasPdf';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import poundSign from '../../assets/img/icons/CentrodeCosto.svg';
import estetoscopic from '../../assets/img/icons/estetoscopic.svg';
import estetoscopicBlue from '../../assets/img/icons/estetoscopicBlue.svg';
import focaEye from '../../assets/img/icons/focaEye.svg';
import focaEyeBlue from '../../assets/img/icons/focaEyeBlue.svg';
import miniRoundWhiteCheck from '../../assets/img/icons/miniRoundWhiteCheck.svg';
import ordBackArrow from '../../assets/img/icons/ordBackArrow.svg';
// import ordOrangeDownloadArrow from '../../assets/img/icons/ordOrangeDownloadArrow.svg';
import ordSugess from '../../assets/img/icons/ordSugess.svg';
import pillDarkBlue from '../../assets/img/icons/pillDarkBlue.svg';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import {
    formatToRcSelect,
    generateId,
    getPermission,
    isEmptyOrUndefined,
    loader,
    message,
    swalConfirm,
    // validateEmptyString,
} from '../../helpers/helpers';
import FullScreenDialog from '../OrderingComponents/FullScreenDialog';
import { OrdCustomToast } from '../OrderingComponents/OrdCustomToast';
import { OrdGenericTemplate } from '../OrderingComponents/OrdGenericTemplate';
import OrdTable from '../OrderingComponents/OrdTable';
import TextConfigModal from '../OrderingComponents/TextConfig';
import OrdModal from '../OrderingComponents/OrdModal';

export const ServiceCombosTab = event => {
    const store = useSelector(state => state);
    const listPermission = store.loginReducer.currentAccount?.profile?.permission;
    const myPermission = getPermission({ prefix: 'order', listPermission });
    const token = store.loginReducer.Authorization;
    const idEnterprise = store.loginReducer.currentAccount.id;
    const [consultTmpArr, setConsultTmpArr] = useState([]);
    const [changeView, setChangeView] = useState({
        generalView: true,
        diagnostics: false,
        procedure: false,
    });
    const [dataOut, setDataOut] = useState({
        reason: '',
        consultGeneralObservations: '',
        procedureGeneralObservations: '',
        consultService: '',
        eye: '',
        orderType: '',
        prequisurgicalExams: []
    });
    const [justification, setJustification] = useState({
        showModal: false,
    });
    const [fullDialogScreen, setFullDialogScreen] = useState({
        show: false,
        id: '',
    });

    const [noInsTextModal, setNoInsTextModal] = useState({
        isOpen: false,
        current: null,
        isLoading: false,
        field: ''
    });

    const handleOpenNoInsTextModal = (name, field = '') => setNoInsTextModal({
        current: name, isOpen: true, field: field
    });

    const handleCloseNoInsTextModal = () => setNoInsTextModal({
        current: null, isOpen: false, field: ''
    });

    const handleAttatchText = (text) => {
        if (noInsTextModal.field) {
            const value = {
                ...dataOut,
                [noInsTextModal.field]: dataOut?.[noInsTextModal.field]?.length
                    ? `${dataOut?.[noInsTextModal?.field]}\n${text}`
                    : text
            };
            setDataOut(value);
            handleCloseNoInsTextModal();
        }
    };

    const [preQuiIds, setPreQuiIds] = useState([]);
    const [reqItems, setReqItems] = useState(0);

    const MySwal = withReactContent(Swal);
    /* ----------------------------- FETCHS SECTION ----------------------------- */
    /* ----------------------------- GET ----------------------------- */

    const {
        results: prequisurgicalExams,
        load: prequisurgicalExamsLoader,
        trigger: getPrequisurgicalExams,
    } = useGetMethod();

    const { results: bodyOrgans, load: bodyOrgansLoader, trigger: getbodyOrgans } = useGetMethod();

    const {
        results: orderingReasons,
        load: orderingReasonsLoader,
        trigger: getOrderingReasons,
    } = useGetMethod();

    const {
        results: listCombosService,
        load: loaderListCombosService,
        trigger: getListCombosService
    } = useGetMethod();
    const { results: times, load: timesLoader, trigger: getTimes } = useGetMethod();
    const { results: orderType, load: orderTypeLoader, trigger: getOrderType } = useGetMethod();

    /* ---------------------------------- POST /PUT ---------------------------------- */
    const { load: createServiceOrderLoader, trigger: createServiceOrder } = usePostMethod();
    /* ------------------------------------ - ----------------------------------- */



    useEffect(() => {
        getOrderingReasons({
            url: '/medical/orderingReasons/',
            objFilters: { id_account: idEnterprise },
            token: token,
        });
        getListCombosService({
            url: '/medical/serviceCombos/',
            objFilters: {
                id_account: idEnterprise,
                status: 'enabled',
                type: changeView.procedure ? 'surgery' : 'other',
                orderType: dataOut.orderType?.value
            },
            doAfterExceptionResults: true,
            token: token,
        });


        getOrderType({
            url: '/medical/orderType/',
            token: token,
            doAfterSuccess: () => {
                getPrequisurgicalExams({
                    url: '/medical/presurgicalExams/',
                    token: token,
                });
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, idEnterprise, dataOut.orderType?.value, dataOut.orderType?.value]);

    useEffect(() => {
        getbodyOrgans({
            url: '/medical/bodyOrgans/',
            objFilters: { draId: 1 },
            token: token,
            succesAction: response => {
                const bothEyes = response?.results.find(org => org.name === 'OS');
                if (bothEyes) {
                    const eyeFormatted = {
                        value: bothEyes.id,
                        label: bothEyes.name,
                    };
                    setDataOut(state => ({
                        ...state,
                        eye: eyeFormatted,
                    }));
                    setDataOut(state => ({
                        ...state,
                        eye: eyeFormatted,
                    }));
                }
            },
            doAfterSuccess: () => {
                getTimes({
                    url: '/medical/time/',
                    objFilters: { id_account: idEnterprise },
                    token: token,
                });
            },
        });
    }, [getTimes, getbodyOrgans, idEnterprise, token]);
    const formattedTimes = formatToRcSelect(times?.results, 'id', 'name', 'value');
    const formattedBodyOrgans = formatToRcSelect(bodyOrgans?.results, 'id', 'name', '', '', '');
    const formattedReasons = formatToRcSelect(orderingReasons?.results, 'id', 'name', '', '', '');
    const formattedServiceCombos = formatToRcSelect(listCombosService?.results, 'id', 'name', '', '', '');
    const formattedOrderType = formatToRcSelect(
        orderType?.results,
        'name',
        'name',
        'id',
        '',
        '',
        false,
    );
    const renderOrderTypeTooltip = text => <Tooltip>{text}</Tooltip>;
    const FormatDataTable = () => {
        let tempList = [];
        consultTmpArr.length > 0 &&
            consultTmpArr.forEach((item, index) => {
                let orderType = item.orderType?.value;
                let orangeText = item?.isRequ === true ? tableStyles.ordOrangeText : '';
                tempList.push(
                    <>
                        <tr key={index} className={`hover-table-row `}>
                            <td className={`text-center ${orangeText}`}> &nbsp;{item?.srvId}</td>
                            <td className={`text-start ${orangeText}`}>
                                <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderOrderTypeTooltip(orderType)}
                                >
                                    <span>
                                        <img
                                            alt='sign'
                                            src={
                                                orderType === 'Particular'
                                                    ? poundSign
                                                    : orderType === 'Aseguradora'
                                                        ? pillDarkBlue
                                                        : ordSugess
                                            }
                                            width='15px'
                                        />
                                    </span>
                                </OverlayTrigger>
                                &nbsp;
                                <span>{`${item.srvName}`}</span>
                            </td>
                            <td className={`text-start ${orangeText}`}> {item?.eye?.label}</td>
                            <td className={`text-start ${orangeText}`}>
                                {' '}
                                {`${item?.optimalTimeQtyId}  ${item.optimalTimeName}`}
                            </td>
                            <td className={`text-start ${orangeText}`}>
                                {' '}
                                {`${item?.tWait}  ${item.tmName}`}
                            </td>
                        </tr>
                    </>,
                );
            });
        return tempList;
    };

    const headers = [
        {
            title: 'Código',
            className: 'px-2 text-start col-1',
        },
        {
            title: 'Servicio',
            className: 'text-start col-4',
        },
        {
            title: 'Ojo',
            className: 'text-start col-2',
        },
        {
            title: 'Programación',
            className: 'text-start col-3',
        },
        {
            title: 'Tiempo',
            className: 'text-start',
        },
    ];

    const handleSubmitConsult = () => {
        let newArr = [];
        let numItemsWithJustification = 0;
        if (consultTmpArr.length > 0) {
            consultTmpArr.forEach(item => {
                if (item.justification) {
                    if (item.justification !== '') {
                        numItemsWithJustification = numItemsWithJustification + 1;
                    }
                }
                newArr.push({
                    ordTypeId: item?.orderType?.extra,
                    ordServiceId: item?.srvId,
                    eye: item?.eye?.value,
                    programingQty: item?.optimalTimeQtyId,
                    programingTimeId: item?.optimalTimeId,
                    justification: item?.justification,
                    durationQty: item?.tWait,
                    durationId: item?.tmId,
                });
            });
        } else {
            return message('info', 'Sin información', 'No hay datos para guardar');
        }

        createServiceOrder({
            url: '/medical/serviceOrder/',
            token: token,
            method: 'POST',
            body: {
                clinicalHistoryId: event?.event?.clhId,
                observations: dataOut?.consultGeneralObservations,
                reasonId: dataOut?.reason?.value,
                services: newArr,
                serviceType: 'consulta',
            },
            succesAction: results => {
                if (results?.status === 200) {
                    setDataOut({
                        reason: '',
                        consultGeneralObservations: '',
                        procedureGeneralObservations: '',
                    });
                }
                MySwal.fire({
                    icon: 'success',
                    title: `<span style=${tableStyles.ordClearBlueText}>Orden de servicio creada exitosamente<span>`,
                    text:
                        numItemsWithJustification < consultTmpArr?.length
                            ? ''
                            : `Se ha(n) enviado (${numItemsWithJustification}) solicitud(es) de servicio(s) a dirección médica `,
                    showConfirmButton: true,
                    confirmButtonText:
                        numItemsWithJustification < consultTmpArr?.length ? 'Ver orden' : 'Aceptar',
                }).then(result => {
                    if (result.isConfirmed) {
                        if (numItemsWithJustification < consultTmpArr?.length) {
                            setConsultTmpArr([]);
                            setFullDialogScreen({
                                show: true,
                                id: results.results,
                            });
                        }
                    }
                    setConsultTmpArr([]);
                });
            },
        });
    };

    const handleSubmitProcedure = () => {
        let newArr = [];
        let numItemsWithJustification = 0;
        if (consultTmpArr.length > 0) {
            consultTmpArr.forEach(item => {
                if (item.justification) {
                    if (item.justification !== '') {
                        numItemsWithJustification = numItemsWithJustification + 1;
                    }
                }
                newArr.push({
                    ordTypeId: item?.orderType?.extra,
                    ordServiceId: item?.srvId,
                    eye: item?.eye?.value,
                    programingQty: item?.optimalTimeQtyId,
                    programingTimeId: item?.optimalTimeId,
                    justification: item?.justification,
                    durationQty: item?.tWait,
                    durationId: item?.tmId,
                });
            });
        } else {
            return message('info', 'Sin información', 'No hay datos para guardar');
        }

        createServiceOrder({
            url: '/medical/serviceOrder/',
            token: token,
            method: 'POST',
            body: {
                clinicalHistoryId: event?.event?.clhId,
                observations: dataOut?.procedureGeneralObservations,
                reasonId: dataOut?.reason.value,
                presurgicalExams: preQuiIds,
                services: newArr,
                serviceType: 'cirugia',
            },
            succesAction: results => {
                setPreQuiIds([])
                if (results?.status === 200) {
                    setDataOut({
                        reason: '',
                        consultGeneralObservations: '',
                        procedureGeneralObservations: '',
                    });
                }
                MySwal.fire({
                    icon: 'success',
                    title: `<span style=${tableStyles.ordClearBlueText}>Orden de servicio creada exitosamente<span>`,
                    text:
                        numItemsWithJustification < consultTmpArr?.length
                            ? ''
                            : `Se ha(n) enviado (${numItemsWithJustification}) solicitud(es) de servicio(s) a dirección médica `,
                    showConfirmButton: true,
                    confirmButtonText:
                        numItemsWithJustification < consultTmpArr?.length ? 'Ver orden' : 'Aceptar',
                }).then(result => {
                    if (result.isConfirmed) {
                        if (numItemsWithJustification < consultTmpArr?.length) {
                            setConsultTmpArr([]);
                            setFullDialogScreen({
                                show: true,
                                id: results.results,
                            });
                        }
                    }
                    setConsultTmpArr([]);
                });
            },
        });
    };

    const handleChangePrequirugical = elem => {
        let bag = [...preQuiIds];
        let founded = bag.find(x => x === elem.id);
        if (!isEmptyOrUndefined(founded)) {
            bag = bag.filter(x => x !== elem.id);
        } else {
            bag.push(elem.id);
        }
        setPreQuiIds(bag);
        setDataOut({ ...dataOut, prequisurgicalExams: bag });
    };

    const addConsultJustify = () => {
        if (isEmptyOrUndefined(justification.consultJustification)) {
            return message('info', 'Campo obligatorio', 'Se requiere una justificación');
        }
        const newService = consultTmpArr?.map((service) => {
            const isAuthReqdata = service?.reqAuth ? {
                justification: justification?.consultJustification,
                isRequ: true,
            } : {}
            return { ...service, ...isAuthReqdata }
        })
        setConsultTmpArr(newService);
        setJustification({ showModal: false, consultJustification: '' });
    };

    const handleGoBack = label => {
        const bothEyes = formattedBodyOrgans.find(org => org.label === 'AO');
        if (label === 'procedure') {
            setPreQuiIds([]);
            setChangeView({
                generalView: false,
                diagnostics: false,
                procedure: true,
            });
        } else {
            setChangeView({
                generalView: false,
                diagnostics: true,
                procedure: false,
            });
        }
        setDataOut({
            reason: '',
            consultGeneralObservations: '',
            procedureGeneralObservations: '',
            consultService: '',
            eye: bothEyes,
            orderType: '',
            prequisurgicalExams: []
        });
    };



    useEffect(() => {
        if (consultTmpArr.length) {
            const isAuthReq = consultTmpArr?.some((obj) => obj.reqAuth)
            if (isAuthReq) {
                swalConfirm({
                    title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                    text: `Uno o más servicios no se encuentran dentro del plan de beneficios del paciente`,
                    confirmButtonText: 'Si, continuar',
                    doAfterConfirm: () => setJustification((states) => ({ ...states, showModal: true })),
                    doAfterCancel: () => {
                        setConsultTmpArr([])
                        setDataOut(states => ({ ...states, consultService: '' }))
                    }
                });
            }
        }
    }, [consultTmpArr.length, consultTmpArr,]);

    return (
        <>
            {createServiceOrderLoader && loader}
            {prequisurgicalExamsLoader && loader}
            {bodyOrgansLoader && loader}
            {orderingReasonsLoader && loader}
            {noInsTextModal.isLoading && loader}
            {timesLoader && loader}
            {orderTypeLoader && loader}
            {loaderListCombosService && loader}
            <TextConfigModal
                isOpen={noInsTextModal.isOpen}
                current={noInsTextModal.current}
                onClose={handleCloseNoInsTextModal}
                width={800}
                onSelectItem={(_, item) => handleAttatchText(item)}
                sheet="servicesCombos"
                onLoading={(e) => setNoInsTextModal(state => ({ ...state, isLoading: e }))}
            />
            <OrdModal
                title={'Justificación'}
                show={justification.showModal}
                btnYesName='Aceptar'
                btnNoName='Cancelar'
                size='700'
                btnYesEvent={
                    () => addConsultJustify()
                }
                onHide={() => {
                    setJustification({ ...justification, showModal: false });
                }}
                btnNoEvent={() => {
                    setJustification({ ...justification, showModal: false });
                }}
            >
                <Col xs={12}>
                    <Form.Group className='mb-3' controlId='fName'>
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                            <b className='text-start'>
                                &nbsp;Explique la razón por la que solicita este servicio
                            </b>
                        </Form.Label>
                        <textarea
                            placeholder={'Escribir...'}
                            value={justification.data}
                            onChange={e => {
                                changeView.diagnostics
                                    ? setJustification({ ...justification, consultJustification: e.target.value })
                                    : setJustification({ ...justification, procedureJustification: e.target.value });
                            }}
                            rows='25'
                            cols='55'
                            style={{ height: '7rem' }}
                            className={`text-secondary ord-roundInput w-100`}
                        ></textarea>
                    </Form.Group>
                </Col>
            </OrdModal>
            {/* /* --------------------------------- DIALOG --------------------------------- */}
            <FullScreenDialog onHide={() => setFullDialogScreen(false)} isOpen={fullDialogScreen.show}>
                {reqItems > 0 && (
                    <OrdCustomToast
                        position='bottomLeft'
                        width='42%'
                        text={`Se ha(n) enviado (${reqItems}) solicitud(es) de servicio(s) a dirección médica `}
                        onClose={() => setReqItems(0)}
                    />
                )}

                <RenderMedicalFormulasPdf
                    withPagination
                    pdfParams={{
                        url: '/medical/pdfserviceOrder/',
                        id: fullDialogScreen.id,
                    }}
                    title={'Ordenamiento de servicio'}
                    finalize={{
                        title: 'Finalizar',
                        finalizeAction: () => {
                            setDataOut({
                                reason: '',
                                consultGeneralObservations: '',
                                procedureGeneralObservations: '',
                            });
                            setConsultTmpArr([]);
                            message('success', 'Orden(es) finalizada');
                            setFullDialogScreen(false);
                        },
                    }}
                    backAction={() => {
                        setDataOut({
                            reason: '',
                            consultGeneralObservations: '',
                            procedureGeneralObservations: '',
                        });
                        setConsultTmpArr([]);
                        setFullDialogScreen(false);
                    }}
                />
            </FullScreenDialog>

            <div className={` ${tableStyles.ordContainer}`}>
                {changeView.generalView && (
                    <>
                        <Row>
                            <Col
                                xs={12}
                                className={`d-flex justify-content-center mt-5 ${tableStyles.darkGrayText}`}
                            >
                                <h2>Orden a realizar:</h2>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: '38%' }} className='ml-5'>
                            <Col xs={6} className={`cursorPointer`}>
                                <Col className={`d-flex justify-content-center mb-2 pl-5`} xs={12}>
                                    <img width='25px' alt='estetoscópico' src={estetoscopic}></img>
                                </Col>
                                <Col
                                    onClick={() => handleGoBack('consultation')}
                                    className={`d-flex justify-content-center ${tableStyles.darkGrayText} ${tableStyles.ordBordersemiCard}`}
                                    xs={11}
                                >
                                    <span className={`pl-5 py-2 px-2`}>Consulta y/o ayudas diagnósticas</span>
                                </Col>
                            </Col>
                            {/* /* ----------------------------------- #2 ----------------------------------- */}
                            <Col xs={6} className={`cursorPointer`}>
                                <Col className={`d-flex justify-content-center mb-2 pl-5`} xs={12}>
                                    <img alt='focaEye' src={focaEye}></img>
                                </Col>
                                <Col
                                    onClick={() => handleGoBack('procedure')}
                                    className={`d-flex justify-content-center ${tableStyles.darkGrayText} ${tableStyles.ordBordersemiCard}`}
                                    xs={11}
                                >
                                    <span className={`pr-5 py-2 px-2`}>Procedimientos y/o cirugía</span>
                                </Col>
                            </Col>
                        </Row>
                        <Row className={`${tableStyles.ordBorderTop}`}></Row>
                    </>
                )}
                {/* /* --------------------------- Help or diagnostics -------------------------- */}
                {(changeView.diagnostics || changeView.procedure) && (
                    <OrdGenericTemplate
                        showBackArrow={true}
                        backArrowWidth={'10px'}
                        backArrowAction={() => {
                            setChangeView({
                                generalView: true,
                                diagnostics: false,
                                procedure: false,
                            });
                            setDataOut({
                                reason: '',
                                consultGeneralObservations: '',
                                procedureGeneralObservations: '',
                                consultService: ''
                            });
                            setConsultTmpArr([]);
                        }}
                        titleSize={12}
                        backIcon={ordBackArrow}
                        title={(changeView.diagnostics &&
                            (<p className={`pt-3 ${tableStyles.f18} ${tableStyles.ordClearBlueText}`}>
                                <b>
                                    <img
                                        src={estetoscopicBlue}
                                        width={'25px'}
                                        className='pb-2'
                                        alt='estetoscopicBlue'
                                    ></img>{' '}
                                    &nbsp; Consulta y/o ayudas diagnósticas
                                </b>
                            </p>)) || (changeView.procedure && (<p className={`pt-3 ${tableStyles.f18} ${tableStyles.ordClearBlueText}`}>
                                <b>
                                    <img width={'25px'} className='pb-2' alt='eye' src={focaEyeBlue}></img> &nbsp;
                                    Procedimientos y/o cirugía
                                </b>
                            </p>))

                        }
                    >
                        {/* /* --------------------------- First select--------------------------- */}
                        <Form.Label className={`ml-5 ${tableStyles.ordDarkBlueText}`}>
                            <b>Motivo que origina el ordenamiento</b>
                        </Form.Label>
                        <Form.Group
                            className={`${tableStyles.ordOrangeCard} p-3 mx-5 pt-2 mb-4 text-start`}
                            controlId='cc'
                        >
                            <Row className={`mb-2`}>
                                <Autocomplete
                                    noOptionsText={'No se encuentra'}
                                    sx={{
                                        input: {
                                            color: '#6e6f7c',
                                        },
                                    }}
                                    renderOption={(props, option) => {
                                        const { label } = option;
                                        return (
                                            <span
                                                {...props}
                                                style={{ color: '#6e6f7c', borderBottom: '1px solid #cecece' }}
                                            >
                                                {label}
                                            </span>
                                        );
                                    }}
                                    value={dataOut?.reason?.label}
                                    key={dataOut?.reason?.label}
                                    onChange={(event, newValue) => {
                                        setDataOut({ ...dataOut, reason: newValue });
                                    }}
                                    id='controllabless'
                                    options={formattedReasons}
                                    placeholder='Motivo que origina el ordenamiento'
                                    renderInput={params => (
                                        <TextField
                                            placeholder='Motivo que origina el ordenamiento'
                                            {...params}
                                            label=''
                                        />
                                    )}
                                />
                            </Row>
                        </Form.Group>
                        {!isEmptyOrUndefined(dataOut?.reason?.value) && (
                            <Form.Group
                                className={`mb-4 mx-5  text-start`}
                                controlId='cc'
                            >
                                <Row >
                                    <Col xs={6}>
                                        <Form.Group className='mb-3 text-start' controlId='fName'>
                                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                                <b>&nbsp;&nbsp;Tipo de orden</b>
                                            </Form.Label>
                                            <Select
                                                noOptionsMessage={() => 'No hay datos'}
                                                value={dataOut.orderType}
                                                options={formattedOrderType}
                                                onChange={e => {
                                                    setDataOut({
                                                        ...dataOut,
                                                        orderType: e,
                                                        consultService: ''
                                                    })
                                                    setConsultTmpArr([])
                                                }
                                                }
                                                className='text-secondary'
                                                placeholder={'Seleccione'}
                                                styles={ordCustomSelect}
                                            ></Select>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className='mb-3' controlId='fName'>
                                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                                <b className='text-start'>&nbsp;&nbsp;Ojo</b>
                                            </Form.Label>
                                            <Select
                                                noOptionsMessage={() => 'No hay datos'}
                                                options={formattedBodyOrgans}
                                                value={dataOut.eye}
                                                onChange={e => {
                                                    setDataOut({
                                                        ...dataOut,
                                                        eye: e,
                                                        consultService: ''
                                                    })
                                                    setConsultTmpArr([])
                                                }}
                                                className='text-secondary'
                                                placeholder={'Seleccione'}
                                                styles={ordCustomSelect}
                                                isDisabled={dataOut?.orderType?.value ? false : true}
                                            ></Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xs={12}>
                                        <Form.Group
                                            className={`${tableStyles.ordOrangeCard} p-2 mb-3 text-start`}
                                            controlId='cc'
                                        >
                                            <Row className={`mb-2`}>
                                                <Autocomplete
                                                    noOptionsText={'No se encuentra'}
                                                    sx={{
                                                        input: {
                                                            color: '#6e6f7c',
                                                        },
                                                    }}
                                                    renderOption={(props, option) => {
                                                        const { label } = option;
                                                        return (
                                                            <span
                                                                {...props}
                                                                style={{ color: '#6e6f7c', borderBottom: '1px solid #cecece' }}
                                                            >
                                                                {label}
                                                            </span>
                                                        );
                                                    }}

                                                    disabled={dataOut?.eye?.value && dataOut?.orderType?.value ? false : true}
                                                    value={dataOut?.consultService?.label}
                                                    key={dataOut?.consultService?.label}
                                                    onChange={(event, newValue) => {
                                                        const services = listCombosService?.results?.
                                                            find((obj) => obj.id === newValue?.value)?.services?.map?.((service) => {
                                                                const optimalTimeName = formattedTimes?.find(
                                                                    item => item.value === service.optimalTimeId,
                                                                )?.label
                                                                return {
                                                                    ...service,
                                                                    optimalTimeName,
                                                                    eye: dataOut.eye,
                                                                    orderType: dataOut.orderType
                                                                }
                                                            }) ?? []

                                                        setDataOut({ ...dataOut, consultService: newValue });
                                                        setConsultTmpArr(services)
                                                    }}
                                                    id='controllabless'
                                                    options={formattedServiceCombos}
                                                    placeholder='Seleccione un combo'
                                                    renderInput={params => (
                                                        <TextField
                                                            placeholder='Seleccione un combo'
                                                            {...params}
                                                            label=''
                                                        />
                                                    )}
                                                />
                                            </Row>
                                        </Form.Group>
                                    </Col >

                                </Row>
                            </Form.Group>
                        )}
                        {/* aqui estoy */}

                        {!isEmptyOrUndefined(dataOut?.reason?.value) && (
                            <>
                                {/* /* ---------------------------- CONSULT ORDTABLE ---------------------------- */}
                                <div style={{ marginBottom: '5%' }} className={`mx-5 text-start`}>
                                    <Col xs={12}>
                                        <OrdTable headers={headers} hasChildren={true}>
                                            {FormatDataTable()}
                                        </OrdTable>
                                    </Col>
                                </div>
                            </>
                        )}

                        {changeView.diagnostics &&
                            (
                                <>
                                    {/* /* ---------------------- CONSULT GENERAL OBSERVATIONS ---------------------- */}
                                    <div style={{ marginBottom: '5%' }} className={`mx-5 text-start`}>
                                        <Col xs={12}>
                                            <Form.Group className='mb-3' controlId='fName'>
                                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                                    <b className='text-start'>&nbsp;Observación general</b>
                                                </Form.Label>
                                                <textarea
                                                    value={dataOut.consultGeneralObservations}
                                                    onChange={e =>
                                                        setDataOut({ ...dataOut, consultGeneralObservations: e.target.value })
                                                    }
                                                    placeholder={'Escribir...'}
                                                    rows='25'
                                                    cols='55'
                                                    style={{ height: '4rem' }}
                                                    className={`text-secondary ord-roundInput w-100`}
                                                    onContextMenu={(event) => {
                                                        event.preventDefault();
                                                        handleOpenNoInsTextModal('serviceCombo', 'srvCombosObservation');
                                                    }}
                                                ></textarea>
                                            </Form.Group>
                                        </Col>
                                    </div>

                                    <Row className={`${tableStyles.ordBorderTop} mt-2`}>
                                        <Col xs={12} className='d-flex justify-content-end pt-3 mb-3'>
                                            <button className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
                                            {myPermission?.create && (
                                                <button
                                                    onClick={() => {
                                                        if (isEmptyOrUndefined(dataOut?.reason?.value)) {
                                                            return message('info', 'Selecciona un motivo que origina el ordenamiento')
                                                        }
                                                        consultTmpArr.length > 0
                                                            ? swalConfirm({
                                                                title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                                                                text: `Se creará(n) orden(es) de servicio(s) para ${event?.event?.firstName} ${event?.event?.lastName}`,
                                                                confirmButtonText: 'Si, continuar',
                                                                doAfterConfirm: () => handleSubmitConsult(),
                                                            })
                                                            : message('info', 'Complete todos los campos para generar la orden')
                                                    }
                                                    }
                                                    className={`${tableStyles.ordBtnPrimary} btn`}
                                                >
                                                    <img src={miniRoundWhiteCheck} className={`mr-2`} alt='check'></img>
                                                    Ordenar
                                                </button>
                                            )}
                                        </Col>
                                    </Row>
                                </>
                            )}
                        {changeView.procedure &&
                            (
                                <>
                                    {/* /* --------------------- PROCEDURE GENERAL OBSERVATIONS --------------------- */}
                                    <div style={{ marginBottom: '5%' }} className={`mx-5 text-start`}>
                                        <Col xs={12}>
                                            <Form.Group className='mb-3' controlId='fName'>
                                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                                                    <b className='text-start'>&nbsp;Observación general</b>
                                                </Form.Label>
                                                <textarea
                                                    value={dataOut.procedureGeneralObservations}
                                                    onChange={e =>
                                                        setDataOut({ ...dataOut, procedureGeneralObservations: e.target.value })
                                                    }
                                                    placeholder={'Escribir...'}
                                                    rows='25'
                                                    cols='55'
                                                    style={{ height: '4rem' }}
                                                    className={`text-secondary ord-roundInput w-100`}
                                                    onContextMenu={(event) => {
                                                        event.preventDefault();
                                                        handleOpenNoInsTextModal('proceduresSurgery', 'procedureGeneralObservations');
                                                    }}
                                                ></textarea>
                                            </Form.Group>
                                        </Col>
                                    </div>
                                    {/* /* --------------------------------- Checks --------------------------------- */}
                                    {!isEmptyOrUndefined(dataOut?.reason?.value) && (
                                        <>
                                            <Form.Group
                                                className={`${tableStyles.ordSearchSections} mb-4 mx-5 pt-2 text-start`}
                                                controlId='cc'
                                            >
                                                <Row className={`p-3 `}>
                                                    <Col xs={12}>
                                                        <p className={`pb-0 mb-0 ${tableStyles.ordClearBlueText} ${tableStyles.f18}`}>
                                                            <b>Exámenes prequirúrgicos</b>
                                                        </p>
                                                    </Col>
                                                    {prequisurgicalExams.results?.length > 0 && (
                                                        <Box
                                                            display='grid'
                                                            gridTemplateColumns={'1fr 1fr 1fr'}
                                                            gap='15px'
                                                            className='my-3'
                                                        >
                                                            {prequisurgicalExams.results.map(item => {
                                                                return (
                                                                    <Col
                                                                        key={generateId()}
                                                                        className={`m-0 p-0 d-flex align-items-center gap-3 w-100 ${tableStyles.f14}`}
                                                                        xs={4}
                                                                    >
                                                                        <Checkbox
                                                                            key={item?.id}
                                                                            id={`item${item.id}`}
                                                                            onChange={e => handleChangePrequirugical(item, e)}
                                                                            checked={
                                                                                preQuiIds.find(x => x === item.id) === undefined ? false : true
                                                                            }
                                                                            style={{ borderRadius: '10px' }}
                                                                            className={'form-check-input'}
                                                                        />
                                                                        <label htmlFor={`item${item.id}`}>
                                                                            <b className={tableStyles.darkGrayText}>{item?.name}</b>
                                                                        </label>
                                                                    </Col>
                                                                );
                                                            })}
                                                        </Box>
                                                    )}
                                                </Row>
                                            </Form.Group>
                                        </>
                                    )}
                                    <Row className={`${tableStyles.ordBorderTop} mt-2`}>
                                        <Col xs={12} className='d-flex justify-content-end pt-3 mb-3'>
                                            <button className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
                                            {myPermission?.create && (
                                                <button
                                                    onClick={() => {
                                                        if (isEmptyOrUndefined(dataOut?.reason?.value)) {
                                                            return message('info', 'Selecciona un motivo que origina el ordenamiento')
                                                        }
                                                        consultTmpArr.length > 0
                                                            ? swalConfirm({
                                                                title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                                                                text: `Se creará(n) orden(es) de servicios para ${event?.event?.firstName} ${event?.event?.lastName}`,
                                                                confirmButtonText: 'Si, continuar',
                                                                doAfterConfirm: () => handleSubmitProcedure(),
                                                            })
                                                            : message('info', 'Complete todos los campos para generar la orden')
                                                    }
                                                    }
                                                    className={`${tableStyles.ordBtnPrimary} btn`}
                                                >
                                                    <img src={miniRoundWhiteCheck} className={`mr-2`} alt='check'></img>
                                                    Ordenar
                                                </button>
                                            )}
                                        </Col>
                                    </Row>
                                </>
                            )}

                    </OrdGenericTemplate>
                )}
            </div>
        </>
    );
};
