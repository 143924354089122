import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { showAccountInfo } from '../../actions/headerActions';
import { getCategories } from '../../actions/loginActions';
import toBack from '../../assets/img/icons/Arrow-black.svg';
import toNext from '../../assets/img/icons/Arrow-gray.svg';
import { getExternalUrl, loader } from '../../helpers';

import Header from '../../components/Header/Header';

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import 'reactjs-popup/dist/index.css';
import layoutStyles from '../../routes/fullLayout.module.css';
import styles from './CategorySelect.module.scss';

const DynamicRedirect = ({ isExternal, category }) => {
  const counter = useSelector(state => state);
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: '/' + category?.url + '/inicio',
      state: {
        group: category.description,
        catDetail: {
          ...category,
          title: 'Módulo de ' + category.title,
        },
      },
    });
  };

  const renderCard = () => {
    return (
      <Card className={`${styles.flex1} rounded-5 mb-2`}>
        <Row>
          <Col xs={3}>
            <img src={category.img} className={styles.classIcon} alt='title' />
          </Col>
          <Col xs={7}>
            <p className={`my-auto ${styles.classTitle}`}>{category.title}</p>

            <p className={`my-auto ${styles.classSubTitle}`}>
              {category.subtitle?.length >= 55
                ? `${category.subtitle.slice(0, 52)}...`
                : category.subtitle}
            </p>
          </Col>
          <Col className='d-flex pb-3' xs={2}>
            <img src={toNext} className={`${styles.classArrow} pt-3`} alt='next' />
          </Col>
        </Row>
      </Card>
    );
  };

  if (isExternal) {
    return (
      <a
        href={getExternalUrl({ loginReducer: counter.loginReducer, category })}
        style={{ textDecoration: 'none' }}
      >
        {renderCard()}
      </a>
    );
  }

  return <div onClick={handleClick}>{renderCard()}</div>;
};

function CategorySelect() {
  const counter = useSelector(state => state);
  const history = useHistory();
  const categories = counter.loginReducer?.newCategories;

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!counter.loginReducer.Authenticated) {
      history.push('/login');
    }
  }, [counter.loginReducer.Authenticated, history]);

  useEffect(() => {
    dispatch(getCategories(counter.loginReducer.currentAccount.profile.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter.loginReducer.currentAccount.profile.id, dispatch, location.pathname]);

  return (
    <div className={`${layoutStyles.fullLayout}  ${styles.bgImage} `}>
      {categories === undefined && counter.loginReducer.loadingCategories && loader}
      <Header isAuth={true}></Header>
      <div className={`${layoutStyles.fullLayoutBody} `}>
        <div
          className={`p-5 ${layoutStyles.fullLayoutWorkArea}`}
          onClick={() => dispatch(showAccountInfo(false))}
        >
          <div className={`mt-2 ${styles.marginRight}`}>
            <Link to={`/accountselect`}>
              <img alt='goBack' src={toBack}></img>
            </Link>
          </div>
          <div className={`w-100`}>
            {categories?.length > 0 ? (
              categories.map((category, i) => {
                return (
                  <Row key={i}>
                    <label className={`${styles.TitleBar} ${styles.marginRight}`}>
                      {category.description}{' '}
                    </label>
                    {category.data?.map((mod, i) => {
                      const isExternal = counter?.loginReducer?.all_categories?.find(
                        cat => cat?.name === mod?.title,
                      )?.isExternal;

                      return (
                        <>
                          <Col key={`Module${i}`} xs={4}>
                            <DynamicRedirect isExternal={isExternal} category={mod} />
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                );
              })
            ) : (
              <div key={0} className={'mt-2'}>
                <span>Este usuario no tiene módulos accesibles</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategorySelect;
