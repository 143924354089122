// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';

import Descarga from '../../assets/img/icons/downloadArrow.svg';
import Reembolso from '../../assets/img/icons/Reembolso.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

import {
  createExpense,
  deleteExpense,
  getDetailExpense,
  getListPettyCash,
  getListTypeExpense,
  getOneDetailExpense,
  sendForReimbursement,
  updateExpense,
} from '../../actions/accountingActions';
import { getAllConCenter } from '../../actions/consumptionCenterActions';
import { get_third_party } from '../../actions/deductionsAccrualsAction';

import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { Title } from '../../shared';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import ModalNew from '../Layouts/ModalNew';
import CustomPopupExtend from '../Popup/customPopUpExtends';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { GET_DETAIL_EXPENSE } from '../../actions/actionTypes';
import { customSelectNew, customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import Styles from './pettyCashStyles.module.scss';
import { formatNumberCurrency, getLocaleSeparators } from '../../helpers/numberFormatting';
import { getEnvs } from '../../helpers';

const localeSeparators = getLocaleSeparators();
const { CURRENCY } = getEnvs();

function Egress() {
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const history = useHistory();

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'Egress',
    );

  const [trigger, setTrigger] = useState(1);
  const [triggerSelect, setTriggerSelect] = useState(1);
  const [triggerModal, setTriggerModal] = useState(1);
  const [showModal, setShowModal] = useState({
    show: false,
    selectedEgress: '',
  });
  const [creatEgress, setcreatEgress] = useState({
    pettyCash: '',
    date: '',
    description: '',
    expType: '',
    thirdParty: '',
    cc: '',
    amount: '',
    supportDocument: false,
    user: storage.loginReducer.user_data.id,
    eaccount: storage.loginReducer.currentAccount.id,
  });
  const [detailEgress, setdetailEgress] = useState({
    date: '',
    description: '',
    expType: '',
    thirdParty: '',
    cc: '',
    amount: '',
    supportDocument: false,
    user: storage.loginReducer.user_data.id,
    eaccount: storage.loginReducer.currentAccount.id,
  });
  const [info, setInfo] = useState({
    eaccount: storage.loginReducer.currentAccount.id,
    pettyCash: '',
  });
  const [showSection, setshowSection] = useState(true);

  useEffect(() => {
    if (!myPermission()?.create || !myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getListPettyCash({ status: 'enabled', eaccount: storage.loginReducer.currentAccount.id }),
    );
    dispatch(
      getListTypeExpense({ status: 'enabled', eaccount: storage.loginReducer.currentAccount.id }),
    );
    dispatch(
      getAllConCenter({ active: 1, eaccount: storage.loginReducer.currentAccount.id, lite: true }),
    );
    dispatch(get_third_party({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch({
      type: GET_DETAIL_EXPENSE,
      payload: [],
      total: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (info.pettyCash !== '') {
      dispatch(
        getDetailExpense(
          {
            eaccount: storage.loginReducer.currentAccount.id,
            pettyCash: info.pettyCash,
            status: 'registered',
          },
          null,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (showModal?.selectedEgress) {
      dispatch(
        getOneDetailExpense(
          {
            eaccount: storage.loginReducer.currentAccount.id,
            id: showModal.selectedEgress,
          },
          stuff => {
            setdetailEgress(stuff);
          },
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerModal]);

  const creatNewEgress = () => {
    if (showModal.edit) {
      dispatch(
        updateExpense(
          {
            pettyCash: detailEgress.pettyCash,
            date: detailEgress.date,
            description: detailEgress.description,
            expType: detailEgress.expType,
            thirdParty: detailEgress.thirdParty,
            cc: detailEgress.cc,
            amount: detailEgress.amount,
            supportDocument: detailEgress.supportDocument,
            user: storage.loginReducer.user_data.id,
            eaccount: storage.loginReducer.currentAccount.id,
            id: showModal.selectedEgress,
          },
          () => {
            setShowModal({ show: false });
            setTrigger(trigger + 1);
          },
        ),
      );
    }
  };

  const buttonReimbursement = () => {
    dispatch(
      sendForReimbursement(
        {
          eaccount: storage.loginReducer.currentAccount.id,
          pettyCash: info.pettyCash,
        },
        () => {
          dispatch({
            type: GET_DETAIL_EXPENSE,
            payload: [],
            total: 0,
          });
          setInfo({
            eaccount: storage.loginReducer.currentAccount.id,
            pettyCash: '',
          });
          setTrigger(trigger + 1);
        },
      ),
    );
  };

  const deleteRow = item => {
    dispatch(
      deleteExpense({ id: item.id, eaccount: storage.loginReducer.currentAccount.id }, () => {
        setTrigger(trigger + 1);
      }),
    );
  };

  const bodyTemplate = () => {
    let body = [];
    for (let i = 0; i < 10; i++) {
      let bodyTemp = (
        <section2 key={i} className={`d-flex`}>
          <div className={`col-3`}>-</div>
          <div className={`col-5 text-start`}>-</div>
          <div className={`col-3 text-end`}>-</div>
          <div className={`col-1`}>-</div>
        </section2>
      );
      body.push(bodyTemp);
    }
    return body;
  };

  const header = [
    <div key={1} className={`col-3`}>
      Fecha
    </div>,
    <div key={2} className={`col-5 text-start`}>
      Detalle
    </div>,
    <div key={3} className={`col-3 text-end`}>
      Valor
    </div>,
    <div key={4} className={`col-1`}>
      &nbsp;
    </div>,
  ];

  const [renderList, totalEgress] = useMemo(() => {
    let tempList = [];
    let total = 0;
    if (Array.isArray(storage.accountingReducer.detailExpense)) {
      storage.accountingReducer.detailExpense.forEach((item, index) => {
        total = total + Number(item.amount);
        tempList.push(
          <section2 className={`d-flex`} key={index}>
            <div className={`col-3`}>
              {item.date?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </div>
            <div className={`col-5 text-start`}>{item.description}</div>
            <div className={`col-3 text-end`}>
              {item.amount ? formatNumberCurrency(item.amount) : ''}
            </div>
            <div className={`col-1`}>
              <CustomPopupExtend
                triggerSrc={threeDots}
                showDelete={myPermission()?.delete}
                deleteEvent={() => deleteRow(item)}
                noHover
                showEdit={myPermission()?.edit}
                editClickEvent={() => {
                  setShowModal({ show: true, edit: true, selectedEgress: item.id });
                  setTriggerModal(triggerModal + 1);
                }}
                showDetails={true}
                showDetailsEvent={() => {
                  setShowModal({ show: true, edit: false, selectedEgress: item.id });
                  setTriggerModal(triggerModal + 1);
                }}
                textDetails={'Ver detalle'}
              />
            </div>
          </section2>,
        );
      });
    }
    return [tempList, total];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    storage.accountingReducer.detailExpense,
    triggerModal,
    storage.loginReducer.currentAccount.id,
    myPermission,
  ]);

  //-------------- select options 🍕   --------------

  let optionsPettyCash = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.accountingReducer.listPettyCash)) {
    storage.accountingReducer.listPettyCash.forEach((item, index) => {
      optionsPettyCash.push({
        value: item.id,
        label: item.name,
        key: index + 'pettyC',
      });
    });
  }

  let optionsTypes = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.accountingReducer.listTypesExpense)) {
    storage.accountingReducer.listTypesExpense.forEach((item, index) => {
      optionsTypes.push({
        value: item.id,
        label: item.description,
        key: index + 'type',
      });
    });
  }
  let optionsCostCenter = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.consumptionCenterReducer.consumption_center)) {
    storage.consumptionCenterReducer.consumption_center.forEach((item, index) => {
      optionsCostCenter.push({
        value: item.id,
        label: item.description,
        key: index + 'cc',
      });
    });
  }
  let optionsThird = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.accountingReducer.listThirdParties)) {
    storage.accountingReducer.listThirdParties.forEach((item, index) => {
      optionsThird.push({
        value: item.id,
        label: item.nit + ' - ' + item.description,
        key: index + 'third',
      });
    });
  }
  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {storage.accountingReducer.detailExpense_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <div className={tableStyles.title}>
          <Title
            title='Egresos'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>
        <Row>
          <Col xs={5}>
            <div className='display-grid mt-1'>
              <label className={tableStyles.crudModalLabel}>Caja menor</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                options={optionsPettyCash}
                onChange={e => {
                  setInfo({
                    ...info,
                    pettyCash: e.value,
                  });
                  setcreatEgress({
                    ...creatEgress,
                    pettyCash: e.value,
                  });
                  setTrigger(trigger + 1);
                }}
              />
            </div>
          </Col>
        </Row>

        {showSection && myPermission().create ? (
          <div
            style={{
              backgroundColor: 'rgba(0, 93, 191, 0.063)',
              borderRadius: '20px 20px 20px 20px',
              width: '100%',
              maxHeight: '32px',
              flex: '1',
              display: 'block',
              padding: '1rem 1rem',
              marginTop: '12px',
              cursor: 'pointer',
            }}
            onClick={() => (showSection ? setshowSection(false) : setshowSection(true))}
          >
            <p
              style={{
                color: 'rgb(0, 93, 191)',
                justifyContent: 'flex-start',
                marginTop: '-11px',
                fontWeight: '700',
                cursor: 'pointer',
              }}
            >
              + Agregar egreso
            </p>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: 'rgba(0, 93, 191, 0.063)',
              borderRadius: '20px 20px 20px 20px',
              width: '100%',
              maxHeight: '230px',
              flex: '1',
              padding: '1rem 1rem',
              marginTop: '12px',
              display: 'block',
            }}
          >
            <Row>
              <p
                style={{
                  color: 'rgb(0, 93, 191)',
                  justifyContent: 'flex-start',
                  marginTop: '-12px',
                  marginBottom: '0px',
                  fontWeight: '700',
                  cursor: 'pointer',
                }}
                onClick={() => (showSection ? setshowSection(false) : setshowSection(true))}
              >
                + Agregar egreso
              </p>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Fecha</p>
                <input
                  placeholder='Escribir...'
                  key={'date' + triggerSelect}
                  className={IndividualStyles.registerInputsBlue}
                  type='date'
                  value={setcreatEgress.date}
                  onChange={e =>
                    setcreatEgress({
                      ...creatEgress,
                      date: e.target.value,
                    })
                  }
                  max={new Date().toISOString().split('T')[0]}
                />
              </Col>
              <Col xs={8}>
                <p className={tableStyles.crudModalLabel}>Detalle</p>
                <input
                  placeholder='Escribir...'
                  key={'Detail' + triggerSelect}
                  className={IndividualStyles.registerInputsGris}
                  name='details'
                  type='text'
                  value={setcreatEgress.description}
                  onChange={e =>
                    setcreatEgress({
                      ...creatEgress,
                      description: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Tipo de gasto</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'typeCost' + triggerSelect}
                  placeholder='Seleccionar...'
                  options={optionsTypes}
                  styles={customSelectNewDark}
                  value={optionsTypes.find(item => item.value === creatEgress.expType)}
                  onChange={e =>
                    setcreatEgress({
                      ...creatEgress,
                      expType: e.value,
                    })
                  }
                />
              </Col>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Cuenta</p>
                <input
                  placeholder='Escribir...'
                  className={IndividualStyles.registerInputsGris}
                  name='requisition_date'
                  type='text'
                  value={
                    storage.accountingReducer.listTypesExpense?.find(
                      x => x.id === creatEgress.expType,
                    )
                      ? storage.accountingReducer.listTypesExpense.find(
                          x => x.id === creatEgress.expType,
                        ).niifAccountNbr +
                        '-' +
                        storage.accountingReducer.listTypesExpense.find(
                          x => x.id === creatEgress.expType,
                        ).niifAccountName
                      : ''
                  }
                  disabled
                />
              </Col>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Tercero</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'third' + triggerSelect}
                  options={optionsThird}
                  placeholder='Seleccionar...'
                  value={optionsThird.find(item => item.value === Number(creatEgress.thirdParty))}
                  styles={customSelectNewDark}
                  onChange={e =>
                    setcreatEgress({
                      ...creatEgress,
                      thirdParty: e.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'centerc' + triggerSelect}
                  placeholder='Seleccionar...'
                  options={optionsCostCenter}
                  value={optionsCostCenter.find(item => item.value === Number(creatEgress.cc))}
                  styles={customSelectNewDark}
                  onChange={e =>
                    setcreatEgress({
                      ...creatEgress,
                      cc: e.value,
                    })
                  }
                />
              </Col>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Valor egreso</p>
                <NumberFormat
                  allowNegative={false}
                  style={{ padding: 0, color: '#58595B' }}
                  className={IndividualStyles.registerInputs}
                  name='valueNote'
                  suffix={` ${CURRENCY}`}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  thousandSeparator={localeSeparators.groupSeparator}
                  decimalScale={2}
                  fixedDecimalScale
                  value={creatEgress.amount}
                  onValueChange={values => {
                    const { value } = values;
                    setcreatEgress({
                      ...creatEgress,
                      amount: value,
                    });
                  }}
                  isAllowed={values => {
                    const { floatValue } = values;
                    if (floatValue !== undefined) {
                      if (floatValue <= 0) {
                        return false;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }}
                ></NumberFormat>
              </Col>
              <Col xs={1} style={{ margin: '0px', paddingRight: '0px' }}>
                <p className={tableStyles.crudModalLabel}>ㅤ</p>
                <input
                  placeholder='Escribir...'
                  className='border border-dark-blue form-check-input p1 check-dark-blue'
                  type='checkbox'
                  name='edit'
                  value='selectedDocument'
                  checked={creatEgress.supportDocument}
                  onChange={e =>
                    setcreatEgress({
                      ...creatEgress,
                      supportDocument: e.target.checked,
                    })
                  }
                />
              </Col>
              <Col xs={3} style={{ padding: '0px', marginLeft: '-55px' }}>
                <p className={tableStyles.crudModalLabel}>ㅤ</p>
                <p
                  style={{ color: '#58595B', fontWeight: '400', fontSize: '13px' }}
                  className='px-2'
                >
                  Generar un documento soporte
                </p>
              </Col>
            </Row>
            <Row>
              {/* ---------------------------- ROW 4 ---------------------------------- */}
              <Col
                xs={12}
                style={{
                  marginTop: '3px',
                  justifyContent: 'flex-end',
                  display: 'flex',
                  paddingBottom: '8px',
                }}
              >
                <img
                  src={Descarga}
                  alt=''
                  srcSet=''
                  className={creatEgress.date === '' ? Styles.changeColor : null}
                  onClick={() =>
                    dispatch(
                      createExpense(
                        {
                          pettyCash: info.pettyCash,
                          date: creatEgress.date,
                          description: creatEgress.description,
                          expType: creatEgress.expType,
                          thirdParty: creatEgress.thirdParty,
                          cc: creatEgress.cc,
                          amount: creatEgress.amount,
                          supportDocument: creatEgress.supportDocument,
                          user: storage.loginReducer.user_data.id,
                          eaccount: storage.loginReducer.currentAccount.id,
                        },
                        () => {
                          setcreatEgress({
                            pettyCash: '',
                            date: '',
                            description: '',
                            expType: '',
                            thirdParty: '',
                            cc: '',
                            amount: '',
                            supportDocument: false,
                            user: storage.loginReducer.user_data.id,
                            eaccount: storage.loginReducer.currentAccount.id,
                          });
                          setTriggerSelect(triggerSelect + 1);
                          setTrigger(trigger + 1);
                          setshowSection(false);
                        },
                      ),
                    )
                  }
                />
              </Col>
            </Row>
          </div>
        )}

        <div>
          <GenericTableScroll
            headers={header}
            dark={true}
            body={storage.accountingReducer.detailExpense?.length > 0 ? renderList : bodyTemplate()}
            typeHead={'2'}
          ></GenericTableScroll>
        </div>

        <Row>
          <Col xs={2}>
            <div className='display-grid mt-1'>
              <label className={tableStyles.crudModalLabel}>Fondo</label>
              <NumberFormat
                key={'fondo' + trigger}
                allowNegative={false}
                style={{ color: '#58595B', background: '#F5F7FA' }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                suffix={` ${CURRENCY}`}
                decimalSeparator={localeSeparators.decimalSeparator}
                thousandSeparator={localeSeparators.groupSeparator}
                decimalScale={2}
                fixedDecimalScale
                value={
                  storage.accountingReducer.listPettyCash?.find(x => x.id === info.pettyCash)
                    ? storage.accountingReducer.listPettyCash.find(x => x.id === info.pettyCash)
                        .totalAmount
                    : ''
                }
              ></NumberFormat>
            </div>
          </Col>

          <Col xs={2}>
            <div className='display-grid mt-1'>
              <label className={tableStyles.crudModalLabel}>Saldo</label>
              <NumberFormat
                key={'saldo' + trigger}
                allowNegative={false}
                style={{ color: '#58595B', background: '#F5F7FA' }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                suffix={` ${CURRENCY}`}
                decimalSeparator={localeSeparators.decimalSeparator}
                thousandSeparator={localeSeparators.groupSeparator}
                decimalScale={2}
                fixedDecimalScale
                value={
                  storage.accountingReducer.listPettyCash?.find(x => x.id === info.pettyCash)
                    ? Number(
                        storage.accountingReducer.listPettyCash.find(x => x.id === info.pettyCash)
                          .totalAmount,
                      ) - totalEgress
                    : ''
                }
              ></NumberFormat>
            </div>
          </Col>

          <Col xs={2}>
            <div className='display-grid mt-1'>
              <label className={tableStyles.crudModalLabel}>% Gastado</label>
              <input
                placeholder='Escribir...'
                key={'gastado' + trigger}
                className={IndividualStyles.registerInputs}
                name='costs'
                type='number'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                value={
                  storage.accountingReducer.listPettyCash?.find(x => x.id === info.pettyCash)
                    ? (totalEgress /
                        Math.round(
                          Number(
                            storage.accountingReducer.listPettyCash.find(
                              x => x.id === info.pettyCash,
                            ).totalAmount,
                          ),
                        )) *
                      100
                    : ''
                }
                disabled
              />
            </div>
          </Col>

          <Col xs={6}>
            <div className='display-grid mt-1' style={{ justifyContent: 'flex-end' }}>
              <label className={tableStyles.crudModalLabel}>Total gastos</label>
              <NumberFormat
                key={'total gastos' + trigger}
                allowNegative={false}
                style={{ color: '#58595B', background: '#F5F7FA' }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                suffix={` ${CURRENCY}`}
                decimalSeparator={localeSeparators.decimalSeparator}
                thousandSeparator={localeSeparators.groupSeparator}
                decimalScale={2}
                fixedDecimalScale
                value={totalEgress}
                disabled={true}
              ></NumberFormat>
            </div>
          </Col>
        </Row>

        <div>
          <ModalNew
            show={showModal.show}
            onHide={() => {
              setShowModal({ show: false });
              setcreatEgress({
                pettyCash: '',
                date: '',
                description: '',
                expType: '',
                thirdParty: '',
                cc: '',
                amount: '',
                supportDocument: false,
                user: storage.loginReducer.user_data.id,
                eaccount: storage.loginReducer.currentAccount.id,
              });
            }}
            title={showModal.edit ? 'Editar egreso' : 'Detalle de egreso'}
            btnYesEvent={showModal.edit ? () => creatNewEgress() : null}
            btnYesName={showModal.edit ? 'Guardar' : ''}
            btnYesDisabled={''}
            size={'500'}
          >
            <Row className='d-flex'>
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>Fecha</p>
                <input
                  placeholder='Escribir...'
                  type='date'
                  name='activity name'
                  value={detailEgress.date}
                  onChange={e =>
                    setdetailEgress({
                      ...detailEgress,
                      date: e.target.value,
                    })
                  }
                  className={IndividualStyles.registerInputsBlue}
                  disabled={!showModal.edit}
                />
              </Col>
            </Row>
            <Row className='d-flex'>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>Detalle</p>
                <input
                  placeholder='Escribir...'
                  type='text'
                  name='activity name'
                  value={detailEgress.description}
                  style={{ color: '#58595B' }}
                  onChange={e =>
                    setdetailEgress({
                      ...detailEgress,
                      description: e.target.value,
                    })
                  }
                  className={IndividualStyles.registerInputsClearBlue}
                  disabled={!showModal.edit}
                />
              </Col>
            </Row>
            <Row className='d-flex'>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>Tipo de gasto</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'type' + detailEgress.expType}
                  options={optionsTypes}
                  styles={customSelectNew}
                  value={optionsTypes.find(item => item.value === detailEgress.expType)}
                  onChange={e =>
                    setdetailEgress({
                      ...detailEgress,
                      expType: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>
            <Row className='d-flex'>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>Tercero</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'third' + detailEgress.thirdParty}
                  options={optionsThird}
                  value={optionsThird.find(item => item.value === detailEgress.thirdParty)}
                  styles={customSelectNewDark}
                  onChange={e =>
                    setdetailEgress({
                      ...detailEgress,
                      thirdParty: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'cc' + detailEgress.cc}
                  options={optionsCostCenter}
                  value={optionsCostCenter.find(item => item.value === Number(detailEgress.cc))}
                  styles={customSelectNewDark}
                  onChange={e =>
                    setdetailEgress({
                      ...detailEgress,
                      cc: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>Valor egreso</p>
                <NumberFormat
                  allowNegative={false}
                  style={{ color: '#58595B' }}
                  className={IndividualStyles.registerInputsClearBlue}
                  name='valueNote'
                  suffix={` ${CURRENCY}`}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  thousandSeparator={localeSeparators.groupSeparator}
                  decimalScale={2}
                  fixedDecimalScale
                  value={detailEgress.amount}
                  onValueChange={values => {
                    const { value } = values;
                    setdetailEgress({
                      ...detailEgress,
                      amount: value,
                    });
                  }}
                  disabled={showModal.edit || showModal.creat ? false : true}
                ></NumberFormat>
              </Col>
            </Row>
          </ModalNew>
          {myPermission()?.create ? (
            <div className={IndividualStyles.bottomRow}>
              <Button
                className={IndividualStyles.btnPrimaryDark}
                onClick={() => buttonReimbursement()}
                disabled={!!(storage.accountingReducer.detailExpense?.length <= 0)}
              >
                <img src={Reembolso} alt='my images' /> <span></span> Reembolsar
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
}
export default Egress;
