// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Pagination from 'react-js-pagination';
import { useHistory } from 'react-router';

// --------------- icons 💥  ----------------//
import Excel from '../../assets/img/icons/excel.svg';
import Imprimir from '../../assets/img/icons/imprimir.svg';
//-------------- scss styles 😄   --------------
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import Styles from './Reports.module.scss';

// ------------------ Functions ----------------------
import { generalReport } from '../../actions/reportsActions';
import deepcopy from 'deepcopy';
import { convertDateToLatinFormat, convertMoneyFormat } from '../../helpers/helpers';
import { Redirect } from 'react-router-dom';
import { getPdfgeneralBalance } from '../../actions/consultAction';
import ModalNew from '../../components/Layouts/ModalNew';
import { PdfViewer } from '../../components/Layouts/PdfViewer';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { Title } from '../../shared';
import { formatNumberCurrency } from "../../helpers/numberFormatting";

function GeneralBalance() {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const [showSection, setShowSection] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [base64, setBase64] = useState('');
  const getPdf = async () => {
    const result = await getPdfgeneralBalance(info, storage.loginReducer.Authorization);

    if (result?.success) {
      return setBase64(result?.base64?.split("'")[1]);
    } else {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Ha ocurrido un error, no ha sido posible cargar el documento`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
      return setShowPdf(false);
    }
  };
  useEffect(() => {
    if (showPdf) {
      getPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPdf]);

  const [info, setInfo] = useState({
    reportType: 'generalBalance',
    jrnlType: '',
    dateUntil: '',
    orderType: '',
    eaccount: storage.loginReducer.currentAccount.id,
    page: 1,
    perpage: 10,
    excludeVoucher: false,
    dateReport: false,
    pagination: false,
    period: false,
    sendInfo: false,
    dateUntilPeriod: '',
    dateComparative: '',
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'generalBal',
    );

  //--------------first functions 🍕   -----------
  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!info.dateUntil !== '') {
      dispatch(generalReport(info));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.page]);

  //--------------  Actions  🍕   --------------
  const generateGeneralBalanceReport = () => {
    let data = deepcopy(info);
    if (!data.pagination) {
      delete data['page'];
      delete data['perpage'];
    }
    dispatch(
      generalReport(info, () => {
        setInfo({ ...info, sendInfo: true });
      }),
    );
    setShowSection(true);
  };

  return (
    <>
      {!myPermission().read && <Redirect to={'/contabilidad/incio'} />}
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className={tableStyles.title}>
          <Title
            title='Informe de balance general'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>
        <div className='d-flex'>
          {/*----------------------------FIRST SECTION---------------------------------- */}
          <div
            className={`${tableStyles.container}`}
            style={{
              width: '35%',
              paddingLeft: '0px',
              borderRight: '1px solid #CECECE',
              marginRight: '1px',
            }}
          >
            <Row
              className='d-flex'
              style={{
                background: 'rgba(0, 93, 191, 0.05)',
                borderRadius: '10px',
                height: '82px',
                marginRight: '1px',
                marginLeft: '1px',
                marginTop: '16px',
                paddingRight: '8px',
                paddingLeft: '8px',
                paddingTop: '8px',
                paddingBottom: '8px',
              }}
            >
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>
                  Saldo al día<span className='text-warning'>*</span>
                </p>
                <input
                  className={IndividualStyles.registerInputsBlue}
                  style={{ padding: '0 0.3rem' }}
                  type='date'
                  value={info.dateUntil}
                  onChange={e =>
                    setInfo({
                      ...info,
                      dateUntil: e.target.value,
                    })
                  }
                />
              </Col>
              {info.period && (
                <Col xs={6} style={{ paddingLeft: '18px' }}>
                  <p className={tableStyles.crudModalLabel}>Fecha comparativa</p>
                  <input
                    key={'dateFromPeriod' + trigger}
                    style={{ padding: '0 0.15rem' }}
                    className={IndividualStyles.registerInputsBlue}
                    type='date'
                    value={info.dateComparative}
                    onChange={e =>
                      setInfo({
                        ...info,
                        dateComparative: e.target.value,
                      })
                    }
                  />
                </Col>
              )}
            </Row>

            <Row className='d-flex' style={{ paddingTop: '24px', paddingBottom: '16px' }}>
              <p
                className={tableStyles.crudModalLabel}
                style={{ fontWeight: '700', fontSize: '16px' }}
              >
                Opciones
              </p>
            </Row>

            <Row className='d-flex'>
              <Col xs={1} style={{ paddingLeft: '18px' }}>
                <input
                  className='border border-dark-blue form-check-input p1 check-dark-blue'
                  type='checkbox'
                  name='edit'
                  checked={info.dateReport}
                  onChange={e => {
                    setInfo({ ...info, dateReport: e.target.checked });
                  }}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: '1px' }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: '#58595B',
                    marginTop: '4px',
                    fontSize: '13px',
                  }}
                >
                  No imprimir fecha en informe
                </p>
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={1} style={{ paddingLeft: '18px' }}>
                <input
                  className='border border-dark-blue form-check-input p1 check-dark-blue'
                  type='checkbox'
                  checked={info.excludeVoucher}
                  onChange={e => setInfo({ ...info, excludeVoucher: e.target.checked })}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: '1px' }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: '#58595B',
                    marginTop: '4px',
                    fontSize: '13px',
                  }}
                >
                  Excluir los comprobantes de cierre
                </p>
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={1} style={{ paddingLeft: '18px' }}>
                <input
                  className='border border-dark-blue form-check-input p1 check-dark-blue'
                  type='checkbox'
                  checked={info.pagination}
                  onChange={e => setInfo({ ...info, pagination: e.target.checked })}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: '1px' }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: '#58595B',
                    marginTop: '4px',
                    fontSize: '13px',
                  }}
                >
                  Imprimir en páginas numeradas
                </p>
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={1} style={{ paddingLeft: '18px' }}>
                <input
                  className='border border-dark-blue form-check-input p1 check-dark-blue'
                  type='checkbox'
                  checked={info.period}
                  onChange={e => setInfo({ ...info, period: e.target.checked })}
                />
              </Col>
              <Col xs={11} style={{ paddingLeft: '1px' }}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color: '#58595B',
                    marginTop: '4px',
                    fontSize: '13px',
                  }}
                >
                  Comparar con otro periodo
                </p>
              </Col>
            </Row>

            <Row className='d-flex' style={{ marginTop: '40px', marginButton: '5px' }}>
              <Col xs={3} style={{ marginRight: '5%' }}>
                <Button
                  className={`${IndividualStyles.btnPrimary}`}
                  onClick={() => {
                    setShowSection(false);
                    setInfo({
                      reportType: 'generalBalance',
                      jrnlType: '',
                      dateUntil: '',
                      orderType: '',
                      eaccount: storage.loginReducer.currentAccount.id,
                      page: 1,
                      perpage: 10,
                      excludeVoucher: false,
                      dateReport: false,
                      pagination: false,
                      period: false,
                      sendInfo: false,
                    });
                    setTrigger(trigger + 1);
                  }}
                  disabled={false}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={7}>
                <Button
                  className={IndividualStyles.btnPrimaryDark}
                  onClick={() => generateGeneralBalanceReport()}
                  disabled={!!info.dateUntil === ''}
                >
                  Generar informe
                </Button>
              </Col>
              {/* <Col xs={4}>

                                    </Col> */}
            </Row>
          </div>

          {/*----------------------------END FIRST SECTION---------------------------------- */}

          {/*----------------------------SECOND SECTION---------------------------------- */}

          {showSection ? (
            <div
              style={{
                width: '65%',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '40px',
              }}
            >
              {storage.reportsReducer.loadingGR && (
                <div className='loading'>
                  <Loader type='Oval' color='#003f80' height={100} width={100} />
                </div>
              )}

              <div className=' display-grid col-12' style={{ textAlignLast: 'end' }}>
                <div className='col' style={{ textAlignLast: 'end' }}>
                  <img
                    alt=''
                    className={`${Styles.btn} `}
                    style={{ paddingLeft: '5px', paddingRight: '5px' }}
                    src={Imprimir}
                    onClick={() => setShowPdf(true)}
                  />
                  <img alt='' className={`${Styles.btn}`} src={Excel} />
                </div>
              </div>

              <div
                style={{
                  marginTop: '9px',
                  backgroundColor: '#FFFF',
                  padding: '21px',
                  height: '100%',
                  width: '100%',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                }}
              >
                <div className='d-flex justify-content-center'>
                  <h5 className='text-primary fw-bold text-center'>
                    {storage.loginReducer.currentAccount.bussines_name}
                    <br />
                    <span style={{ fontSize: '1rem' }}>
                      {formatNumberCurrency(storage.loginReducer.currentAccount.nit)}
                    </span>
                    <br></br>
                    <p className='darkGray' style={{ fontSize: '1rem' }}>
                      BALANCE GENERAL {convertDateToLatinFormat(info.dateUntil)}
                    </p>
                  </h5>
                </div>
                {storage.reportsReducer.dateReport2}
                <div className={tableStyles.rwoReport} style={{ marginTop: '20px' }}>
                  <Col xs={2}>
                    <label
                      style={{
                        fontWeight: '700',
                        fontSize: '12px',
                        color: '#005DBF',
                      }}
                    >
                      Descripción
                    </label>
                  </Col>

                  <Col xs={2} className='text-end'>
                    <label
                      style={{
                        fontWeight: '700',
                        fontSize: '12px',
                        color: '#005DBF',
                      }}
                    >
                      Saldo {info.dateUntil ? convertDateToLatinFormat(info.dateUntil) : ''}
                    </label>
                  </Col>
                  <Col xs={2} className='text-end'>
                    <label
                      style={{
                        fontWeight: '700',
                        fontSize: '12px',
                        color: '#005DBF',
                      }}
                    >
                      {info.dateComparative
                        ? `Saldo ${convertDateToLatinFormat(info.dateComparative)}`
                        : ''}
                    </label>
                  </Col>
                  <Col
                    xs={2}
                    className='d-flex justify-content-end align-items-center text-primary'
                  >
                    {info.dateComparative && (
                      <>
                        <span>$</span>
                      </>
                    )}
                  </Col>
                  <Col
                    xs={2}
                    className='d-flex justify-content-end align-items-center text-primary'
                  >
                    {info.dateComparative && (
                      <>
                        <span>%</span>
                      </>
                    )}
                  </Col>
                </div>

                {/*-------MAPEO 1---- */}
                {storage.reportsReducer.getGeneralBalance?.map((x, realindex) => (
                  <div className={tableStyles.rwoReport} key={'table' + realindex}>
                    <div className='col-2'>
                      <label
                        style={{
                          fontWeight: '700',
                          fontSize: '12px',
                          color: '#58595B',
                        }}
                      >
                        {x.name ? x.name : ''}
                      </label>
                    </div>

                    <div className='col-2 text-end'>
                      <label
                        style={{
                          fontWeight: '700',
                          fontSize: '12px',
                        }}
                      >
                        {formatNumberCurrency(x.balance)}
                      </label>
                    </div>
                    <div className='col-2 text-end'>
                      <label
                        style={{
                          fontWeight: '700',
                          fontSize: '12px',
                        }}
                      >
                        {x.balance2 ? formatNumberCurrency(x.balance2) : ''}
                      </label>
                    </div>
                    <div className='col-2 text-end'>
                      <label
                        style={{
                          fontWeight: '700',
                          fontSize: '12px',
                        }}
                      >
                        {x.balance2
                          ? convertMoneyFormat(parseFloat(x.balance) - parseFloat(x.balance2))
                          : ''}
                      </label>
                    </div>
                    <div className='col-2 text-end'>
                      <label
                        style={{
                          fontWeight: '700',
                          fontSize: '12px',
                        }}
                      >
                        {x.balance2
                          ? `${String((parseFloat(x.balance2) * 100) / parseFloat(x.balance)).slice(
                              0,
                              6,
                            )}%`
                          : ''}
                      </label>
                    </div>

                    {x.detail.map((y, key) => (
                      <div key={key} className={tableStyles.rwoReport}>
                        <div className='col-2 whiteGray'>
                          <label
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            {y.name ? y.name : ''}
                          </label>
                        </div>

                        <div className='col-2 text-end whiteGray'>
                          <label
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            {formatNumberCurrency(y.balance)}
                          </label>
                        </div>
                        <div className='col-2 text-end whiteGray'>
                          <label style={{ fontSize: '12px' }}>
                            {y.balance2 ? formatNumberCurrency(y.balance) : ''}
                          </label>
                        </div>
                        <div className='col-2 text-end whiteGray'>
                          <label style={{ fontSize: '12px' }}>
                            {y.balance2
                              ? convertMoneyFormat(parseFloat(y.balance) - parseFloat(y.balance2))
                              : ''}
                          </label>
                        </div>
                        <div className='col-2 text-end whiteGray'>
                          <label style={{ fontSize: '12px' }}>
                            {y.balance2
                              ? `${String(
                                  (parseFloat(y.balance2) * 100) / parseFloat(y.balance),
                                ).slice(0, 6)}%`
                              : ''}
                          </label>
                        </div>

                        {y.detail.map((z, key) => (
                          <div key={key} className={tableStyles.rwoReport}>
                            <div className='col-2'>
                              <label
                                className='whiteGray'
                                style={{
                                  fontWeight: '400',
                                  fontSize: '12px',
                                }}
                              >
                                {z.name ? z.name : ''}
                              </label>
                            </div>
                            <div className='col-2 text-end whiteGray'>
                              <label style={{ fontSize: '12px' }}>
                                {formatNumberCurrency(z.balance)}
                              </label>
                            </div>
                            <div className='col-2 text-end whiteGray'>
                              <label style={{ fontSize: '12px' }}>
                                {z.balance2 ? formatNumberCurrency(z.balance2) : ''}
                              </label>
                            </div>
                            <div className='col-2 text-end whiteGray'>
                              <label style={{ fontSize: '12px' }}>
                                {z.balance2
                                  ? convertMoneyFormat(
                                      parseFloat(z.balance) - parseFloat(z.balance2),
                                    )
                                  : ''}
                              </label>
                            </div>
                            <div className='col-2 text-end whiteGray'>
                              <label style={{ fontSize: '12px' }}>
                                {z.balance2
                                  ? `${String(
                                      (parseFloat(z.balance2) * 100) / parseFloat(z.balance),
                                    ).slice(0, 6)}%`
                                  : ''}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}

                {storage.reportsReducer.pagination2 ? (
                  <Col xs={12} style={{ textAlign: 'end' }}>
                    <label
                      style={{
                        fontWeight: '400',
                        fontSize: '12px',
                        color: '#58595B',
                      }}
                    >
                      {Math.ceil(storage.reportsReducer?.getGeneralBalance_total / info.perpage)
                        ? info.page +
                          '/' +
                          Math.ceil(storage.reportsReducer?.getGeneralBalance_total / info.perpage)
                        : ''}
                    </label>
                  </Col>
                ) : (
                  <span></span>
                )}
                <div className='d-flex justify-content-center my-3'>
                  <p className='darkGray text-center'>Vigilado Super Salud</p>
                </div>
              </div>

              {/* ----- pagination #️⃣ ----- */}
              {info.pagination && (
                <div className={IndividualStyles.bottom} style={{ marginTop: '14px' }}>
                  <div className={`${paginationStyles.wrapper} darkGray`}>
                    <p className={paginationStyles.paginationText}>
                      Pag. {storage.reportsReducer.getGeneralBalance_total ? info.page : ''}
                      {' de '}
                      {Math.ceil(storage.reportsReducer?.getGeneralBalance_total / info.perpage)
                        ? Math.ceil(storage.reportsReducer.getGeneralBalance_total / info.perpage)
                        : ''}
                    </p>
                    <Pagination
                      activePage={info.page}
                      itemsCountPerPage={10}
                      totalItemsCount={storage.reportsReducer.getGeneralBalance_total}
                      pageRangeDisplayed={5}
                      onChange={e => setInfo({ ...info, page: e })}
                      itemClassPrev={paginationStyles.itemClassPrev}
                      itemClassNext={paginationStyles.itemClassNext}
                      itemClassFirst={paginationStyles.itemClassFirst}
                      itemClassLast={paginationStyles.itemClassLast}
                      itemClass={paginationStyles.itemClass}
                    />
                  </div>
                </div>
              )}

              {/*----------------------------END SECOND SECTION---------------------------------- */}
            </div>
          ) : (
            <span></span>
          )}
        </div>
      </div>
      <ModalNew
        title='Informe de balance general'
        show={showPdf}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer downloadable file={`data:application/pdf;base64,${base64}`}></PdfViewer>
      </ModalNew>
    </>
  );
}
export default GeneralBalance;
