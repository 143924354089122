import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod } from '../../Hooks';
import {
  generateCaption,
  getListCompanies,
  getListContracts,
  getOneListCompanies,
  getOneListContract,
} from '../../actions/billingActions';
import { SupportVisualizer } from '../../components/AreaManagement/SupportVisualizer';
import GenericTable from '../../components/Layouts/GenericTableNew';
import ModalNew from '../../components/Layouts/ModalNew';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import {
  customSelectNewDark,
  customSelectNewDarkMultiAdmin,
} from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import {
  customSwaltAlert,
  isEmptyOrUndefined,
  optionsSelect,
  today,
  valueSelect,
} from '../../helpers';
import { formatNumberCurrency } from '../../helpers/numberFormatting';
import { Title } from '../../shared';

function CapitationInvoice() {
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [triggerSelect, setTriggerSelect] = useState(0);
  const [showPdf, setShowPdf] = useState(false);
  const [file, setFile] = useState('');
  const [trigger, setTrigger] = useState(0);
  const [paginatedResults, setPaginatedResults] = useState([]);
  const [valueInvoice, setValueInvoice] = useState(0);

  const initialInfo = {
    client: '',
    contract: '',
    due_date: today(),
    observations: '',
    eaccount: storage.loginReducer.currentAccount.id,
    site: [],
    user: storage.loginReducer.user_data.id,
    file64: '',
    fileName: '',
    copayment: 0,
    type_doc: '',
    contractType: 1,
    ungroup: true,
    start_date: '',
    end_date: '',
    resolution_id: undefined,
    page: 1,
    perPage: 10,
    appointmentsIds: [],
    address: '',
    trm: 0,
  };

  const [info, setInfo] = useState(initialInfo);

  const { trigger: getResSites, results: resultResSites, load: resolutionsLoad } = useGetMethod();
  const {
    trigger: getTotalValue,
    results: resultTotalValue,
    load: totalValueLoad,
  } = useGetMethod();
  const {
    trigger: getAppointments,
    results: listAppointments,
    load: appointmentsLoad,
  } = useGetMethod();

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const handleCheckboxChange = (id, amountCompany) => {
    setInfo(state => {
      const updatedCheckedIds = state.appointmentsIds ? [...state.appointmentsIds] : [];
      const index = updatedCheckedIds?.indexOf(id);
      if (index !== -1) {
        updatedCheckedIds?.splice(index, 1);
        setValueInvoice(valueInvoice - amountCompany);
      } else {
        updatedCheckedIds?.push(id);
        setValueInvoice(valueInvoice + amountCompany);
      }
      return { ...state, appointmentsIds: updatedCheckedIds };
    });
  };

  const handleCheckboxChangeAll = data => {
    setInfo(state => {
      const allIds = data?.map(row => row.app_id);
      const allSelected = allIds?.every(id => state?.appointmentsIds?.includes(id));
      let updatedIds;
      if (allSelected) {
        updatedIds = state?.appointmentsIds?.filter(id => !allIds.includes(id)) || [];
        setValueInvoice(0);
      } else {
        updatedIds = Array.from(new Set([...(state?.appointmentsIds || []), ...allIds]));
        setValueInvoice(resultTotalValue.results);
      }
      return { ...state, appointmentsIds: updatedIds };
    });
  };
  const isSelectedAllRow = data => {
    const allIds = data?.map(row => row.app_id);
    return allIds?.every(id => info?.appointmentsIds?.includes(id));
  };

  useEffect(() => {
    getResSites({
      url: '/medical/admissions/sites/',
      objFilters: {
        eaccount: info.eaccount,
        userId: info.user,
      },
      token: storage.loginReducer.Authorization,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (info.site && info.client && info.contract && info.end_date && info.start_date) {
      getTotalValue({
        url: '/invoicing/invoice/getTotalValueAppointments/',
        objFilters: {
          site_id: info.site.map(site => site.value).join(','),
          contract_id: info.contract,
          client_id: info.client,
          start_date: info.start_date,
          end_date: info.end_date,
          eaccount: info.eaccount,
        },
        token: storage.loginReducer.Authorization,
        doAfterSuccess: result => {
          if (result.success) {
            setValueInvoice(result.results);
            if (result.results.length === 0) {
              return customSwaltAlert({
                showCancelButton: false,
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: result.message,
                confirmButtonText: 'Aceptar',
              });
            }
          }
        },
      });
    }
  }, [
    getTotalValue,
    info.client,
    info.contract,
    info.end_date,
    info.site,
    info.eaccount,
    info.start_date,
    storage.loginReducer.Authorization,
  ]);

  useEffect(() => {
    dispatch(getListContracts(info));
    dispatch(getOneListCompanies(info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.client]);

  useEffect(() => {
    if (info.contract) {
      dispatch(getOneListContract(info));
    }
    dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.contract]);

  useEffect(() => {
    if (info.site.length && info.client && info.contract && info.end_date && info.start_date) {
      getAppointments({
        url: '/invoicing/invoice/appointmentForInvoicing/',
        objFilters: {
          siteId: info.site.map(site => site.value).join(','),
          contractCorpClientId: info.contract,
          clientId: info.client,
          startDate: info.start_date,
          endDate: info.end_date,
          eaccount: info.eaccount,
        },
        token: storage.loginReducer.Authorization,
        doAfterSuccess: result => {
          if (result.success) {
            if (result.results.length === 0) {
              return customSwaltAlert({
                showCancelButton: false,
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: result.message,
                confirmButtonText: 'Aceptar',
              });
            }
            const appointmentsIds = result?.results?.map(result => result.app_id);
            setInfo(state => ({ ...state, appointmentsIds }));
          }
        },
        doAfterException: () => {
          setInfo(state => ({ ...state, appointmentsIds: [] }));
          setPaginatedResults([]);
        },
        doAfterExceptionResults: true,
      });
    }
  }, [
    getAppointments,
    info.client,
    info.contract,
    info.end_date,
    info.site,
    info.eaccount,
    info.start_date,
    storage.loginReducer.Authorization,
  ]);

  useEffect(() => {
    if (
      listAppointments.results &&
      listAppointments.results.length > 0 &&
      info.site.length &&
      info.client &&
      info.contract &&
      info.end_date &&
      info.start_date
    ) {
      const start = (info.page - 1) * info.perPage;
      const end = start + info.perPage;
      const paginated = listAppointments?.results?.slice(start, end) || [];
      setPaginatedResults(paginated);
    } else {
      setPaginatedResults([]);
    }
  }, [
    listAppointments,
    info.page,
    info.perPage,
    info.site.length,
    info.client,
    info.contract,
    info.end_date,
    info.start_date,
  ]);

  let optionsCompanies = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.companies)) {
    storage.billingReducer.companies.forEach(item => {
      optionsCompanies.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }
  let optionsContracts = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.contracts)) {
    storage.billingReducer.contracts.forEach(item => {
      optionsContracts.push({
        value: item.id,
        label: item.contract_name,
        key: item.id + 'contracts',
      });
    });
  }

  const selectedContract = storage.billingReducer?.contracts?.find(
    option => option.id === info.contract,
  );

  let optionsSites = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.sites)) {
    storage.billingReducer.sites.forEach(item => {
      optionsSites.push({
        value: item.id,
        label: item.description,
        key: item.id + 'sites',
      });
    });
  }
  const buttonGenerate = () => {
    dispatch(
      generateCaption(
        {
          client_id: info.client,
          contract_id: info.contract,
          site_id: info.site.map(site => site.value).join(','),
          resolution_id: info.resolution_id,
          observations: info.observations,
          user_id: info.user,
          start_date: info.start_date,
          end_date: info.end_date,
          due_date: info.due_date,
          eaccount: info.eaccount,
          discount: info.copayment,
          appIds: info.appointmentsIds,
          ...(selectedContract.trm ? { trm: info.trm } : {}),
        },
        (jsonResponse, isConfirmed) => {
          if (jsonResponse && jsonResponse.success) {
            if (isConfirmed) {
              setFile(jsonResponse.results.url);
              setShowPdf(true);
            }
          } else {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'error',
              title: 'Error al generar factura',
              text: jsonResponse?.message || 'Ocurrió un error al generar la factura',
              confirmButtonText: 'Aceptar',
            });
            return;
          }

          setInfo(initialInfo);
          setPaginatedResults([]);
          setValueInvoice(0);
          setTriggerSelect(triggerSelect + 1);
        },
      ),
    );
  };

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <input
                style={{ cursor: 'pointer' }}
                type='checkbox'
                checked={info?.appointmentsIds.includes(item?.app_id)}
                onChange={() =>
                  handleCheckboxChange(item?.app_id, Number(item?.companyAmount ?? 0))
                }
              />
            </td>
            <td className='text-center px-3'>{item?.app_id}</td>
            <td className='text-start px-2'>
              {moment(item?.attentionDate).format('DD/MM/YYYY') || '-'}
            </td>
            <td className='text-center px-2' title={item?.authorizationNumber}>
              {item?.authorizationNumber || '-'}
            </td>
            <td className='text-center px-2'>{item?.patientName}</td>
            <td className='text-center px-3'>{item.serviceName}</td>
            <td className='text-start'>
              {formatNumberCurrency(
                Number(item?.totalServiceValue ?? 0),
                item?.currency?.shortName,
              )}
            </td>
            <td className='text-start'>
              {formatNumberCurrency(Number(item?.cluAmount ?? 0), item?.currency?.shortName)}
            </td>
            <td className='text-start'>
              {formatNumberCurrency(
                Number(item?.companyAmount ?? 0),
                item?.currency?.shortName,
              )}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const header3 = [
    <th key={1}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: 30,
          marginBottom: 3,
        }}
      >
        <input
          style={{ cursor: 'pointer' }}
          type='checkbox'
          checked={isSelectedAllRow(listAppointments?.results || [])}
          onChange={() => handleCheckboxChangeAll(listAppointments?.results || [])}
        />
      </div>
    </th>,
    <th key={2} className='text-center'>
      No. Cita/Cirugía
    </th>,
    <th key={3} className='text-start'>
      Fecha de atención
    </th>,
    <th key={4} className='text-center'>
      No. de autorización
    </th>,
    <th key={5} className='text-center'>
      Paciente
    </th>,
    <th key={6} className='text-center'>
      Servicio
    </th>,
    <th key={7} className='text-start'>
      Valor servicio
    </th>,
    <th key={8} className='text-start'>
      Valor paciente
    </th>,
    <th key={9} className='text-start'>
      Valor empresa
    </th>,
  ];

  return (
    <>
      <div className={` ml-5 container-fluid`} style={{ marginRight: '3rem', paddingTop: '30px' }}>
        {(storage.billingReducer.loading_generate_caption ||
          resolutionsLoad ||
          totalValueLoad ||
          appointmentsLoad) && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className='div justify-content-between'>
          <Title title='Generación de facturas' backIconNone className={'mb-2'} />
        </div>

        <div>
          <div className='d-flex gap-3 align-items-end'>
            <div className={tableStyles.containerSelect} style={{ width: '5rem' }}>
              &nbsp;<label className={tableStyles.stylesLabel}>Factura N°</label>
              <input
                className={IndividualStyles.registerInputs}
                type='text'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                readOnly
              />
            </div>

            <div className='div gap-3 justify-content-end'>
              <div className={tableStyles.containerSelect}>
                &nbsp;<label className={tableStyles.stylesLabel}>Fecha de expedición</label>
                <input
                  className={`${IndividualStyles.registerInputs} register-inputs`}
                  style={{ background: '#F5F7FA', color: '#005DBF', fontWeight: 'bold' }}
                  type='date'
                  value={new Date().toISOString().substr(0, 10)}
                  readOnly
                />
              </div>
              <div className={tableStyles.containerSelect}>
                &nbsp;
                <label className={tableStyles.stylesLabel}>
                  Fecha de vencimiento<span className='text-danger'>*</span>{' '}
                </label>
                <input
                  className={`${IndividualStyles.registerInputs} register-inputs`}
                  style={{ color: '#005DBF', fontWeight: 'bold' }}
                  type='date'
                  value={info.due_date}
                  onChange={e =>
                    setInfo(state => ({
                      ...state,
                      due_date: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className='row align-items-end'>
            <div className='col-lg-2'>
              &nbsp;
              <label className={tableStyles.stylesLabel}>
                Empresa<span className='text-danger'>*</span>{' '}
              </label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'client' + triggerSelect}
                placeholder='Seleccionar...'
                options={optionsCompanies}
                onChange={e => {
                  setInfo({
                    ...info,
                    client: e.value,
                    contract: undefined,
                    address: '',
                    site: [],
                    resolution_id: undefined,
                    trm: 0,
                  });
                  setValueInvoice(0);
                }}
                styles={customSelectNewDark}
              />
            </div>

            <div className='col-lg-2'>
              &nbsp;
              <label className={tableStyles.stylesLabel}>
                Contrato<span className='text-danger'>*</span>{' '}
              </label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'contract' + triggerSelect}
                placeholder='Seleccionar...'
                options={optionsContracts}
                value={valueSelect({
                  list: optionsContracts,
                  findId: 'value',
                  findLabel: 'label',
                  value: info.contract,
                })}
                onChange={e =>
                  setInfo({
                    ...info,
                    contract: e.value,
                    trm: 0,
                  })
                }
                styles={customSelectNewDark}
              />
            </div>

            <div className='col-lg-2'>
              &nbsp;<label className={tableStyles.stylesLabel}>Dirección</label>
              <input
                className={IndividualStyles.registerInputs}
                type='text'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                placeholder='Seleccionar...'
                defaultValue={storage.billingReducer.one_company?.address}
                readOnly
              />
            </div>

            <div className='col-lg-3'>
              &nbsp;
              <label className={tableStyles.stylesLabel}>
                Sede<span className='text-danger'>*</span>{' '}
              </label>
              <Select
                isMulti
                noOptionsMessage={() => 'No hay datos'}
                key={'site' + triggerSelect}
                options={optionsSelect({
                  list: resultResSites?.results?.sites,
                  label: 'siteName',
                  value: 'siteId',
                })}
                placeholder='Seleccionar...'
                onChange={e => setInfo({ ...info, site: e })}
                styles={customSelectNewDarkMultiAdmin}
                value={info.site}
              />
            </div>

            <div className='col-lg-3'>
              &nbsp;
              <label className={tableStyles.stylesLabel}>
                Resolución<span className='text-danger'>*</span>{' '}
              </label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'site' + triggerSelect}
                options={optionsSelect({
                  list: resultResSites?.results?.sites?.find(el =>
                    info.site.map(site => site.value).includes(el.siteId),
                  )?.resolutions,
                  value: 'id',
                  label: 'name',
                })}
                placeholder='Seleccionar...'
                onChange={e =>
                  setInfo({
                    ...info,
                    resolution_id: e.value,
                  })
                }
                styles={customSelectNewDark}
                value={valueSelect({
                  list: resultResSites?.results?.sites?.find(el =>
                    info.site.map(site => site.value).includes(el.siteId),
                  )?.resolutions,
                  findId: 'id',
                  findLabel: 'name',
                  value: info.resolution_id,
                })}
              />
            </div>
          </div>

          <div className='row align-items-end'>
            <div className='col-lg-3'>
              &nbsp;
              <label className={tableStyles.stylesLabel}>
                Fecha desde<span className='text-danger'>*</span>{' '}
              </label>
              <input
                className={`${IndividualStyles.registerInputs} register-inputs`}
                style={{ color: '#005DBF', fontWeight: 'bold' }}
                type='date'
                value={info.start_date}
                onChange={e =>
                  setInfo(state => ({
                    ...state,
                    start_date: e.target.value,
                  }))
                }
              />
            </div>

            <div className='col-lg-3'>
              &nbsp;
              <label className={tableStyles.stylesLabel}>
                Fecha hasta<span className='text-danger'>*</span>{' '}
              </label>
              <input
                className={`${IndividualStyles.registerInputs} register-inputs`}
                style={{ color: '#005DBF', fontWeight: 'bold' }}
                type='date'
                value={info.end_date}
                onChange={e =>
                  setInfo(state => ({
                    ...state,
                    end_date: e.target.value,
                  }))
                }
              />
            </div>
            {selectedContract?.trm ? (
              <div className='col-lg-2'>
                &nbsp;<label className={tableStyles.stylesLabel}>TRM</label>
                <NumberFormat
                  allowNegative={false}
                  style={{ padding: 0, color: '#58595B' }}
                  className={IndividualStyles.registerInputs}
                  name='valueNote'
                  thousandsGroupStyle='thousand'
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'$'}
                  value={info.trm}
                  onValueChange={values => {
                    const { value } = values;
                    setInfo({
                      ...info,
                      trm: Number(value),
                    });
                  }}
                  min={1}
                ></NumberFormat>
              </div>
            ) : null}

            <div className='col-lg-2'>
              &nbsp;<label className={tableStyles.stylesLabel}>Valor</label>
              <input
                className={IndividualStyles.registerInputs}
                type='text'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                value={
                  !isEmptyOrUndefined(valueInvoice)
                    ? '$' +
                      numberWithCommas(
                        info.trm && selectedContract?.trm ? info.trm * valueInvoice : valueInvoice,
                      )
                    : '$'
                }
                readOnly
              />
            </div>

            <div className='col-lg-2'>
              &nbsp;<label className={tableStyles.stylesLabel}>Descuento</label>
              <NumberFormat
                allowNegative={false}
                style={{ padding: 0, color: '#58595B' }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                thousandsGroupStyle='thousand'
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'$'}
                value={info.copayment}
                isAllowed={e => {
                  const { value } = e;
                  return value <= (valueInvoice || 0);
                }}
                onValueChange={values => {
                  const { value } = values;
                  setInfo({
                    ...info,
                    copayment: value,
                  });
                }}
                min={1}
              ></NumberFormat>
            </div>

            <div className='col-lg-2'>
              &nbsp;<label className={tableStyles.stylesLabel}>Valor a pagar</label>
              <input
                className={IndividualStyles.registerInputs}
                type='text'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                value={
                  !isEmptyOrUndefined(valueInvoice) && !!(info.copayment >= 0)
                    ? '$' + numberWithCommas(Number(valueInvoice) - Number(info.copayment))
                    : '$'
                }
                readOnly
              />
            </div>
          </div>
          {paginatedResults.length > 0 && (
            <div style={{ position: 'relative' }}>
              <GenericTable headers={header3} dark={true}>
                {formatData(paginatedResults)}
              </GenericTable>
              <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}>
                  Pag. {listAppointments?.row_count ? info.page : ''}
                  {' de '}
                  {Math.ceil(listAppointments?.row_count / info.perPage)
                    ? Math.ceil(listAppointments?.row_count / info.perPage)
                    : ''}{' '}
                  ({listAppointments?.row_count} encontrados)
                </p>
                <Pagination
                  activePage={info.page}
                  itemsCountPerPage={info.perPage}
                  totalItemsCount={listAppointments?.row_count}
                  pageRangeDisplayed={5}
                  onChange={val => {
                    setInfo({ ...info, page: val });
                  }}
                  itemClassPrev={`${paginationStyles.itemClassPrev} `}
                  itemClassNext={`${paginationStyles.itemClassNext} `}
                  itemClassFirst={`${paginationStyles.itemClassFirst} `}
                  itemClassLast={`${paginationStyles.itemClassLast} `}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </div>
          )}

          <div>
            <div className='mt-4'>
              &nbsp;
              <label className={tableStyles.stylesLabel}>
                Observaciones<span className='text-danger'>*</span>{' '}
              </label>
              <textarea
                className={IndividualStyles.textArea_new3}
                rows='6'
                cols=''
                value={info.observations}
                placeholder='Escribir...'
                onChange={e =>
                  setInfo({
                    ...info,
                    observations: e.target.value,
                  })
                }
              ></textarea>
            </div>
            <div className={IndividualStyles.bottomRow}>
              <Button
                className={`${tableStyles.buttonExtra3} d-block`}
                style={{ padding: '8px', border: '0px' }}
                onClick={() => {
                  buttonGenerate();
                  setTrigger(trigger + 1);
                }}
              >
                Generar factura
              </Button>
              <ModalNew
                title='Detalle de factura'
                show={showPdf && file}
                btnNoName={'Cancelar'}
                size='700'
                btnYesDisabled={false}
                onHide={() => setShowPdf(false)}
                btnNoEvent={() => setShowPdf(false)}
                btnNoDisabled={false}
              >
                <SupportVisualizer file={file} />
              </ModalNew>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CapitationInvoice;
