import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AddCircleOutline, Close } from '@material-ui/icons';
import Pagination from 'react-js-pagination';
import ReactSelect from 'react-select';
import NumberFormat from 'react-number-format';
//
import ThreeDots from '../../../assets/img/icons/threeDots.svg';
//
import GenericTableNew from '../../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { customSwaltAlert, loader } from '../../../helpers';
//
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../../components/Layouts/react-select-custom';
import modalStyles from '../../../components/Layouts/modalnew.module.scss';
import { getLocaleSeparators } from '../../../helpers/numberFormatting';

const statusList = [
  { label: 'Habilitado', value: 1 },
  { label: 'Deshabilitado', value: 0 },
];

const localeSeparators = getLocaleSeparators();

export default function PortfolioProvision() {
  const store = useSelector(state => state);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    range: '',
    status: null,
  });
  const [dataModal, setDataModal] = useState({
    isOpen: false,
    payload: {
      eaccount: store.loginReducer.currentAccount.id,
      created_by: store.loginReducer.user_data.id,
    },
  });
  const [rangeList, setRangeList] = useState([]);

  const { trigger: getPortfolioProvisionSelect } = useGetMethod();
  const {
    trigger: getPortfolioProvision,
    results: portfolioProvision,
    load: portfolioProvisionLoader,
  } = useGetMethod();

  const { trigger: createInterestPayment, load: createLoader } = usePostMethod();
  const { trigger: updateInterestPayment, load: updateLoader } = usePostMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      const result = await getPortfolioProvisionSelect({
        url: '/receivable/portfolioProvision/',
        objFilters: {
          page: 1,
          perpage: 10,
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
      if (result.results) {
        setRangeList(
          result.results.map(item => ({
            value: item.name,
            label: item.name,
          })),
        );
      }
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [
    filters,
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
    getPortfolioProvisionSelect,
  ]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getPortfolioProvision({
        url: '/receivable/portfolioProvision/',
        objFilters: {
          ...filters,
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [
    filters,
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
    getPortfolioProvision,
  ]);

  const onCloseDuplicateModal = () => {
    setDataModal({
      isOpen: false,
      payload: {
        eaccount: store.loginReducer.currentAccount.id,
        created_by: store.loginReducer.user_data.id,
      },
    });
  };

  const onChangePayload = (value, key) => {
    const opt = {
      ...dataModal,
      payload: {
        ...dataModal?.payload,
        [key]: value,
      },
    };
    setDataModal(opt);
  };

  const onSuccessResponse = async () => {
    await getPortfolioProvision({
      url: '/receivable/portfolioProvision/',
      objFilters: {
        ...filters,
        eaccount: store.loginReducer.currentAccount.id,
      },
      token: store.loginReducer.Authorization,
    });
    const result = await getPortfolioProvisionSelect({
      url: '/receivable/portfolioProvision/',
      objFilters: {
        page: 1,
        perpage: 10,
        eaccount: store.loginReducer.currentAccount.id,
      },
      token: store.loginReducer.Authorization,
    });
    if (result.results) {
      setRangeList(
        result.results.map(item => ({
          value: item.name,
          label: item.name,
        })),
      );
    }
    setDataModal({
      isOpen: false,
      payload: {
        eaccount: store.loginReducer.currentAccount.id,
        created_by: store.loginReducer.user_data.id,
      },
    });
  };

  const onCreateInterest = () => {
    const isValid =
      (dataModal.payload?.start && dataModal.payload?.end && dataModal.payload?.percentage) ||
      (dataModal.payload?.extraDays && dataModal.payload?.percentage);

    if (!isValid) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor, diligencie todos los campos marcados como obligatorios.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    if (dataModal.payload) {
      createInterestPayment({
        token: store.loginReducer.Authorization,
        body: {
          ...dataModal.payload,
          start: dataModal.payload?.start === '' ? 360 : dataModal.payload?.start,
          end: dataModal.payload?.end === '' ? 0 : dataModal.payload?.end,
        },
        method: 'POST',
        url: '/receivable/portfolioProvision/',
        succesAction: results => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'success',
            title: 'Creado exitosamente',
            text: results.message,
            confirmButtonText: 'Aceptar',
          }).then(async response => {
            if (response.isConfirmed) {
              await onSuccessResponse();
            }
          });
        },
        doAfterException: results => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results?.message,
            confirmButtonText: 'Aceptar',
          });
        },
      });
    }
  };

  const onUpdateInterest = () => {
    const isValid =
      (dataModal.payload?.start && dataModal.payload?.end && dataModal.payload?.percentage) ||
      (dataModal.payload?.extraDays && dataModal.payload?.percentage);

    if (!isValid) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor, diligencie todos los campos marcados como obligatorios.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    if (dataModal.payload && dataModal.payload.id) {
      updateInterestPayment({
        token: store.loginReducer.Authorization,
        body: {
          ...dataModal.payload,
          start: dataModal.payload?.start === '' ? 360 : dataModal.payload?.start,
          end: dataModal.payload?.end === '' ? 0 : dataModal.payload?.end,
        },
        method: 'PUT',
        url: '/receivable/portfolioProvision/',
        succesAction: results => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'success',
            title: 'Actualizado exitosamente',
            text: results.message,
            confirmButtonText: 'Aceptar',
          }).then(async response => {
            if (response.isConfirmed) {
              await onSuccessResponse();
            }
          });
        },
        doAfterException: results => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results?.message,
            confirmButtonText: 'Aceptar',
          });
        },
      });
    }
  };

  const onChangeStatus = item => {
    updateInterestPayment({
      token: store.loginReducer.Authorization,
      body: {
        ...dataModal.payload,
        id: item.id,
        status: item.status === '1' ? '0' : '1',
      },
      method: 'PUT',
      url: '/receivable/portfolioProvision/',
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            await getPortfolioProvision({
              url: '/receivable/portfolioProvision/',
              objFilters: {
                ...filters,
                eaccount: store.loginReducer.currentAccount.id,
              },
              token: store.loginReducer.Authorization,
            });
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const renderModal = () => {
    return (
      <Modal
        show={dataModal.isOpen}
        centered
        size={700}
        className={`${modalStyles.container}`}
        onHide={onCloseDuplicateModal}
      >
        <div className='d-flex align-items-center justify-content-between py-3 px-4'>
          <div></div>
          <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
            {dataModal.payload?.id ? 'Editar' : 'Crear'} provisión general
          </h3>
          <Close className='pointer text-secondary' onClick={onCloseDuplicateModal} />
        </div>
        <hr className='m-0' />
        <div className='py-3 px-4'>
          <div className='p-3 d-flex flex-column'>
            <div className='row align-items-end'>
              <div className='col'>
                <Form.Group>
                  <small>
                    <span className='text-primary ms-1'>Rango mínimo edad</span>
                    <span className='text-danger'>*</span>
                  </small>
                  <NumberFormat
                    className='register-inputs'
                    value={dataModal.payload?.start}
                    onValueChange={values => {
                      const { value } = values;
                      onChangePayload(value, 'start');
                    }}
                    disabled={dataModal.payload?.extraDays}
                    placeholder='Escribir...'
                    allowNegative={false}
                    decimalScale={0}
                    thousandSeparator={localeSeparators.groupSeparator}
                    decimalSeparator={localeSeparators.decimalSeparator}
                    isAllowed={({ floatValue }) =>
                      floatValue === undefined || (floatValue > 0 && floatValue < 360)
                    }
                  />
                </Form.Group>
              </div>
              <div className='col'>
                <Form.Group>
                  <small>
                    <span className='text-primary ms-1'>Rango máximo edad</span>
                    <span className='text-danger'>*</span>
                  </small>
                  <NumberFormat
                    className='register-inputs'
                    value={dataModal.payload?.end}
                    onValueChange={values => {
                      const { value } = values;
                      onChangePayload(value, 'end');
                    }}
                    disabled={dataModal.payload?.extraDays}
                    placeholder='Escribir...'
                    allowNegative={false}
                    decimalScale={0}
                    thousandSeparator={localeSeparators.groupSeparator}
                    decimalSeparator={localeSeparators.decimalSeparator}
                    isAllowed={({ floatValue }) => floatValue === undefined || floatValue < 360}
                  />
                </Form.Group>
              </div>
              <div className='col'>
                <Form.Group>
                  <small>
                    <span className='text-primary ms-1'>Porcentaje provisión</span>
                    <span className='text-danger'>*</span>
                  </small>
                  <NumberFormat
                    className='register-inputs'
                    placeholder='Escribir...'
                    allowNegative={false}
                    thousandSeparator={localeSeparators.groupSeparator}
                    decimalSeparator={localeSeparators.decimalSeparator}
                    decimalScale={2}
                    fixedDecimalScale
                    suffix="%"
                    isNumericString
                    value={String(dataModal.payload?.percentage ?? '')}
                    onValueChange={({ value }) => onChangePayload(value, 'percentage')}
                    isAllowed={({ value }) => value <= 100}
                  />
                </Form.Group>
              </div>
            </div>
            <Form.Check
              className='mt-3'
              label={<small className='text-muted'>Mayor a 630 días</small>}
              checked={dataModal.payload?.extraDays}
              style={{ marginTop: 20  }}
              onChange={({ target }) =>
                setDataModal({
                  ...dataModal,
                  payload: {
                    ...dataModal.payload,
                    extraDays: target.checked,
                    start: '',
                    end: '',
                  },
                })
              }
            />
            <div className='d-flex justify-content-center align-items-center mt-4'>
              <button
                onClick={onCloseDuplicateModal}
                className={`${tableStyles.btnSecondary} me-3`}
              >
                Cancelar
              </button>
              <button
                className={`${tableStyles.btnPrimaryAlt}`}
                onClick={() => (dataModal.payload?.id ? onUpdateInterest() : onCreateInterest())}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderFilters = () => {
    return (
      <div className='d-flex align-items-end'>
        <Form.Group className='me-3' style={{ width: '20%' }}>
          <small className='text-primary ms-1'>Estado</small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            options={[{ value: null, label: 'Seleccionar...' }, ...statusList]}
            onChange={option =>
              setFilters(prev => ({
                ...prev,
                status: option.value,
                page: 1,
              }))
            }
            styles={customSelectNewDark}
            placeholder='Seleccionar...'
          />
        </Form.Group>
        <Form.Group style={{ width: '20%' }}>
          <small className='text-primary ms-1'>Rango de edad</small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            options={[{ value: null, label: 'Seleccionar...' }, ...rangeList]}
            onChange={option =>
              setFilters(prev => ({
                ...prev,
                range: option.value,
                page: 1,
              }))
            }
            styles={customSelectNewDark}
            placeholder='Seleccionar...'
          />
        </Form.Group>
        <div className='flex-grow-1'></div>
        <div
          className={tableStyles.buttonTextPrimary}
          onClick={() =>
            setDataModal({
              isOpen: true,
              payload: {
                eaccount: store.loginReducer.currentAccount.id,
                created_by: store.loginReducer.user_data.id,
              },
            })
          }
        >
          <small className='mr-2'>Crear provisión</small>
          <AddCircleOutline stroke='2' fontSize='small' />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    const tableHeaders = [
      <th key={1} className='text-start ps-3'>
        Rango de edad
      </th>,
      <th key={2} className='text-center'>
        Porcentaje de provisión
      </th>,
      <th key={3} className='text-center'>
        Estado
      </th>,
      <th key={4} className='text-center'>
        &nbsp;
      </th>,
    ];

    return (
      <GenericTableNew headers={tableHeaders} dark={true}>
        {portfolioProvision?.results?.map(item => (
          <tr key={item.id}>
            <td className='text-start align-middle ps-3'>{item.name}</td>
            <td>{item.percentage}%</td>
            <td>
              <div
                className={`${item.status === '1' ? tableStyles.greenState : tableStyles.redState}`}
              >
                {item.status === '1' ? 'Habilitado' : 'Deshabilitado'}
              </div>
            </td>
            <td className='text-center'>
              <CustomPopupExtend
                showEdit={true}
                showEnable={true}
                position='right'
                triggerSrc={ThreeDots}
                isEnabled={item.status === '1'}
                editClickEvent={() =>
                  setDataModal({
                    isOpen: true,
                    payload: {
                      ...dataModal.payload,
                      id: item.id,
                      percentage: item.percentage,
                      start: Number(item.start_days) === 360 ? '' : item.start_days,
                      end: Number(item.end_days) === 0 ? '' : item.end_days,
                      extraDays: Number(item.start_days) === 360 ? true : false,
                    },
                  })
                }
                enableClickEvent={() => {
                  customSwaltAlert({
                    icon: 'warning',
                    title: '¿Está seguro?',
                    text: `Se ${item.status === '1' ? 'deshabilitará' : 'habilitará'} la provisión: ${item.name}.`,
                    confirmButtonText: 'Si, continuar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                  }).then(result => {
                    if (result.isConfirmed) {
                      onChangeStatus(item);
                    }
                  });
                }}
              />
            </td>
          </tr>
        ))}
      </GenericTableNew>
    );
  };

  const render = () => {
    return (
      <>
        {portfolioProvisionLoader && loader}
        {createLoader && loader}
        {updateLoader && loader}
        <div className={`${tableStyles.tlnContainer}`}>
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}>Provisión de cartera - Fiscal</h1>
            {renderFilters()}
            {renderTable()}
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {filters.page}
                {' de '}
                {Math.ceil(portfolioProvision?.row_count / filters.perpage)
                  ? Math.ceil(portfolioProvision?.row_count / filters.perpage)
                  : '1'}{' '}
                ({portfolioProvision?.row_count} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={portfolioProvision?.row_count}
                pageRangeDisplayed={5}
                onChange={page => setFilters({ ...filters, page: page })}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
        {renderModal()}
      </>
    );
  };

  return render();
}
