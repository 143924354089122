import { connect, useSelector } from 'react-redux';

import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getParamsObject, hasData, urlRedirect } from './utils';

import { getCategories, logOutUser, saveAccount, selectAccount } from '../actions/loginActions';
import { loader } from '../helpers';

function ValidateAuth(props) {
  const history = useHistory();
  const location = useLocation();
  const store = useSelector(state => state);
  const userId = store.loginReducer?.user_data.id;
  const accounts = store?.loginReducer?.accounts;
  const parsed = useMemo(() => {
    return getParamsObject(location.search);
  }, [location.search]);

  const onClearAuth = useCallback(() => {
    window.location.href = urlRedirect + '/login';
  }, []);

  useEffect(() => {
    (async function () {
      if (hasData(parsed.idAccount) && hasData(parsed.idProfile)) {
        if (accounts) {
          const currentAccount = accounts.find(acc => acc.id === Number(parsed.idAccount));
          if (currentAccount) {
            const currentProfile = currentAccount.profile.find(
              prof => prof.id === Number(parsed.idProfile),
            );
            props.saveAccount({ ...currentAccount, profile: currentProfile });
          }
        }

        await props.getCategories(parsed.idProfile);
        history.push('/categoryselect');
      } else {
        props.logoutUser();
        onClearAuth();
      }
    })();
  }, [parsed, onClearAuth, history, props, userId, accounts]);

  return loader;
}

const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
});

const mapDispatchToProps = dispatch => ({
  logoutUser: () => {
    dispatch(logOutUser());
  },
  getAccountsByUser: userId => {
    return dispatch(selectAccount(userId));
  },
  saveAccount: account => {
    dispatch(saveAccount(account));
  },
  getCategories: profileId => {
    return dispatch(getCategories(profileId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateAuth);
