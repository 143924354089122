import { Box } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useGetMethod } from '../../../Hooks/useFetch';
import {
  currency_edit_active,
  currency_insert,
  currency_update,
} from '../../../actions/currencyDenomination';
import lupa from '../../../assets/img/icons/lupa.svg';
import threeDots from '../../../assets/img/icons/threeDots.svg';
import { getEnvs, listDepotType } from '../../../helpers';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';
import { formatNumberCurrency, getLocaleSeparators } from '../../../helpers/numberFormatting';
import GenericTableNew from '../../Layouts/GenericTableNew';
import ModalNew from '../../Layouts/ModalNew';
import paginationStyles from '../../Layouts/pagination.module.scss';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import CustomPopup from '../../Popup/customPopup';
import SelectComponent from '../../SelectComponent/SelectComponent';

const initialStateFilters = {
  search: '',
  active: '',
  page: 1,
  perpage: 10,
};

const { CURRENCY } = getEnvs();

export const Coins = () => {
  const counter = useSelector(state => state);
  const token = counter.loginReducer.Authorization;
  const eaccount = counter.loginReducer.currentAccount.id;
  const userId = counter.loginReducer.user_data.id;

  const localeSeparators = useRef(getLocaleSeparators());

  const myPermission = useCallback(
    () =>
      counter.loginReducer.currentAccount?.profile?.permission?.find(
        x => x.functionality?.prefix === 'CCost',
      ),
    [counter.loginReducer.currentAccount?.profile?.permission],
  );

  const {
    results: listCurrencyDenomination,
    load: loadListCurrencyDenomination,
    trigger: getListCurrencyDenomination,
  } = useGetMethod();

  const {
    results: listCurrency,
    load: loadListCurrency,
    trigger: getListCurrency,
  } = useGetMethod();

  const [putState, setPutState] = useState({
    usage: '',
    id: '',
    cdType: '',
    account: '',
    depoTypeId: '',
    cdValue: '',
    curId: '',
    modalShow: false,
    showFunctionalUnits: false,
    eaccount: counter.loginReducer.currentAccount.id,
    userId,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  //filtros para el get
  const [filters, setFilter] = useState({
    ...initialStateFilters,
    perpage: 10,
  });

  const handleResetPutState = () => {
    setPutState({
      usage: '',
      id: '',
      account: '',
      depoTypeId: '',
      cdValue: '',
      curId: '',
      modalShow: false,
      showFunctionalUnits: false,
      eaccount: counter.loginReducer.currentAccount.id,
      userId,
    });
  };

  function handleSubmit() {
    if (putState?.curId === '') {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione una moneda correcta',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    if (putState?.cdType === '') {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione un tipo de moneda correcta',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    if (putState?.cdValue <= 0) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo valor monetario debe ser mayor a 0',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    if (putState.usage === 'PUT') {
      return dispatch(
        currency_update(putState.id, { ...putState, userId }, () => {
          handleResetPutState();
          setFilter({ ...filters, page: 1 });
          getListCurrencyDenomination({
            url: '/medical/admissions/currencyDenomination/',
            token: token,
            objFilters: {
              ...initialStateFilters,
            },
          });
        }),
      );
    }

    if (putState.usage === 'POST') {
      return dispatch(
        currency_insert({ ...putState, userId }, () => {
          handleResetPutState();
          setFilter({ ...filters, page: 1 });
          getListCurrencyDenomination({
            url: '/medical/admissions/currencyDenomination/',
            token: token,
            objFilters: {
              ...initialStateFilters,
            },
          });
        }),
      );
    }
  }

  const handlePageChange = val => {
    setFilter({ ...filters, page: val });
    getListCurrencyDenomination({
      url: '/medical/admissions/currencyDenomination/',
      token: token,
      objFilters: {
        ...filters,
        perpage: 10,
        page: val,
      },
    });
  };

  const renderHeaders = [
    <th key={`1${Date.now()}`} className='px-2'>
      Moneda
    </th>,
    <th key={`2${Date.now()}`} className='px-2 text-end'>
      Valor monetario
    </th>,
    <th key={`3${Date.now()}`} className='px-2 text-center'>
      Tipo
    </th>,
    <th key={`4${Date.now()}`} className='px-2 text-center'>
      Estado
    </th>,
    <th key={`8${Date.now()}`}>ㅤ</th>,
  ];

  const renderElement = elem => {
    let type = '';
    if (elem?.cdType === 'cash') type = 'Billete';
    if (elem?.cdType === 'coin') type = 'Moneda';
    if (elem?.cdType === 'cripto') type = 'Cripto';

    return (
      <tr key={elem.id}>
        <td className='text-start px-2 text-uppercase'>{elem.curShortName}</td>
        <td className='text-end px-2'>{formatNumberCurrency(elem?.cdValue)}</td>
        <td className='text-center px-2'>{type}</td>
        <td className='text-start px-2 text-center'>
          <div
            className={`${
              elem.cdStatus === 'enabled' ? tableStyles.greenState : tableStyles.redState
            }`}
          >
            {elem.cdStatus === 'enabled' ? 'Habilitado' : 'Inhabilitado'}
          </div>
        </td>

        <td className='col-md-1'>
          <CustomPopup
            triggerSrc={threeDots}
            showEdit={elem.cdStatus === 'enabled' && !!myPermission()?.edit}
            editClickEvent={() =>
              setPutState({
                ...putState,
                cdId: elem?.cdId,
                depoTypeId: 20,
                cdType: elem?.cdType,
                cdValue: elem.cdValue,
                curId: elem.curId,
                modalShow: true,
                usage: 'PUT',
              })
            }
            showEnable={true && !!myPermission()?.delete}
            isEnabled={elem.cdStatus === 'disabled' ? false : true}
            enableClickEvent={() =>
              dispatch(
                currency_edit_active(
                  elem.depoTypeId,
                  {
                    depoTypeId: elem.depoTypeId,
                    cdValue: elem.cdValue,
                    curId: elem.curId,
                    cdStatus: elem.cdStatus === 'disabled' ? 'enabled' : 'disabled',
                    curShortName: elem.curShortName,
                    cdId: elem.cdId,
                    cdType: elem.cdType,
                  },
                  elem.depoTypeName,
                  () => {
                    getListCurrencyDenomination({
                      url: '/medical/admissions/currencyDenomination/',
                      token: token,
                      objFilters: {
                        ...initialStateFilters,
                      },
                    });
                  },
                ),
              )
            }
          />
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (listCurrencyDenomination?.rowTotal !== undefined) {
      const elem2 = listCurrencyDenomination?.results;
      elemMap = elem2.map(elem => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push('/administracion/inicio');
    }

    getListCurrency({
      url: '/medical/generals/currency/',
      token: token,
    });

    getListCurrencyDenomination({
      url: '/medical/admissions/currencyDenomination/',
      token: token,
      objFilters: {
        ...initialStateFilters,
      },
    });
  }, [
    counter.loginReducer.currentAccount.id,
    eaccount,
    getListCurrency,
    getListCurrencyDenomination,
    history,
    myPermission,
    token,
  ]);

  return (
    <div className='mt-4' style={{ width: '700px' }}>
      {(loadListCurrency || loadListCurrencyDenomination) && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={tableStyles.mainRow} style={{ marginBottom: '10px', alignItems: 'center' }}>
        <div>
          <input
            style={{ width: '300px' }}
            className={tableStyles.searchUsersAlt}
            type='text'
            placeholder='Buscar...'
            onChange={event => {
              setFilter({
                ...filters,
                search: event.target.value,
              });
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                getListCurrencyDenomination({
                  url: '/medical/admissions/currencyDenomination/',
                  token: token,
                  objFilters: filters,
                });
              }
            }}
          />

          <button
            onClick={() => {
              getListCurrencyDenomination({
                url: '/medical/admissions/currencyDenomination/',
                token: token,
                objFilters: filters,
              });
            }}
          >
            <img
              src={lupa}
              alt='User icon'
              className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
            />
          </button>
        </div>

        <div className={tableStyles.groupElems}>
          {myPermission()?.create && (
            <div
              className='d-flex justify-content-end groupAddButton align-items-center'
              onClick={() => setPutState({ ...putState, usage: 'POST', modalShow: true })}
            >
              <label htmlFor='newAccident' className='darkGray fw-bold'>
                Nueva moneda
              </label>
              <button className='addCheckButton mx-2' style={{ marginBottom: '1.5px' }} />
            </div>
          )}
        </div>
      </div>

      <ModalNew
        title={
          putState.usage === 'POST'
            ? 'Crear Moneda'
            : putState.usage === 'PUT'
              ? 'Editar Moneda'
              : ''
        }
        show={putState.modalShow}
        onHide={() => handleResetPutState()}
        btnYesEvent={() => handleSubmit()}
        btnYesName='Aceptar'
        size={'620'}
      >
        <Box className='mb-2' display={'grid'} gridTemplateColumns={'150px 150px 1fr'} gap={2}>
          <Box>
            <span className={tableStyles.darkBlueText}>Moneda</span>
            <span className={'text-danger'}>*</span>

            <SelectComponent
              options={
                listCurrency?.results?.length
                  ? [
                      { label: 'Seleccionar...', value: '' },
                      ...listCurrency.results.map(el => ({ label: el?.label, value: el?.id })),
                    ]
                  : []
              }
              value={
                putState?.curId
                  ? {
                      label: listCurrency?.results?.find(el => el?.id === putState?.curId)?.label,
                      value: listCurrency?.results?.find(el => el?.id === putState?.curId)?.id,
                    }
                  : ''
              }
              onChange={({ value }) => {
                setPutState({ ...putState, curId: value });
              }}
            />
          </Box>

          <Box>
            <span className={tableStyles.darkBlueText}>Tipo</span>
            <span className={'text-danger'}>*</span>

            <SelectComponent
              options={listDepotType}
              value={
                putState?.cdType
                  ? {
                      label: listDepotType?.find(el => el?.value === putState?.cdType)?.label,
                      value: listDepotType?.find(el => el?.value === putState?.cdType)?.value,
                    }
                  : ''
              }
              onChange={({ value }) => {
                setPutState({ ...putState, cdType: value });
              }}
            />
          </Box>

          <Box>
            <span className={tableStyles.darkBlueText}>Valor monetario</span>
            <span className={'text-danger'}>*</span>

            <NumberFormat
              allowNegative={false}
              className={'register-inputs'}
              placeholder='Escribir...'
              suffix={` ${CURRENCY}`}
              decimalSeparator={localeSeparators.current.decimalSeparator}
              thousandSeparator={localeSeparators.current.groupSeparator}
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              value={String(putState.cdValue ?? '')}
              onValueChange={({ value }) => {
                setPutState({ ...putState, cdValue: value });
              }}
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 999_999_999_999
              }
            />
          </Box>
        </Box>
      </ModalNew>

      <GenericTableNew
        fontFamilyTable={'fontFamilyTable'}
        style={{ marginTop: '0px' }}
        dark={true}
        headers={renderHeaders}
      >
        {listElem()}
      </GenericTableNew>

      <div className={paginationStyles.wrapper}>
        <p className={paginationStyles.paginationText}>
          Pag. {listCurrencyDenomination?.rowTotal ? filters.page : ''}
          {' de '}
          {Math.ceil(listCurrencyDenomination?.rowTotal / filters.perpage)
            ? Math.ceil(listCurrencyDenomination?.rowTotal / filters.perpage)
            : ''}{' '}
          ({listCurrencyDenomination?.rowTotal} encontrados)
        </p>
        <Pagination
          activePage={filters.page}
          itemsCountPerPage={filters?.perpage}
          totalItemsCount={listCurrencyDenomination?.rowTotal}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
          itemClassPrev={paginationStyles.itemClassPrev}
          itemClassNext={paginationStyles.itemClassNext}
          itemClassFirst={paginationStyles.itemClassFirst}
          itemClassLast={paginationStyles.itemClassLast}
          itemClass={paginationStyles.itemClass}
        />
      </div>
    </div>
  );
};
