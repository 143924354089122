import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import { Col, Row, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { expRegList, isEmptyOrUndefined, validExpReg } from '../../helpers/helpers';
import Loader from 'react-loader-spinner';
import { newParemAgesByWallet, updateParemAgesByWallet } from '../../actions/bankNoteActions';
import NumberFormat from 'react-number-format';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { Title } from '../../shared/Title';
import { getLocaleSeparators } from '../../helpers/numberFormatting';

const localeSeparators = getLocaleSeparators()

export const NewReceivAge = () => {
  const location = useLocation();
  let isEditing = location?.state?.isEditing;
  let paramInfo = location?.state?.paramInfo;
  const store = useSelector(state => state);
  const history = useHistory();
  const idEnterprise = store.loginReducer.currentAccount.id;
  const [isLoading, setIsLoading] = useState(false);
  const token = store.loginReducer.Authorization;
  const [inputs, setInputs] = useState({
    eaccount: idEnterprise,
    name: '',
    start_days: '',
    end_days: '',
    percentage: '',
  });

  useEffect(() => {
    if (isEditing) {
      setInputs({
        eaccount: idEnterprise,
        name: paramInfo?.name,
        start_days: paramInfo?.start_days,
        end_days: paramInfo?.end_days,
        percentage: paramInfo?.deteriorationValue,
        id: paramInfo?.id,
      });
    }
  }, [
    idEnterprise,
    isEditing,
    location,
    paramInfo?.deteriorationValue,
    paramInfo?.end_days,
    paramInfo?.id,
    paramInfo?.name,
    paramInfo?.start_days,
  ]);

  const handleBack = () => {
    history.push('/administracion/TMDeCartera/EdadesPorCobrar');
  };

  const handleSubmit = async () => {
    if (
      isEmptyOrUndefined(inputs.name) ||
      isEmptyOrUndefined(inputs.start_days) ||
      isEmptyOrUndefined(inputs.end_days) ||
      isEmptyOrUndefined(inputs.percentage)
    ) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor llene los campos obligatorios',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    if (inputs.end_days < inputs.start_days) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El día de fin no puede ser menor que el día de inicio de periodo',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    try {
      setIsLoading(true);
      const result = isEditing
        ? await updateParemAgesByWallet(inputs, token)
        : await newParemAgesByWallet(inputs, token);

      if (result.success) {
        setIsLoading(false);
        setInputs({
          eaccount: idEnterprise,
          name: '',
          start_days: '',
          end_days: '',
          percentage: '',
        });
        return customSwaltAlert({
          icon: 'success',
          title: isEditing ? 'Actualizado exitosamente' : 'Creado exitosamente',
          text: `Se ha ${isEditing ? 'actualizado' : 'creado'} el parámetro: ${inputs?.name}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      } else {
        setIsLoading(false);
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: result.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    } catch (error) {
      setIsLoading(false);

      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error.message,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };
  return (
    <>
      {isLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea} style={{ paddingTop: '30px' }}>
          <Title
            title={isEditing ? 'Editar Parámetro' : 'Nuevo Parámetro edades por cartera'}
            onClickIcon={handleBack}
            className={'mb-2'}
          />
          <Row>
            <Col xs={12}>
              {' '}
              <p className={`${tableStyles.crudModalLabel} `}>
                Nombre
                <span className='text-danger'>*</span>
              </p>
              <input
                onChange={e => setInputs({ ...inputs, name: e.target.value })}
                value={inputs.name}
                type='text'
                placeholder='Escribe...'
                className='register-inputs text-primary '
                name='position'
              />
            </Col>

            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Día de inicio de periodo
                <span className={'text-danger'}>*</span>
              </p>
              <input
                onChange={e => {
                  if (validExpReg(e.target.value, expRegList.number) || e.target.value === '') {
                    setInputs({
                      ...inputs,
                      start_days: Number(e.target.value),
                      end_days: Number(e.target.value) + 1,
                    });
                  }
                }}
                value={inputs.start_days}
                // type="number"
                placeholder='Escribe...'
                className='register-inputs text-primary '
                name='position'
              />
            </Col>

            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Día de fin del periodo
                <span className={'text-danger'}>*</span>
              </p>
              <input
                onChange={e => {
                  if (validExpReg(e.target.value, expRegList.number) || e.target.value === '') {
                    setInputs({ ...inputs, end_days: e.target.value });
                  }
                }}
                value={inputs.end_days}
                placeholder='Escribe...'
                className='register-inputs text-primary '
                name='position'
              />
            </Col>

            <Col xs={12}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Porcentaje de deterioro
                <span className={'text-danger'}>*</span>
              </p>
              <NumberFormat
                className={`register-inputs text-secondary`}
                name='percentage'
                allowNegative={false}
                suffix='%'
                max={100}
                thousandSeparator={localeSeparators.groupSeparator}
                decimalSeparator={localeSeparators.decimalSeparator}
                decimalScale={2}
                isNumericString
                value={String(inputs.percentage ?? '')}
                onValueChange={e => {
                  if (e.value > 100) {
                    return setInputs({ ...inputs, percentage: 100 });
                  }

                  setInputs({ ...inputs, percentage: e.value });
                }}
              />
            </Col>

            <Col xs={12} className={`pt-3 customTabs__Footer mt-2`}>
              <Button
                onClick={() => handleSubmit()}
                className={tableStyles.btnPrimary}
                // disabled={!isEmptyOrUndefined(inputs.colaborator) ? false : true}
              >
                {isEditing ? 'Actualizar' : 'Guardar'}
              </Button>

              <Button
                variant='outline-primary'
                onClick={() => handleBack()}
                className={tableStyles.btnSecondary}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
