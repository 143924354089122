import { Tooltip, withStyles } from '@material-ui/core';
import deepcopy from 'deepcopy';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';

import Closed from '../../assets/img/icons/Cerrar.svg';
import Descarga from '../../assets/img/icons/downloadArrow.svg';
import Excel from '../../assets/img/icons/excel.svg';

import { getListCompanies } from '../../actions/billingActions';
import { costcenter_get } from '../../actions/costcenterActions';
import { functional_get } from '../../actions/functionalActions';
import { createVoucherRegistration, listThirdParties } from '../../actions/movementsActions';
import { getProviders } from '../../actions/providersActions';
import { getNiifAccounts } from '../../actions/pucActions';
import { getSites } from '../../actions/sitesActions';

import GenericTableNew from '../../components/Layouts/GenericTableNew';
import { Title } from '../../shared';

import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Styles from './CodeReplace.module.scss';
import movementsStyles from './movementsStyles.module.scss';
import { formatNumberCurrency, getLocaleSeparators } from "../../helpers/numberFormatting";
import { getEnvs } from "../../helpers";

const localeSeparators = getLocaleSeparators();
const { CURRENCY } = getEnvs();

function VoucherRegistration(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const [trigger2, setTrigger2] = useState(1);
  const [count, setCount] = useState(1);

  const [info, setInfo] = useState({
    typeVoucher: 'DIA',
    eaccount: storage.loginReducer.currentAccount.id,
    description: '',
    date: '',
    user: storage.loginReducer.user_data.id,
    niifAccounts: [],
  });

  const [infoSelect, setInfoSelect] = useState({
    niifAccount: '',
    third: '',
    cc: '',
    unit: '',
    site: '',
    infoThird: '',
    infoThirdName: '',
    niifAccountName: '',
    thirdName: '',
    siteName: '',
    ccName: '',
    unitName: '',
  });

  const [list, setList] = useState([]);

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'vouchers',
    );

  const WhiteTooltip = withStyles({
    tooltip: {
      color: 'rgba(88, 89, 91, 1)',
      backgroundColor: '#FFF',
      boxShadow: '0px 1px 4px rgba(0, 63, 128, 0.35);',
      fontSize: '12px',
      position: 'relative',
    },
    arrow: {
      color: '#FFF',
      '&::before': {
        backgroundColor: '#FFF',
        border: '2px solid #FFF',
        boxShadow: '0px 0px 2px rgba(0, 63, 128, 0.35);',
        position: 'absolute',
      },
    },
  })(Tooltip);

  useEffect(() => {
    if (!myPermission()?.create || !myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getNiifAccounts({ eaccount: storage.loginReducer.currentAccount.id, active: 1, aux: 1 }),
    );
    dispatch(costcenter_get({ eaccount: storage.loginReducer.currentAccount.id, active: 1 }));
    dispatch(functional_get({ eaccount: storage.loginReducer.currentAccount.id, active: 1 }));
    dispatch(getSites({ id_account: storage.loginReducer.currentAccount.id, active: 1 }));
    // dispatch(getJournalType({eaccount: storage.loginReducer.currentAccount.id, blocked: 0}));

    dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(
      getProviders({
        id_account: storage.loginReducer.currentAccount.id,
        slim: 1,
        status: 'enabled',
        checked: 1,
      }),
    );
    dispatch(listThirdParties({ eaccount: storage.loginReducer.currentAccount.id }));
  }, [dispatch, storage.loginReducer.currentAccount.id]);

  const renderHeaders = [
    <th key={`reqTH2`} className='col-2 px-2'>
      Cuenta
    </th>,
    <th key={`reqTH3`} className='col-1'>
      Tipo
    </th>,
    <th key={`reqTH4`} className='col-2'>
      Tercero{' '}
    </th>,
    <th key={`reqTH5`}>C.C</th>,
    <th key={`reqTH6`}>U.F</th>,
    <th key={`reqTH7`}>Sede</th>,
    <th key={`reqTH8`} className='text-end col-1'>
      Debe
    </th>,
    <th key={`reqTH9`} className='text-end col-1'>
      Haber
    </th>,
    <th key={`reqTH10`} className='text-end'>
      &nbsp;
    </th>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(list)) {
      tempList = list.map((x, index) => {
        return (
          <tr key={'renderList' + x.id} className={`hover-table-row`}>
            <td className='col-2' style={{ paddingRight: '2px', paddingLeft: '2px' }}>
              {
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'account' + x.id}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={optionsAccount}
                  onChange={e => handleNiifAccount(e, index)}
                  value={
                    list[index].niifAccount
                      ? { label: list[index].niifAccountName }
                      : optionsAccount.find(item => item.label === x.niifAccountName)
                  }
                />
              }
            </td>
            <td className='col-1' style={{ paddingRight: '2px', paddingLeft: '2px' }}>
              {x.infoThirdName ? (
                x.infoThirdName
              ) : (
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'Tthird2' + x.id}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={optionsTypeThirds}
                  value={
                    list[index].typeThirdName
                      ? { label: list[index].typeThirdName }
                      : optionsTypeThirds.find(item => item.label === x.typeThirdName)
                  }
                  onChange={e => {
                    handleTypeThird(e, index);
                  }}
                />
              )}
            </td>
            <td className='col-2' style={{ paddingRight: '2px', paddingLeft: '2px' }}>
              {
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'third2' + x.id}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={getOptionThirds(list[index].typeThird)}
                  value={
                    list[index].thirdName
                      ? { label: list[index].thirdName }
                      : getOptionThirds(list[index].typeThird).find(
                          item => item.label === x.thirdName,
                        )
                  }
                  onChange={e => handleThird(e, index)}
                />
              }
            </td>
            <td style={{ paddingRight: '2px', paddingLeft: '2px' }}>
              {' '}
              {
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'cc2' + x.id}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={optionsCostCenter}
                  value={
                    list[index].ccName
                      ? { label: list[index].ccName }
                      : optionsCostCenter.find(item => item.label === x.cc)
                  }
                  onChange={e => handleCC(e, index)}
                />
              }
            </td>
            <td style={{ paddingRight: '2px', paddingLeft: '2px' }}>
              {' '}
              {
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'uf2' + x.id}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={optionsFU}
                  value={
                    list[index].unitName
                      ? { label: list[index].unitName }
                      : optionsFU.find(item => item.label === x.ccName)
                  }
                  onChange={e => handleUF(e, index)}
                />
              }
            </td>
            <td style={{ paddingRight: '2px', paddingLeft: '2px' }}>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'site2' + x.id}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                options={optionsSites}
                value={
                  list[index].siteName
                    ? { label: list[index].siteName }
                    : optionsSites.find(item => item.label === x.siteName)
                }
                onChange={e => handleSite(e, index)}
              />
            </td>
            <td className='col-1' style={{ paddingRight: '2px', paddingLeft: '2px' }}>
              <NumberFormat
                key={'valueCredit' + x.id}
                allowNegative={false}
                style={{ padding: 0, color: '#58595B', textAlignLast: 'end', paddingRight: '3px' }}
                className={IndividualStyles.registerInputs}
                name='valueCredit'
                decimalScale={2}
                suffix={` ${CURRENCY}`}
                decimalSeparator={localeSeparators.decimalSeparator}
                thousandSeparator={localeSeparators.groupSeparator}
                fixedDecimalScale
                onValueChange={e => handleDebit(e, index)}
                isAllowed={values => {
                  const { floatValue } = values;
                  let tempObject = deepcopy(list);
                  if (tempObject[index].credit) {
                    tempObject[index].credit = '0';
                    setList(tempObject);
                    return false;
                  } else {
                    if (floatValue !== undefined) {
                      if (floatValue <= 0) {
                        return false;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }}
              ></NumberFormat>
            </td>
            <td className='col-1' style={{ paddingRight: '2px', paddingLeft: '2px' }}>
              <NumberFormat
                key={'valueDebit' + x.id}
                allowNegative={false}
                style={{ padding: 0, color: '#58595B', textAlignLast: 'end', paddingRight: '3px' }}
                className={IndividualStyles.registerInputs}
                name='valueDebit'
                decimalScale={2}
                suffix={` ${CURRENCY}`}
                decimalSeparator={localeSeparators.decimalSeparator}
                thousandSeparator={localeSeparators.groupSeparator}
                fixedDecimalScale
                onValueChange={e => handleCredit(e, index)}
                isAllowed={values => {
                  const { floatValue } = values;
                  let tempObject = deepcopy(list);
                  if (tempObject[index].debit) {
                    tempObject[index].debit = '0';
                    setList(tempObject);
                    return false;
                  } else {
                    if (floatValue !== undefined) {
                      if (floatValue <= 0) {
                        return false;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }}
              ></NumberFormat>
            </td>
            <td>
              <img
                key={'img' + x.id}
                src={Closed}
                alt='closed'
                width={10}
                height={10}
                className={IndividualStyles.pointer}
                onClick={() => deleteItem(x.id)}
              />
            </td>
          </tr>
        );
      });
    }

    return tempList;
  };

  let optionsAccount = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.pucReducer.niifAccounts)) {
    storage.pucReducer.niifAccounts.forEach((item, index) => {
      optionsAccount.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.description,
        key: index + 'account',
      });
    });
  }

  let optionsTypeThirds = [
    { key: 'default', value: '', label: 'Seleccionar...' },
    { key: '1', value: 1, label: 'CE' },
    { key: '1', value: 2, label: 'PR' },
    { key: '1', value: 3, label: 'T' },
  ];

  let optionsThirds_A = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.billingReducer.companies)) {
    storage.billingReducer.companies.forEach(item => {
      optionsThirds_A.push({
        value: item,
        label: item.Nit + '-' + item.copyName,
        key: 'third' + item.Nit,
      });
    });
  }

  let optionsThirds_B = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.providerReducer.providers)) {
    storage.providerReducer.providers.forEach(item => {
      optionsThirds_B.push({
        value: item,
        label: item.nit + '-' + item.name,
        key: item.id + 'providers',
      });
    });
  }

  let optionsThirds_C = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.accountingConsultsReducer.thirdParties)) {
    storage.accountingConsultsReducer.thirdParties.forEach(item => {
      optionsThirds_C.push({
        value: item,
        label: item.nit + '-' + item.description,
        key: item.id + 'thirds',
      });
    });
  }

  const getOptionThirds = codigo => {
    switch (codigo) {
      case 1:
        return optionsThirds_A;
      case 2:
        return optionsThirds_B;
      case 3:
        return optionsThirds_C;
      default:
        return optionsThirds_A;
    }
  };

  let optionsCostCenter = storage.costcenterReducer.cc?.length
    ? [{ key: '', value: '', label: 'Seleccionar...' }]
    : [];
  if (Array.isArray(storage.costcenterReducer.cc)) {
    storage.costcenterReducer.cc.forEach((item, index) => {
      optionsCostCenter.push({
        value: item.id,
        label: item.description,
        key: index + 'cc',
      });
    });
  }

  let optionsFU = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.functionalReducer.units)) {
    storage.functionalReducer.units.forEach((item, index) => {
      optionsFU.push({
        value: item.id,
        label: item.description,
        key: index + 'fu',
      });
    });
  }
  let optionsSites = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.siteReducer.sites)) {
    storage.siteReducer.sites.forEach((item, index) => {
      optionsSites.push({
        value: item.id,
        label: item.description,
        key: index + 'sites',
      });
    });
  }

  //--------------  Actions  🍕   --------------
  const addItem = () => {
    setCount(count + 1);
    let tempObject = deepcopy(list);
    tempObject.push({
      id: count,
      niifAccount: infoSelect.niifAccount,
      niifAccountName: infoSelect.niifAccountName,
      typeThird: infoSelect.infoThird,
      typeThirdName: infoSelect.infoThirdName,
      third: infoSelect.third,
      thirdName: infoSelect.thirdName,
      cc: infoSelect.cc,
      unit: infoSelect.unit,
      site: infoSelect.site,
      siteName: infoSelect.siteName,
      ccName: infoSelect.ccName,
      unitName: infoSelect.unitName,
      debit: '',
      credit: '',
    });
    setList(tempObject);
  };

  const handleDebit = (value, index) => {
    let tempObject = deepcopy(list);
    tempObject[index].debit = value.value;
    setList(tempObject);
  };

  const handleCredit = (value, index) => {
    let tempObject = deepcopy(list);
    tempObject[index].credit = value.value;
    setList(tempObject);
  };

  const handleNiifAccount = (value, index) => {
    let tempObject = deepcopy(list);
    tempObject[index].niifAccount = value.value;
    tempObject[index].niifAccountName = value.label;
    setList(tempObject);
  };

  const handleTypeThird = (value, index) => {
    let tempObject = deepcopy(list);
    tempObject[index].typeThird = value.value;
    tempObject[index].typeThirdName = value.label;
    setList(tempObject);
  };

  const handleThird = (value, index) => {
    let tempObject = deepcopy(list);
    tempObject[index].third = value.value;
    tempObject[index].thirdName = value.label;
    setList(tempObject);
  };

  const handleCC = (value, index) => {
    let tempObject = deepcopy(list);
    tempObject[index].cc = value.value;
    tempObject[index].ccName = value.label;
    setList(tempObject);
  };

  const handleUF = (value, index) => {
    let tempObject = deepcopy(list);
    tempObject[index].unit = value.value;
    tempObject[index].unitName = value.label;
    setList(tempObject);
  };

  const handleSite = (value, index) => {
    let tempObject = deepcopy(list);
    tempObject[index].site = value.value;
    tempObject[index].siteName = value.label;
    setList(tempObject);
  };

  const deleteItem = id => {
    let tempObject = deepcopy(list);
    let found = tempObject.filter(x => x.id !== id);
    setList(found);
  };

  const createRegistration = () => {
    dispatch(
      createVoucherRegistration(
        {
          typeVoucher: info.typeVoucher,
          eaccount: storage.loginReducer.currentAccount.id,
          description: info.description,
          date: info.date,
          user: storage.loginReducer.user_data.id,
          niifAccounts: list,
        },
        () => {
          setTrigger(trigger + 1);
          setList([]);
        },
      ),
    );
  };

  const totalDebit = () => {
    let tempObject = deepcopy(list);
    let total = 0;
    tempObject.forEach(x => {
      total = total + Number(x.debit);
    });
    return formatNumberCurrency(total);
  };

  const totalCredit = () => {
    let tempObject = deepcopy(list);
    let total = 0;
    tempObject.forEach(x => {
      total = total + Number(x.credit);
    });
    return formatNumberCurrency(total);
  };

  const iqualTotals = () => {
    let equal = false;
    let tempObject = deepcopy(list);
    let total = 0;
    let total1 = 0;
    tempObject.forEach(x => {
      total = total + Number(x.credit);
      total1 = total1 + Number(x.debit);
    });
    if (total === total1 && total !== 0 && total1 !== 0) {
      equal = true;
    }
    return equal;
  };

  //--------------  Render  🍕   --------------

  return (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {storage?.movementsReducer?.loadingRegisterVoucher && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className='d-flex'>
        <div className='d-flex flex-column' style={{ width: '100%' }}>
          <div className={tableStyles.title}>
            <Title
              title='Registro de comprobantes'
              onClickIcon={() => {
                props.setShow({ showRegistration: false });
              }}
            />
          </div>
          <Row className='d-flex'>
            <Col xs={8}></Col>
            <Col xs={4} className='text-end'>
              <div className='d-inline-flex'>
                <div>
                  <label
                    className={Styles.bgBtn}
                    style={{
                      padding: '5px',
                      borderRadius: '6px',
                      width: '185px',
                      fontSize: '12px',
                      fontWeight: '700',
                      fontStyle: 'bold',
                      cursor: 'pointer',
                    }}
                  >
                    {' '}
                    Importar plantilla
                    <img style={{ width: '17px', marginLeft: '7px' }} src={Excel} alt='' />
                  </label>
                </div>
                <div>
                  <label
                    className={Styles.bgBtn}
                    style={{
                      padding: '5px',
                      borderRadius: '6px',
                      width: '185px',
                      fontSize: '12px',
                      fontWeight: '700',
                      fontStyle: 'bold',
                      cursor: 'pointer',
                    }}
                  >
                    {' '}
                    Traer comprobante
                    <img style={{ width: '17px', marginLeft: '7px' }} src={Excel} alt='' />
                  </label>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='d-flex'>
            <Col xs={9}>
              <p className={tableStyles.crudModalLabel}>
                Concepto <span className={'text-warning'}>*</span>{' '}
              </p>
              <input
                key={'description' + trigger}
                type='text'
                name='concept'
                placeholder='Escribir...'
                className={IndividualStyles.registerInputsBlue}
                style={{ color: '#58595B' }}
                onChange={e =>
                  setInfo({
                    ...info,
                    description: e.target.value,
                  })
                }
                disabled={false}
              />
            </Col>

            <Col xs={3}>
              <p className={tableStyles.crudModalLabel}>
                Fecha de comprobante <span className={'text-warning'}>*</span>{' '}
              </p>
              <input
                key={'date' + trigger}
                type='date'
                name='date'
                className={IndividualStyles.registerInputsBlue}
                value={info.date}
                onChange={e => {
                  let dateSelected = new Date(e.target.value);
                  if (dateSelected < new Date()) {
                    setInfo({
                      ...info,
                      date: e.target.value,
                    });
                  }
                }}
                max={new Date().toISOString().slice(0, 10)}
              />
            </Col>
          </Row>

          <Row
            className='mx-0'
            style={{
              background: 'rgba(0, 93, 191, 0.1)',
              borderRadius: '10px',
              paddingRight: '8px',
              marginTop: '12px',
              paddingBottom: '8px',
              paddingLeft: '8px',
              paddingTop: '4px',
            }}
          >
            <Col xs={12} style={{ marginTop: '0px', paddingLeft: '0px' }}>
              <p
                className={IndividualStyles.labelNew}
                style={{ fontSize: '15px', marginLeft: '0px', paddingLeft: '0px' }}
              >
                {' '}
                Repetir:
              </p>
            </Col>
            <Col xs={2} style={{ paddingLeft: '0px' }}>
              <p className={tableStyles.crudModalLabel}>Cuenta</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'account' + trigger}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                options={optionsAccount}
                onChange={e =>
                  setInfoSelect({
                    ...infoSelect,
                    niifAccount: e.value,
                    niifAccountName: e.label,
                  })
                }
              />
            </Col>
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Tipo de tercero</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'Tthird' + trigger}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                options={optionsTypeThirds}
                onChange={e => {
                  setInfoSelect({
                    ...infoSelect,
                    infoThird: e.value,
                    infoThirdName: e.label,
                  });
                  setTrigger2(trigger2 + 1);
                }}
              />
            </Col>
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Tercero</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'third' + trigger + trigger2}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                options={getOptionThirds(infoSelect.infoThird)}
                onChange={e =>
                  setInfoSelect({
                    ...infoSelect,
                    third: e.value,
                    thirdName: e.label,
                  })
                }
              />
            </Col>
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Centro de costo</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'cc' + trigger}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                options={optionsCostCenter}
                onChange={e =>
                  setInfoSelect({
                    ...infoSelect,
                    cc: e.value,
                    ccName: e.label,
                  })
                }
              />
            </Col>
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Unidad funcional</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'uf' + trigger}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                options={optionsFU}
                onChange={e =>
                  setInfoSelect({
                    ...infoSelect,
                    unit: e.value,
                    unitName: e.label,
                  })
                }
              />
            </Col>
            <Col xs={2} style={{ paddingRight: '0px' }}>
              <p className={tableStyles.crudModalLabel}>Sede</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'site' + trigger}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                options={optionsSites}
                onChange={e =>
                  setInfoSelect({
                    ...infoSelect,
                    site: e.value,
                    siteName: e.label,
                  })
                }
              />
            </Col>
            <Col
              xs={12}
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
                paddingRight: '0px',
                marginTop: '5px',
              }}
            >
              <div className={movementsStyles.btnImage} style={{ width: '24px', height: '25px ' }}>
                <WhiteTooltip title={'Agregar partida'} placement='left' arrow>
                  <img
                    style={{ width: '24px', height: '24px' }}
                    src={Descarga}
                    onClick={() => addItem()}
                    className={IndividualStyles.btnImage}
                    alt='icon'
                  ></img>
                </WhiteTooltip>
              </div>
            </Col>
          </Row>
          <div>
            <GenericTableNew dark={true} headers={renderHeaders}>
              {renderList()}
            </GenericTableNew>
          </div>
          {list.length > 0 ? (
            <div className='d-flex'>
              <div className='col-2'></div>
              <div className='col-2'></div>
              <div className='col-4'></div>
              <div className='col-2 text-center' style={{ paddingLeft: '7px' }}>
                {' '}
                <p
                  style={
                    iqualTotals()
                      ? {
                          color: '#00A551',
                          fontWeight: '700',
                          fontSize: '15px',
                          lineHeight: '19px',
                        }
                      : {
                          color: '#58595B',
                          fontWeight: '700',
                          fontSize: '15px',
                          lineHeight: '19px',
                        }
                  }
                >
                  Totales
                </p>
              </div>
              <div className='col-1 text-end' style={{ paddingRight: '39px' }}>
                {' '}
                <p
                  style={
                    iqualTotals()
                      ? {
                          color: '#00A551',
                          fontWeight: '700',
                          fontSize: '15px',
                          lineHeight: '19px',
                        }
                      : {
                          color: '#58595B',
                          fontWeight: '700',
                          fontSize: '15px',
                          lineHeight: '19px',
                        }
                  }
                >
                  {'$' + totalDebit()}
                </p>
              </div>
              <div className='col-1 text-end' style={{ paddingRight: '39px' }}>
                <p
                  style={
                    iqualTotals()
                      ? {
                          color: '#00A551',
                          fontWeight: '700',
                          fontSize: '15px',
                          lineHeight: '19px',
                        }
                      : {
                          color: '#58595B',
                          fontWeight: '700',
                          fontSize: '15px',
                          lineHeight: '19px',
                        }
                  }
                >
                  {'$' + totalCredit()}
                </p>
              </div>
            </div>
          ) : (
            <span></span>
          )}
          <div className={IndividualStyles.bottomRow}>
            <Button
              className={IndividualStyles.btnPrimaryDark}
              onClick={() => createRegistration()}
              disabled={!!(info.description === '') || !!(info.date === '') || !!(list.length <= 0)}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VoucherRegistration;
