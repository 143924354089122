import { useEffect, useState, useMemo, useCallback } from 'react';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import GenericTable from '../../components/Layouts/GenericTableNew';
import GenericTableScroll from '../../components/Layouts/GenericTableScroll';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import StyleMovements from './movements.module.scss';
import ModalNew from '../../components/Layouts/ModalNew';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { getProviders } from '../../actions/providersActions';
import { getCausationType, updateCausation } from '../../actions/causationActions';
import { useDispatch, useSelector } from 'react-redux';
import Agregar from '../../assets/img/icons/aggdocGray.svg';
import Grafi from '../../assets/img/icons/pie-chart.svg';
import CheckGreen from '../../assets/img/icons/CheckGreen.svg';
import Question from '../../assets/img/icons/question.svg';
import Excel from '../../assets/img/icons/excel.svg';
import Word from '../../assets/img/icons/word.svg';
import PDF from '../../assets/img/icons/pdf.svg';
import Cerrar from '../../assets/img/icons/littleClose.svg';
import Adjuntar from '../../assets/img/icons/insertar.svg';
import ModalDocuments from '../../components/TableIndividualAuction/IndividualDocuments';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import NumberFormat from 'react-number-format';
import { Button, Col, Row } from 'react-bootstrap';
import { getAllConCenter } from '../../actions/consumptionCenterActions';
import { getNiifAccounts } from '../../actions/AccountsActions';
import deepcopy from 'deepcopy';
import { useHistory } from 'react-router';
import Lupa from '../../assets/img/icons/lupa.svg';
import { getPinvoices } from '../../actions/crossingAccountsAction';
import Loader from 'react-loader-spinner';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { Title } from '../../shared';
import { generateId, getEnvs } from '../../helpers';
import { formatNumberCurrency, getLocaleSeparators } from '../../helpers/numberFormatting';

const localeSeparators = getLocaleSeparators();
const { CURRENCY } = getEnvs();

function Causation() {
  const history = useHistory();
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const [triggerInput, setTriggerInput] = useState(1);
  const [showModalPinvoice, setShowModalPinvoice] = useState(false);
  const [searchPinvoices, setSearchPinvoices] = useState([]);
  const [triggerClean, settriggerClean] = useState(1);
  const [pos, setPos] = useState(0);
  const [trigger2, setTrigger2] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [createCausation, setCreateCausation] = useState({
    eaccount: counter.loginReducer.currentAccount.id,
    user: counter.loginReducer.user_data.id,
    generateVoucher: false,
    totalAmount: '',
    documentNumber: '',
    docDate: '',
    dueDate: '',
    currentBalance: '',
    vatTotal: '',
    discDate: '',
    discAmount: 0,
    due_date_Invoice: '',
    exp_date_Invoice: '',
    url_Invoice: '',
    pinvoice: '',
  });
  const [loading, setLoading] = useState(true);
  const [reqState, setreqState] = useState({
    auction_title: '',
    date_expiry: '',
    hour_expiry: '',
    terms: '',
    priority: '',
    id_warehouse: '',
    id_user: counter.loginReducer.user_data.id,
    account: counter.loginReducer.currentAccount.id,
    account_session: counter.loginReducer.currentAccount.name,
    id_consumption_center: 0,
    articulos: [],
    pay_conditions: '',
    coin: '',
    document: [],
    file: false,
    observations: ' ',
    qty_total: 0,
  });
  const resetStatusFile = event => {
    if (event) {
      event.stopPropagation();
    }
    setreqState({
      auction_title: '',
      date_expiry: '',
      hour_expiry: '',
      terms: '',
      priority: '',
      id_warehouse: '',
      id_user: counter.loginReducer.user_data.id,
      account: counter.loginReducer.currentAccount.id,
      account_session: counter.loginReducer.currentAccount.name,
      id_consumption_center: 0,
      articulos: [],
      pay_conditions: '',
      coin: '',
      document: [],
      file: false,
      observations: ' ',
      qty_total: 0,
    });
  };

  useEffect(() => {
    dispatch(getNiifAccounts({ eaccount: counter.loginReducer.currentAccount.id }));
  }, [counter.loginReducer.currentAccount.id, dispatch, history]);

  const validateDisabled = (id, type) => {
    let tempObject = deepcopy(niifAccountNbr);
    let found = niifAccountNbr.findIndex(x => x.id === id);
    if (type === 'debe') {
      if (found >= 0) {
        if (tempObject[id]?.credit) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (found >= 0) {
        if (tempObject[id]?.debit) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
  const [modalDocumentsControl, setmodalDocumentsControl] = useState({ show: false });
  const [niifAccountNbr, setNiifAccountNbr] = useState([]);
  const [tempCc, setTempCc] = useState({
    cc: '',
    ccName: '',
    percentage: '',
  });

  const [cc, setCc] = useState([]);

  const ButtonDocuments = () => {
    setmodalDocumentsControl({
      ...modalDocumentsControl,
      show: true,
    });
  };

  var date = new Date();

  const formatDateTwo = date => {
    let formatted_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return formatted_date;
  };
  const docType = data => {
    let iconDoc;
    if (data.file) {
      switch (data.file.type) {
        case 'application/pdf':
          iconDoc = PDF;
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          'application/msword':
          iconDoc = Word;
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          'csv' ||
          'application/vnd.ms-excel':
          iconDoc = Excel;
          break;
        default:
          iconDoc = PDF;
          break;
      }
    }
    return iconDoc;
  };

  const docType2 = data => {
    let iconDoc;
    if (data) {
      switch (data) {
        case 'application/pdf':
          iconDoc = PDF;
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          'application/msword':
          iconDoc = Word;
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          'csv' ||
          'application/vnd.ms-excel':
          iconDoc = Excel;
          break;
        default:
          iconDoc = PDF;
          break;
      }
    }
    return iconDoc;
  };

  useEffect(() => {
    dispatch(
      getProviders({
        id_account: counter.loginReducer.currentAccount.id,
        slim: 1,
        status: 'enabled',
        checked: 1,
      }),
    );
    dispatch(
      getCausationType({ eaccount: counter.loginReducer.currentAccount.id }, () =>
        setLoading(false),
      ),
    );
  }, [trigger, counter.loginReducer.currentAccount.id, dispatch]);
  useEffect(() => {
    if (createCausation?.provider) {
      dispatch(
        getPinvoices({
          provider_id: createCausation?.provider ? createCausation?.provider : '',
          invoice_nbr: searchPinvoices?.search ? searchPinvoices?.search : '',
          due_date: searchPinvoices?.date ? searchPinvoices?.date : '',
          status: 2,
        }),
      );
    }
  }, [trigger2, createCausation.provider, dispatch, searchPinvoices.search, searchPinvoices.date]);

  let optionProvider = [{ value: '', label: 'Seleccionar...' }];
  if (Array.isArray(counter.providerReducer.providers)) {
    counter.providerReducer.providers.forEach(item => {
      optionProvider.push({
        value: item.id,
        label: item.nit + ' - ' + item.name,
        valueName: item.name,
      });
    });
  }
  let optionCausationType = [{ value: '', label: 'Seleccionar...' }];
  if (Array.isArray(counter.causationReducer.resultsCausatioType)) {
    counter.causationReducer.resultsCausatioType.forEach(item => {
      optionCausationType.push({
        value: item.id,
        label: item.tag,
        name: item.name,
      });
    });
  }
  let optionConsumptionCenter = [{ value: '', label: 'Seleccionar...' }];
  if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
    counter.consumptionCenterReducer.consumption_center.forEach(item => {
      optionConsumptionCenter.push({
        value: item.id,
        label: item.description,
      });
    });
  }
  let optionNiifAccounts = [{ value: '', label: 'Seleccionar...' }];
  if (Array.isArray(counter.pucReducer.niifAccounts)) {
    counter.pucReducer.niifAccounts.forEach(item => {
      if (item.active === true) {
        optionNiifAccounts.push({
          value: item.id,
          label: item.complete_account + ' - ' + item.description,
          key: item.id + 'qg',
        });
      }
    });
  }

  const headerModalPinvoices = [
    <div key={1} className='col-1 text-center'>
      ㅤ
    </div>,
    <div key={2} className='col text-center'>
      No. Factura
    </div>,
    <div key={3} className='col text-center'>
      Vence
    </div>,
    <div key={4} className='col text-center'>
      Valor
    </div>,
  ];
  const renderList = () => {
    let tempList = [];
    if (Array.isArray(counter?.PinvoiceReducer?.results)) {
      counter?.PinvoiceReducer?.results.forEach(item => {
        tempList.push(
          <section2 htmlFor='PinvoiceSelect'>
            <div className='col-1 text-center'>
              <input
                className='form-check-input'
                type='radio'
                name='consignment'
                id='consignment'
                value='consignment'
                onChange={() =>
                  setCreateCausation({
                    ...createCausation,
                    documentNumber: item.invoice_nbr,
                    due_date_Invoice: item.due_date,
                    exp_date_Invoice: item.exp_date,
                    url_Invoice: item.file,
                    pinvoice: item.id,
                  })
                }
              />
            </div>
            <div className='col text-start' style={{ paddingLeft: '1rem' }}>
              {item.invoice_nbr}
            </div>
            <div className='col text-center'>{convertDateToLatinFormat(item.due_date)}</div>
            <div className='col text-end' style={{ paddingRight: '1rem' }}>
              {formatNumberCurrency(item.total_amount)}
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  const headerModal = [
    <th key={generateId()} className='text-center'>
      #
    </th>,
    <th key={generateId()} className='text-center'>
      Centro de costo
    </th>,
    <th key={generateId()} className='text-center'>
      Porcentaje
    </th>,
    <th key={generateId()} className='text-center'>
      Valor
    </th>,
    <th key={generateId()} className='text-center'>
      ㅤ
    </th>,
  ];

  const [bodyModal, sum, percentage] = useMemo(() => {
    let list = [];
    let tempRest = 0;
    let tempPercentage = 0;
    const deleteTempCc = id => {
      if (cc.length > 0) {
        let arrayNew = cc.filter(item => item.cc !== id);
        setCc(arrayNew);
      }
    };
    if (Array.isArray(cc)) {
      list = cc.map((item, index) => {
        tempRest = tempRest + Number(createCausation.totalAmount) * (item.percentage / 100);
        tempPercentage = tempPercentage + item.percentage;
        return (
          <tr key={generateId()}>
            <td className='text-center'>{index + 1}</td>
            <td className='text-center'>{item.ccName}</td>
            <td className='text-center'>{item.percentage}%</td>
            <td className='text-end' style={{ paddingRight: '20px' }}>
              {formatNumberCurrency(
                createCausation.totalAmount
                  ? Number(createCausation.totalAmount) * (item.percentage / 100)
                  : 0,
              )}
            </td>
            <td onClick={() => deleteTempCc(item.cc)} className='col-md-1 cursorPointer'>
              X
            </td>
          </tr>
        );
      });
      tempRest = createCausation.totalAmount - tempRest;
    }
    return [list, tempRest, tempPercentage];
  }, [createCausation.totalAmount, cc]);

  const header3 = [
    <th key={generateId()} className='text-center col-7'>
      Cuenta{' '}
    </th>,
    <th key={generateId()} className='text-end col-2'>
      DEBE
    </th>,
    <th key={generateId()} className='text-end col-2'>
      HABER
    </th>,
    <th key={generateId()} className='text-center'>
      ㅤ
    </th>,
  ];
  let totalDebit = 0;
  let totalCredit = 0;
  if (Array.isArray(niifAccountNbr)) {
    niifAccountNbr.forEach(x => {
      totalDebit = totalDebit + Number(x.debit);
      totalCredit = totalCredit + Number(x.credit);
    });
  }
  const footer3 = [
    <tr key={generateId}>
      {/* <td class="invisible" colspan="1"></td> */}
      <th style={{ color: '#58595B' }}>Total</th>
      <th className='text-end' style={{ color: '#58595B' }}>
        {formatNumberCurrency(totalDebit)}
      </th>
      <th className='text-end' style={{ color: '#58595B' }} colSpan='1'>
        {formatNumberCurrency(totalCredit)}
      </th>
      <th className='text-end'>ㅤ</th>
    </tr>,
    <tr key={generateId()}>
      <td className={tableStyles.titleDeference}>Diferencia</td>
      <td className={`${tableStyles.titleDeference} text-end`}>
        {formatNumberCurrency(
          isNaN(createCausation.currentBalance + createCausation.vatTotal - totalDebit)
            ? 0
            : createCausation.currentBalance + createCausation.vatTotal - totalDebit,
        )}
      </td>
      <td className={`${tableStyles.titleDeference} text-end`}>
        {formatNumberCurrency(
          isNaN(createCausation.currentBalance + createCausation.vatTotal - totalCredit)
            ? 0
            : createCausation.currentBalance + createCausation.vatTotal - totalCredit,
        )}
      </td>
      <th className='text-end'>ㅤ</th>
    </tr>,
  ];

  const descriptionniifAccount = (e, position, id) => {
    let tempObject = deepcopy(niifAccountNbr);
    let found = niifAccountNbr?.findIndex(x => x.id === id);
    if (found >= 0) {
      tempObject[position].niif = e.value;
      if (counter?.pucReducer?.niifAccounts.find(x => x.id === e.value)) {
        let description = counter.pucReducer.niifAccounts.find(x => x.id === e.value).description;
        tempObject[position].description = description;
      }
    }
    setNiifAccountNbr(tempObject);
  };

  const changeDebit = (e, position, id) => {
    let tempObject = deepcopy(niifAccountNbr);
    let found = niifAccountNbr.findIndex(x => x.id === id);
    if (found >= 0) {
      tempObject[position].debit = e.value;
    }
    setNiifAccountNbr(tempObject);
  };

  const changeCredit = (e, position, id) => {
    let tempObject = deepcopy(niifAccountNbr);
    let found = niifAccountNbr.findIndex(x => x.id === id);
    if (found >= 0) {
      tempObject[position].credit = e.value;
    }
    setNiifAccountNbr(tempObject);
  };
  const delFromTable = item => {
    let newArray = niifAccountNbr.filter(obj => obj.id !== item.id);

    setNiifAccountNbr(newArray);
    setTrigger(trigger + 1);
  };

  const addTable = () => {
    let tempObject = {
      id: pos,
      niif: '',
      description: '',
      debit: '',
      credit: '',
    };
    niifAccountNbr.push(tempObject);
    setPos(pos + 1);
  };
  const download = url => {
    if (url !== null) {
      window.location.href = url;
    }
  };

  const nameDoc = url => {
    let name = url.split('/').pop();
    return name;
  };
  let bodyTablePrincipal = [];
  if (Array.isArray(niifAccountNbr)) {
    niifAccountNbr.forEach((item, index) => {
      bodyTablePrincipal.push(
        <tr key={item.id + 'da'}>
          <td className='text-center hoverPointer'>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              // key={item.id + "qa"}
              placeholder='Seleccionar...'
              options={optionNiifAccounts}
              styles={customSelectNewDark}
              onChange={e => descriptionniifAccount(e, index, item.id)}
            />
          </td>
          <td className='text-end' style={{ paddingRight: '10px' }}>
            <NumberFormat
              allowNegative={false}
              key={'debit' + trigger + item.id}
              id='cost_price'
              style={{ backgroundColor: 'transparent', border: 'none' }}
              className={`${IndividualStyles.registerInputsBlue} text-end`}
              prefix='$'
              value={item?.debit}
              thousandsGroupStyle='thousand'
              thousandSeparator
              onValueChange={e => changeDebit(e, index, item.id)}
              isAllowed={values => {
                const { floatValue } = values;
                let tempObject = deepcopy(niifAccountNbr);
                let found = niifAccountNbr.findIndex(x => x.id === item.id);
                if (found >= 0) {
                  if (tempObject[index].credit) {
                    tempObject[index].debit = 0;
                    setNiifAccountNbr(tempObject);
                    return false;
                  } else {
                    if (floatValue !== undefined) {
                      if (floatValue <= 0) {
                        return false;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
              }}
              disabled={validateDisabled(item.id, 'debe')}
            />
          </td>
          <td className='text-end' style={{ paddingRight: '10px' }}>
            <NumberFormat
              allowNegative={false}
              id='cost_price'
              style={{ backgroundColor: 'transparent', border: 'none' }}
              className={`${IndividualStyles.registerInputsBlue} text-end`}
              prefix='$'
              key={'credit' + trigger + item.id}
              value={item?.credit}
              thousandsGroupStyle='thousand'
              thousandSeparator
              onValueChange={e => changeCredit(e, index, item.id)}
              isAllowed={values => {
                const { floatValue } = values;
                let tempObject = deepcopy(niifAccountNbr);
                let found = niifAccountNbr.findIndex(x => x.id === item.id);
                if (found >= 0) {
                  if (tempObject[index]?.debit) {
                    tempObject[index].credit = 0;
                    setNiifAccountNbr(tempObject);
                    return false;
                  } else {
                    if (floatValue !== undefined) {
                      if (floatValue <= 0) {
                        return false;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
              }}
              disabled={validateDisabled(item.id, 'haber')}
            />
          </td>
          <td className='text-center hoverPointer' onClick={() => delFromTable(item)}>
            X
          </td>
        </tr>,
      );
    });
  }

  const sendTempCc = () => {
    if (!!tempCc.percentage && !!tempCc.ccName) {
      if (percentage + tempCc.percentage <= 100) {
        let find = cc.find(item => item.cc === tempCc.cc);
        if (!find) {
          let temp = deepcopy(cc);
          temp.push(tempCc);
          setCc(temp);
          setTempCc({
            cc: '',
            ccName: '',
            percentage: '',
          });
          setTriggerInput(triggerInput + 1);
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'Por favor tenga presente que no se puede seleccionar el mismo centro de consumo',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
          });
        }
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Por favor tenga presente que el porcentaje debe ser menor a 100%',
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
      }
    } else {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor tenga presente que debe seleccionar un Centro de Consumo y asociarle un porcentaje',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    }
  };
  const PostRegisterCausation = () => {
    if (createCausation.nameCausationType === 'invoice') {
      if (createCausation.vatTotal === '') {
        createCausation.vatTotal = 0;
      }
      if (createCausation.discAmount === '') {
        createCausation.discAmount = 0;
      }
      createCausation.docDate = createCausation.exp_date_Invoice;
      createCausation.dueDate = createCausation.due_date_Invoice;
    } else {
      if (createCausation.vatTotal === '') {
        createCausation.vatTotal = 0;
      }
      if (createCausation.discAmount === '') {
        createCausation.discAmount = 0;
      }
    }

    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Verifique la información',
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true);
        createCausation.distribution = cc;
        createCausation.niifAccounts = niifAccountNbr;
        createCausation.fileName = reqState.document[0]?.file_name;
        createCausation.type_doc = reqState.document[0]?.type;
        createCausation.file64 = reqState.document[0]?.base64;
        dispatch(
          updateCausation(
            createCausation,
            () => {
              settriggerClean(triggerClean + 1);
              setNiifAccountNbr([]);
              setCreateCausation({
                eaccount: counter.loginReducer.currentAccount.id,
                user: counter.loginReducer.user_data.id,
                generateVoucher: false,
                totalAmount: '',
                documentNumber: '',
                docDate: '',
                dueDate: '',
                currentBalance: '',
                vatTotal: '',
                discDate: '',
                discAmount: 0,
                due_date_Invoice: '',
                exp_date_Invoice: '',
                url_Invoice: '',
              });
              setCc([]);
              resetStatusFile();
            },
            () => setLoading(false),
          ),
        );
      }
    });
  };

  const checkParameters = () => {
    let disable = true;
    let sum1 = 0;
    let sum2 = 0;
    niifAccountNbr.forEach(article => {
      sum1 = Number(article.debit) + sum1;
      sum2 = Number(article.credit) + sum2;
    });
    let saldoActual = 0;
    let iva = 0;
    if (createCausation.currentBalance) {
      saldoActual = createCausation.currentBalance;
    }
    if (createCausation.vatTotal) {
      iva = createCausation.vatTotal;
    }
    var validate =
      sum1 === saldoActual + iva &&
      sum2 === saldoActual + iva &&
      createCausation.documentNumber !== '' &&
      reqState.document.length > 0 &&
      createCausation.docDate !== '' &&
      createCausation.dueDate !== '' &&
      createCausation.currentBalance !== '' &&
      !!createCausation.currentBalance &&
      createCausation.currentBalance !== 0 &&
      createCausation.totalAmount !== '' &&
      !!createCausation.totalAmount &&
      createCausation.totalAmount !== 0;
    var validateInvoice =
      sum1 === saldoActual + iva &&
      sum2 === saldoActual + iva &&
      createCausation.documentNumber !== '' &&
      createCausation.currentBalance !== '' &&
      !!createCausation.currentBalance &&
      createCausation.currentBalance !== 0 &&
      createCausation.totalAmount !== '' &&
      !!createCausation.totalAmount &&
      createCausation.totalAmount !== 0;
    switch (createCausation.causationType) {
      case '1':
        // Opción de "Factura"
        if (validateInvoice) {
          disable = false;
        }
        break;
      case '2':
        // Opción de "Contrato"
        if (validate) {
          disable = false;
        }

        break;
      case '3':
        // Opción de "Cuenta de cobro"
        if (validate) {
          disable = false;
        }

        break;
      case '4':
        // Opción de "Cotización"
        if (validate) {
          disable = false;
        }

        break;
      default:
        break;
    }
    return disable;
  };
  const openModalPinvoices = () => {
    setShowModalPinvoice(true);
    setTrigger2(trigger2 + 1);
  };
  const openConsumptionCenter = () => {
    setShowModal(true);
    dispatch(getAllConCenter({ eaccount: counter.loginReducer.currentAccount.id, lite: 1 }));
  };

  const validateSum = useCallback(() => {
    let saldoActual = 0;
    let iva = 0;
    let descuento = 0;
    if (createCausation.currentBalance) {
      saldoActual = createCausation.currentBalance;
    }
    if (createCausation.vatTotal) {
      iva = createCausation.vatTotal;
    }
    if (createCausation.discAmount) {
      descuento = createCausation.discAmount;
    }

    setCreateCausation(item => ({
      ...item,
      totalAmount: saldoActual + iva - descuento,
    }));
  }, [createCausation.currentBalance, createCausation.discAmount, createCausation.vatTotal]);

  useEffect(() => {
    validateSum();
  }, [
    validateSum,
    createCausation.currentBalance,
    createCausation.vatTotal,
    createCausation.discAmount,
  ]);

  const today = new Date();
  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <div className={tableStyles.title}>
          <Title
            title='Causación'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>
        <div>
          <div className='div'>
            <div className='col-6 display-grid'>
              <label className={tableStyles.crudModalLabel}>Proveedor*</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={optionProvider}
                key={'provider' + triggerClean}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                onChange={e =>
                  setCreateCausation({
                    ...createCausation,
                    provider: e.value,
                  })
                }
              />
            </div>
            <div className='col-6 display-grid ml-3' style={{ paddingRight: '1rem' }}>
              <label className={tableStyles.crudModalLabel}>Detalle*</label>
              <input
                key={'detail' + triggerClean}
                className={IndividualStyles.registerInputsGris}
                type='text'
                onChange={e =>
                  setCreateCausation({
                    ...createCausation,
                    detail: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div
            className='mt-3'
            style={{ backgroundColor: '#005DBF20', padding: '10px', borderRadius: '10px' }}
          >
            <div className='div'>
              <div className='col-3 display-grid' style={{ paddingRight: '1rem' }}>
                <label className={tableStyles.crudModalLabel}>Tipo de documento </label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  options={optionCausationType}
                  key={'typeDoc' + triggerClean}
                  styles={customSelectNewDark}
                  onChange={e =>
                    setCreateCausation({
                      ...createCausation,
                      causationType: e.value,
                      nameCausationType: e.name,
                    })
                  }
                />
              </div>
              <div className='col-3 display-grid' style={{ paddingRight: '1rem' }}>
                <label className={tableStyles.crudModalLabel}>
                  {createCausation.nameCausationType === 'invoice' ? (
                    'No. de Factura*'
                  ) : createCausation.nameCausationType === 'contract' ? (
                    'No. de Contrato'
                  ) : createCausation.nameCausationType === 'chargeAccount' ? (
                    'No. de Cuenta de cobro'
                  ) : createCausation.nameCausationType === 'quotation' ? (
                    'No. de Cotización'
                  ) : (
                    <div> ㅤ </div>
                  )}
                </label>
                {createCausation.nameCausationType === 'invoice' ? (
                  <div
                    className={IndividualStyles.registerInputsGris}
                    style={{ backgroundColor: '#fff' }}
                    onClick={() => openModalPinvoices()}
                  >
                    {createCausation.documentNumber ? createCausation.documentNumber : ''}
                  </div>
                ) : (
                  <input
                    key={'documentNumber' + triggerClean}
                    className={IndividualStyles.registerInputsGris}
                    type='text'
                    onChange={e =>
                      setCreateCausation({
                        ...createCausation,
                        documentNumber: e.target.value,
                      })
                    }
                  />
                )}
              </div>
              <div className='col-2' style={{ paddingRight: '1rem' }}>
                <label className={tableStyles.crudModalLabel} style={{ height: '13px' }}>
                  <div>ㅤ</div>
                </label>
                <div
                  className={`
                                        ${IndividualStyles.registerInputsBlue} 
                                        ${createCausation?.nameCausationType === 'invoice' || (createCausation?.nameCausationType === undefined && createCausation.documentNumber !== '') ? '' : ''} 
                                        d-flex
                                    `}
                  style={{ backgroundColor: '#fff' }}
                  type='text'
                  onClick={() =>
                    createCausation?.nameCausationType === 'invoice' ||
                    (createCausation?.nameCausationType === null &&
                      createCausation.documentNumber !== '')
                      ? download(createCausation.url_Invoice)
                      : ButtonDocuments()
                  }
                >
                  {!!(createCausation?.documentNumber !== '') &&
                  createCausation?.nameCausationType === 'invoice' ? (
                    <img src={docType2(createCausation.url_Invoice)} alt='' srcSet='' />
                  ) : (
                    <img src={docType(reqState)} alt='' srcSet='' />
                  )}

                  {!!(createCausation?.documentNumber !== '') &&
                  createCausation?.nameCausationType === 'invoice' ? (
                    <p
                      className={`${tableStyles.crudModalLabel} ${IndividualStyles.ellipsis}`}
                      style={{ marginTop: '0.2rem', maxWidth: '100px', width: 'max-content' }}
                    >
                      {createCausation?.url_Invoice ? nameDoc(createCausation?.url_Invoice) : ''}
                    </p>
                  ) : (
                    <p
                      className={`${tableStyles.crudModalLabel} ${IndividualStyles.ellipsis}`}
                      style={{ marginTop: '0.2rem', maxWidth: '100px', width: 'max-content' }}
                    >
                      {reqState.file.name}
                      {/* // <img src={Cerrar} onClick={()=> resetStatusFile()} /> */}
                    </p>
                  )}

                  {reqState.file.name ? (
                    <img
                      alt='cerrar'
                      src={Cerrar}
                      style={{ marginLeft: '5px', width: '18px' }}
                      onClick={e => resetStatusFile(e)}
                    />
                  ) : (
                    ''
                  )}
                  <img style={{ marginLeft: 'auto', width: '16px' }} src={Adjuntar} alt='' />
                </div>
              </div>
              <div className='col-2 display-grid' style={{ paddingRight: '1rem' }}>
                <label className={tableStyles.crudModalLabel}>Fecha de inicio</label>
                <input
                  key={'docDate' + triggerClean}
                  className={`${IndividualStyles.registerInputsBlue} `}
                  type='date'
                  max={formatDateTwo(date)}
                  value={
                    createCausation?.nameCausationType === 'invoice' &&
                    createCausation.documentNumber !== ''
                      ? createCausation.exp_date_Invoice
                      : createCausation.docDate
                  }
                  onChange={e =>
                    setCreateCausation({
                      ...createCausation,
                      docDate: e.target.value,
                      discDate: '',
                    })
                  }
                />
              </div>
              <div className='col-2 display-grid'>
                <label className={tableStyles.crudModalLabel}>Fecha de vencimiento</label>
                <input
                  key={'dueDate' + triggerClean}
                  className={IndividualStyles.registerInputsBlue}
                  type='date'
                  value={
                    createCausation?.nameCausationType === 'invoice' &&
                    createCausation.documentNumber !== ''
                      ? createCausation.due_date_Invoice
                      : createCausation.dueDate
                  }
                  min={createCausation.docDate}
                  onChange={e =>
                    setCreateCausation({
                      ...createCausation,
                      dueDate: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className='div'>
              <div className='col-3 display-grid' style={{ paddingRight: '1rem' }}>
                <label className={tableStyles.crudModalLabel}>Saldo actual</label>
                <NumberFormat
                  key={'currentBalance' + triggerClean}
                  allowNegative={false}
                  id='cost_price'
                  className={`${IndividualStyles.registerInputsBlue}`}
                  decimalScale={2}
                  suffix={` ${CURRENCY}`}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  thousandSeparator={localeSeparators.groupSeparator}
                  fixedDecimalScale
                  onValueChange={e =>
                    setCreateCausation({
                      ...createCausation,
                      currentBalance: e.floatValue,
                    })
                  }
                  isAllowed={values => {
                    const { floatValue } = values;
                    if (floatValue !== undefined) {
                      if (floatValue <= 0) {
                        return false;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }}
                />
              </div>
              <div className='col-3 display-grid' style={{ paddingRight: '1rem' }}>
                <label className={tableStyles.crudModalLabel}>IVA</label>
                <NumberFormat
                  key={'vatTotal' + triggerClean}
                  allowNegative={false}
                  id='cost_price'
                  className={`${IndividualStyles.registerInputsGris}`}
                  decimalScale={2}
                  suffix={` ${CURRENCY}`}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  thousandSeparator={localeSeparators.groupSeparator}
                  fixedDecimalScale
                  isAllowed={values => {
                    const { floatValue } = values;
                    if (floatValue !== undefined) {
                      if (floatValue <= 0) {
                        return false;
                      } else if (floatValue < createCausation.currentBalance) {
                        return true;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }}
                  thousandsGroupStyle='thousand'
                  onValueChange={e =>
                    setCreateCausation({
                      ...createCausation,
                      vatTotal: e.floatValue,
                    })
                  }
                />
              </div>
              <div className='col-2 display-grid' style={{ paddingRight: '1rem' }}>
                <label className={tableStyles.crudModalLabel}>Si paga antes de:</label>
                <input
                  key={'discDate' + triggerClean}
                  className={IndividualStyles.registerInputsBlue}
                  type='date'
                  onChange={e =>
                    setCreateCausation({
                      ...createCausation,
                      discDate: e.target.value,
                    })
                  }
                  min={
                    createCausation.docDate
                      ? createCausation.docDate
                      : today.toISOString().split('.')[0]
                  }
                />
              </div>
              <div className='col-2 display-grid' style={{ paddingRight: '1rem' }}>
                <label className={tableStyles.crudModalLabel}>Descuento</label>
                <NumberFormat
                  key={'discAmount' + triggerClean}
                  allowNegative={false}
                  id='cost_price'
                  className={`${IndividualStyles.registerInputsGris}`}
                  decimalScale={2}
                  suffix={` ${CURRENCY}`}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  thousandSeparator={localeSeparators.groupSeparator}
                  fixedDecimalScale
                  onValueChange={e =>
                    setCreateCausation({
                      ...createCausation,
                      discAmount: e.floatValue,
                    })
                  }
                />
              </div>
              <div className='col-2 display-grid'>
                <label className={tableStyles.crudModalLabel}>Valor a pagar</label>
                <NumberFormat
                  key={'totalAmount' + triggerClean}
                  allowNegative={false}
                  id='cost_price'
                  className={`${IndividualStyles.registerInputsBlue}`}
                  decimalScale={2}
                  suffix={` ${CURRENCY}`}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  thousandSeparator={localeSeparators.groupSeparator}
                  fixedDecimalScale
                  value={createCausation.totalAmount}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex mt-3'>
          {cc.length > 0 ? (
            <img
              style={{ width: '20px', verticalAlign: 'middle' }}
              src={CheckGreen}
              alt='checkGreen'
            />
          ) : (
            ''
          )}
          <div className='col d-flex'>
            {createCausation.totalAmount !== 0 &&
            createCausation.totalAmount !== '' &&
            !!createCausation.totalAmount ? (
              <label onClick={() => openConsumptionCenter()} className={StyleMovements.bgBtnTwo}>
                <img style={{ width: '20px', verticalAlign: 'middle' }} src={Grafi} alt='grafi' />{' '}
                Centros de consumo
              </label>
            ) : (
              <label>
                <img
                  src={Grafi}
                  className='mr-2'
                  alt='grafi'
                  style={{ width: '20px', verticalAlign: 'middle' }}
                />
                <button
                  style={{
                    fontWeight: 'bold',
                    color: '#CECECE',
                    fontSize: '14px',
                    cursor: 'not-allowed',
                  }}
                >
                  Centros de consumo
                </button>
              </label>
            )}
            <div className='d-flex' style={{ marginTop: '4px', marginLeft: '20px' }}>
              <input
                className=' mr-2 mb-1'
                type='checkbox'
                onChange={e =>
                  setCreateCausation({
                    ...createCausation,
                    generateVoucher: e.target.checked,
                  })
                }
              />
              <div
                className='mr-2 mt-1'
                style={{ fontWeight: 'bold', color: '#58595B', fontSize: '14px' }}
              >
                Generar comprobante
              </div>
              <OverlayTrigger
                overlay={
                  <Tooltip id='tooltip-disabled'>
                    Documentos de soporte en adquisiciones efectuados
                  </Tooltip>
                }
              >
                <span className='d-inline-block'>
                  <img
                    alt={'icon'}
                    src={Question}
                    style={{ width: '18px', verticalAlign: 'text-top' }}
                  />
                </span>
              </OverlayTrigger>
            </div>
          </div>

          <div className='col-5 text-end d-block'></div>
          <div className='col-2 text-end d-block'></div>
        </div>
        <div>
          <GenericTable headers={header3} footer={footer3} dark={true}>
            {bodyTablePrincipal}
          </GenericTable>
        </div>

        <div className='d-flex groupAddButton col-2' onClick={() => addTable()}>
          <button className='addCheckButton mx-2' style={{ alignSelf: 'center' }} />
          <label className='darkGray fw-bold' htmlFor='addButtonCau'>
            Agregar partida
          </label>
        </div>
        <Row className=''>
          <Col xs={1}></Col>
          <Col style={{ textAlignLast: 'end' }}>
            <Button
              style={{ textAlignLast: 'auto' }}
              className={`${IndividualStyles.btnPrimary} mr-3`}
              onClick={() => {
                settriggerClean(triggerClean + 1);
                setNiifAccountNbr([]);
                setCreateCausation({
                  eaccount: counter.loginReducer.currentAccount.id,
                  user: counter.loginReducer.user_data.id,
                  generateVoucher: false,
                  totalAmount: '',
                  documentNumber: '',
                  docDate: '',
                  dueDate: '',
                  currentBalance: '',
                  vatTotal: '',
                  discDate: '',
                  discAmount: 0,
                  due_date_Invoice: '',
                  exp_date_Invoice: '',
                  url_Invoice: '',
                  pinvoice: '',
                });
                setCc([]);
                resetStatusFile();
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{ textAlignLast: 'auto' }}
              className={IndividualStyles.btnSecondary}
              onClick={() => PostRegisterCausation()}
              disabled={
                !!createCausation.causationType &&
                !!createCausation.provider &&
                !!createCausation.detail
                  ? checkParameters()
                  : true
              }
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </div>
      <ModalDocuments
        title='Anexar Documento'
        key={'modalDocuments' + trigger}
        show={modalDocumentsControl.show}
        onHide={() =>
          setmodalDocumentsControl({
            ...modalDocumentsControl,
            show: false,
          })
        }
        reqState={reqState}
        setreqState={setreqState}
      />
      <ModalNew
        show={showModal}
        onHide={() => setShowModal(false)}
        title={'Distribución de consumo'}
        // subtitle={"203265 - Nueva EPS"}
        btnYesEvent={() => setShowModal(false)}
        btnYesName={'Selecionar'}
        size={'620'}
      >
        <div style={{ backgroundColor: '#005DBF10', padding: '10px', borderRadius: '10px' }}>
          <b style={{ color: '#01A0F6' }}>Selección de centros de costos</b>
          <Row className='d-flex'>
            <Col xs={5}>
              <label className={tableStyles.crudModalLabel}>Fecha de vencimiento</label>
              <input
                className={IndividualStyles.registerInputsBlue}
                type='date'
                value={createCausation.dueDate}
                disabled
              ></input>
            </Col>
            <Col xs={7}>
              <label className={tableStyles.crudModalLabel}>Valor a pagar</label>
              <NumberFormat
                allowNegative={false}
                id='cost_price'
                className={`${IndividualStyles.registerInputsBlue}`}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={createCausation.totalAmount}
                disabled
              />
            </Col>
          </Row>
        </div>
        <div style={{ padding: '10px' }}>
          <b style={{ color: '#01A0F6' }}>Selección de centros de costos</b>
          <Row className='d-flex'>
            <Col xs={5}>
              <label className={tableStyles.crudModalLabel}>Centro de consumo</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder='Seleccionar...'
                key={'cc' + triggerInput}
                options={optionConsumptionCenter}
                styles={customSelectNewDark}
                onChange={e => {
                  setTempCc({
                    ...tempCc,
                    cc: e.value,
                    ccName: e.label,
                  });
                }}
              />
            </Col>
            <Col xs={2}>
              <label className={tableStyles.crudModalLabel}>Porcentaje</label>
              <NumberFormat
                key={'percentage' + triggerInput}
                allowNegative={false}
                id='cost_price'
                className={`${IndividualStyles.registerInputsBlue}`}
                suffix='%'
                thousandsGroupStyle='thousand'
                isAllowed={values => {
                  const { floatValue } = values;
                  return floatValue >= 1 && floatValue <= 100;
                }}
                maxLength={4}
                onValueChange={e =>
                  setTempCc({
                    ...tempCc,
                    percentage: e.floatValue,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              <label className={tableStyles.crudModalLabel}>Valor</label>
              <NumberFormat
                allowNegative={false}
                id='cost_price'
                className={`${IndividualStyles.registerInputsBlue}`}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                disabled
                value={
                  createCausation.totalAmount
                    ? Number(createCausation.totalAmount) * (tempCc.percentage / 100)
                    : ''
                }
              />
            </Col>
            <Col xs={1}>
              <label className={tableStyles.crudModalLabel}>ㅤ</label>
              <img src={Agregar} alt='agregar' onClick={() => sendTempCc()} />
            </Col>
          </Row>
        </div>
        <GenericTable headers={headerModal}>{bodyModal}</GenericTable>
        <Row className='d-flex'>
          <Col xs={4}>
            <label className={tableStyles.crudModalLabel}>Restante</label>
            <label className={IndividualStyles.registerInputsGris} type='number' readOnly>
              {sum ? formatNumberCurrency(sum) : formatNumberCurrency(0)}
            </label>
          </Col>
        </Row>
      </ModalNew>
      <ModalNew
        show={showModalPinvoice}
        onHide={() => setShowModalPinvoice(false)}
        title={'Facturas por pagar'}
        subtitle={' '}
        btnYesEvent={() => setShowModalPinvoice(false)}
        btnYesName={'Selecionar'}
        size={'620'}
      >
        <Row className='d-flex'>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>No. Factura</label>
            <input
              className={IndividualStyles.registerInputsGris}
              type='text'
              onChange={e =>
                setSearchPinvoices({
                  ...searchPinvoices,
                  search: e.target.value,
                })
              }
            ></input>
          </Col>
          <Col xs={5}>
            <label className={tableStyles.crudModalLabel}>Fecha</label>
            <input
              className={IndividualStyles.registerInputsBlue}
              type='date'
              onChange={e =>
                setSearchPinvoices({
                  ...searchPinvoices,
                  date: e.target.value,
                })
              }
            ></input>
          </Col>
          <Col xs={1} className='d-grid'>
            <label className={tableStyles.crudModalLabel}>ㅤ</label>
            <i>
              <img
                alt='lupa'
                style={{ width: '25px' }}
                src={Lupa}
                onClick={() => setTrigger2(trigger2 + 1)}
              />
            </i>
          </Col>
        </Row>
        <GenericTableScroll
          headers={headerModalPinvoices}
          body={renderList()}
          typeHead={'3'}
        ></GenericTableScroll>
      </ModalNew>
    </>
  );
}
export default Causation;
