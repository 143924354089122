import { Children, cloneElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../../Hooks';

import { Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { loader } from '../../helpers';
import { customSwaltAlert, getMessagesES, localizer } from './../../helpers/';

import moment from 'moment';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import ModalNew from '../Layouts/ModalNew';

export default function Holidays() {
  const storage = useSelector(state => state);
  const token = storage?.loginReducer?.Authorization;

  const { trigger: getHolidays, load: holidaysLoader } = useGetMethod();
  const { trigger: addHoliday } = usePostMethod();

  const [selectedOption, setSelectedOption] = useState('month');
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [selectedDay, setSelectedDay] = useState({
    date: '',
    description: '',
    is_national_holiday: null,
    isEditing: false,
    isCreating: false,
    id: null,
  });

  const eventStyledGetter = () => {
    const style = {
      borderRadius: '0px',
      opacity: 0.8,
    };

    return { style };
  };

  const onViewChange = event => {
    setSelectedOption(event);
  };

  const EventComponent = ({ event }) => {
    return (
      <div style={{ padding: '5px', textAlign: 'center' }}>
        <div style={{ color: 'white' }}>{event.description}</div>
      </div>
    );
  };
  const eventWrapperComponent = ({ event, children }) => {
    const newChildren = cloneElement(children, {
      ...children.props,
      className: `${children.props.className} outline-none border-none`,
      style: {
        ...children.props.style,
        color: 'white',
        backgroundColor: event.is_national_holiday ? '#28a745' : '#007bff',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '2px',
        ...(selectedOption === 'week' && {
          borderRight: `3px solid ${event.is_national_holiday ? '#28a745' : '#007bff'}`,
          borderLeft: `3px solid ${event.is_national_holiday ? '#28a745' : '#007bff'}`,
          borderTop: 'transparent',
          borderBottom: 'transparent',
          padding: '10px',
          fontSize: '10px',
        }),
        ...(selectedOption === 'month' && {
          borderRight: 'transparent',
          borderLeft: 'transparent',
          borderTop: 'transparent',
          borderBottom: 'transparent',
          padding: '0px',
          borderRadius: '11px',
          fontSize: '10px',
          height: '20px',
        }),
        ...(selectedOption === 'day' && {
          border: 'none',
          borderRadius: '5px',
          padding: '16px',
          minHeight: '10px !important',
        }),
      },
    });

    return <div>{newChildren}</div>;
  };

  const DateCellWrapper = ({ children, value }) => {
    const handleClick = () => {
      setSelectedDay({ ...selectedDay, date: value });
      setShowModal(true);
    };

    let cellStyle = cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: 'white',
        cursor: 'pointer',
      },
      onClick: handleClick,
    });

    return cellStyle;
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedDay({ date: '', description: '', isEditing: false, isCreating: false, id: null });
  };
  const handleInputChange = e => {
    const { name, value } = e.target;
    setSelectedDay({ ...selectedDay, [name]: value });
  };

  const handleSaveHoliday = async () => {
    try {
      addHoliday({
        method: selectedDay.isCreating ? 'POST' : 'PUT',
        url: selectedDay.isCreating ? '/payroll/holidays' : `/payroll/holidays/${selectedDay.id}`,
        body: selectedDay.isCreating
          ? selectedDay
          : {
              fecha: selectedDay.date,
              desc: selectedDay.description,
            },
        token: token,

        doAfterSuccess: () => {
          fetchData();
          handleModalClose();
        },

        succesAction: result => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'success',
            title: result.message,
            confirmButtonText: 'Aceptar',
          });
        },
        doAfterException: result => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: result.message,
            confirmButtonText: 'Aceptar',
          });
        },
      });
    } catch (error) {
      console.error('Error adding holiday', error);
    }
  };

  const fetchData = async () => {
    try {
      const result = await getHolidays({
        url: '/payroll/holidays',
        objFilters: {},
        token: token,
      });

      if (!result?.success) {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          message: result.message,
          confirmButtonText: 'Aceptar',
        });
      } else if (result?.success) {
        const data =
          result?.results?.map(e => ({
            ...e,
            id: e.id,
            description: e.description,
            start: setEventTime(e.date, 5, 0),
            end: setEventTime(e.date, 6, 0),
            is_national_holiday: e.is_national_holiday,
          })) || [];

        return setEvents(data);
      }
    } catch (error) {
      console.error('error fetching data', error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setEventTime = (date, hours, minutes) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    return newDate;
  };

  const handleDateSelect = (info, isEditing, isCreating) => {
    const newDate = new Date(info?.start).toISOString().split('T')[0];
    const eventExists = events.some(event => moment(event.start).isSame(newDate, 'day'));

    if (!eventExists || isEditing) {
      setSelectedDay({
        date: newDate,
        description: info?.description,
        is_national_holiday: info?.is_national_holiday === 1 ? info?.is_national_holiday : 0,
        id: info?.id ? info?.id : null,
        isEditing,
        isCreating,
      });
    }
  };

  useEffect(() => {
    selectedDay.is_national_holiday === 0 ? setShowModal(true) : setShowModal(false);
  }, [selectedDay]);

  const render = () => {
    return (
      <>
        {holidaysLoader && loader}
        <div className={`${tableStyles.tlnContainer}`}>
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}> Días festivos </h1>
            <div id='calendarHolidays'>
              <Calendar
                onSelectEvent={e => handleDateSelect(e, true, false)}
                selectable
                onSelectSlot={e => handleDateSelect(e, false, true)}
                showAllEvents={true}
                culture='es'
                localizer={localizer}
                defaultView={'month'}
                events={events}
                startAccessor='start'
                endAccessor='end'
                style={{ height: '70vh' }}
                messages={getMessagesES()}
                eventPropGetter={eventStyledGetter}
                components={{
                  eventWrapper: eventWrapperComponent,
                  event: EventComponent,
                  dateCellWrapper: DateCellWrapper,
                }}
                onView={onViewChange}
                views={{
                  month: true,
                  week: true,
                  day: true,
                }}
              />
            </div>

            <ModalNew
              show={showModal}
              onHide={handleModalClose}
              title={selectedDay.isCreating ? 'Añadir nuevo festivo' : 'Editar festivo'}
              btnYesName={'Aceptar'}
              btnYesEvent={handleSaveHoliday}
              size={'500'}
            >
              <Form>
                <Form.Group controlId='formDate'>
                  <Form.Label className={`${tableStyles.crudModalLabel} `}>Fecha</Form.Label>
                  <Form.Control
                    type='date'
                    className='register-inputs'
                    name='date'
                    value={
                      selectedDay.date
                        ? new Date(selectedDay.date).toISOString().substring(0, 10)
                        : ''
                    }
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId='formDescription'>
                  <Form.Label className={`${tableStyles.crudModalLabel} `}>Descripción</Form.Label>
                  <Form.Control
                    className='register-inputs text-secondary'
                    type='text'
                    name='description'
                    value={selectedDay.description}
                    onChange={handleInputChange}
                    placeholder='Descipción del evento...'
                  />
                </Form.Group>
              </Form>
            </ModalNew>
          </div>
        </div>
      </>
    );
  };
  return render();
}
